import gql from 'graphql-tag';
import React, { SFC } from 'react';
import { ContactFormContentBlockFragment } from '../../entities/operationResults';
import ContactUs from '../organisms/ContactUs';
import ContactSlideover from '../organisms/ContactSlideover';

export const fragment = gql`
    fragment ContactFormContentBlock on ContentBlocksContactForm {
        id
        ctaText
        buttonText
        compactDisplay
        documentUpload
        description {
            content
        }
    }
`;

interface Props {
    contentBlock: ContactFormContentBlockFragment;
}

const ContactFormContentBlock: SFC<Props> = ({ contentBlock: { id, ctaText, description, buttonText, compactDisplay, documentUpload } }) => {
    const blockId = parseInt(id || '', 10);
    
    return (
        documentUpload ? (
        <ContactUs
            slideover={ContactSlideover}
            contactBlockId={blockId}
            ctaText={ctaText}
            contactFormText={description && description.content}
            buttonText={buttonText}
            compact={compactDisplay}
            documentUpload={documentUpload}
        />
        ) : (
        <ContactUs
            slideover={ContactSlideover}
            contactBlockId={blockId}
            ctaText={ctaText}
            contactFormText={description && description.content}
            buttonText={buttonText}
            compact={compactDisplay}
            documentUpload={false}
        /> 
        )
    );
};

export default ContactFormContentBlock;