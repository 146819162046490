import withModal, { ModalInProps } from '../../hocs/withModal';
import React, { SFC } from 'react';
import gql from 'graphql-tag';
import { contentBlockFragments, default as ContentBlocks } from './ContentBlocks';
import { DataProps, graphql } from 'react-apollo';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router';
import {
    ServiceHowItWorksFragment,
    ServiceHowItWorksQuery
} from '../../entities/operationResults';
import { ServiceParams } from '../Service';
import { getEntryOfType } from '../../services/entryTypes';

const GET_SERVICE_HOWITWORKS_QUERY = gql`
    query ServiceHowItWorks ($exchange: String!, $slug: String!) {
        service: entry(type: Service, site: $exchange, slug: $slug) {
            ...ServiceHowItWorks
        }
    }
    
    fragment ServiceHowItWorks on Service {
        id
        contentBlocks {
            ...ContentBlock
        }
    }
    
    ${contentBlockFragments}
`;

interface InProps extends ModalInProps {
    test?: string;
}

interface OutProps extends RouteComponentProps<ServiceParams>, DataProps<ServiceHowItWorksQuery> {
    slug: string;
}

const ServiceSlideover: SFC<InProps & OutProps> = ({ data }) => {
    const service: ServiceHowItWorksFragment | null = getEntryOfType('Service', data.service);
    if (!service) { return null; }

    return (
        <ContentBlocks contentBlocks={service.contentBlocks} />
    );
};

const enhance = compose<OutProps, InProps>(
    withRouter,
    graphql(GET_SERVICE_HOWITWORKS_QUERY, {
        options: ({ match: { params: { slug, exchange } } }: OutProps) => ({
            variables: {
                slug,
                exchange
            }
        })
    }),
    withModal
);

export default enhance(ServiceSlideover);