import React, { SFC, useState } from 'react';
import Container from '../../../atoms/Container';
import sites from '../../../../constants/sites';
import poweredBySites from '../../../../constants/poweredBySites';
import styled from '../../../../styled-components';
import LinkIconRight from '../../../atoms/icons/LinkIconRight';
import generatePath from 'react-router-dom/generatePath';
import { exchangeRoute } from '../../../../constants/routes';
import { RouteComponentProps } from 'react-router';
import { ExchangeParams } from '../../../Exchange';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { NavHeading, NavItem, NavList } from '../../../atoms/NavItems';
import WorldMap from './WorldMap';

type OutProps = RouteComponentProps<ExchangeParams>;

interface InProps {
    setVisible: (visible: boolean) => void;
}

interface NavColumnProps {
    reverse?: boolean;
}

const enhanced = compose<OutProps, InProps>(
    withTheme
);

const getCurrentExchangeHandle = () => {
    const handle = window.location.pathname.split('/');
    return handle[1];
};

const DropdownLocations: SFC<OutProps & InProps> = ({ setVisible }) => {
    const siteList = sites;
    const poweredBySiteList = poweredBySites;
    const currentExchange = getCurrentExchangeHandle();
    const [location, setLocation] = useState('');
    const [title, setTitle] = useState('');

    const onHoverLocation = (locationHandle: string, siteTitle: string) => {
        setLocation(locationHandle);
        setTitle(siteTitle);
    };

    const handleUnhover = () => {
        setLocation('');
        setTitle('');
    };

    return (
        <DropdownLocationsContainer maxWidth={true} paddingTop={'5.6rem'} paddingBottom={'6.4rem'}>
            <NavWrap>
                <OverflowContainer>
                    <LocationsNavColumn>
                        <NavHeading>AMS-IX locations</NavHeading>
                        <NavList className={'main-menu'} column>
                            {siteList.map(
                                (site, index) =>
                                <NavItem key={index}
                                    onMouseOver={() => onHoverLocation(site.handle, site.title)}
                                    onMouseOut={() => handleUnhover()}
                                >
                                    {currentExchange && currentExchange === site.handle &&
                                        <NavLink
                                            active={"true"}
                                            onClick={ () => { setVisible(false); } }
                                            className={'location-item'}
                                            to={generatePath(exchangeRoute.path, { exchange: site.handle })}>
                                                {site.title}
                                                <LinkIconRight/>
                                        </NavLink>
                                    }

                                    {currentExchange && currentExchange !== site.handle &&
                                        <NavLink
                                            active={"false"}
                                            onClick={ () => { setVisible(false); } }
                                            className={'location-item'}
                                            to={generatePath(exchangeRoute.path, { exchange: site.handle })}>
                                                {site.title}
                                                <LinkIconRight/>
                                        </NavLink>
                                    }
                                </NavItem>
                            )}
                        </NavList>
                        <NavHeading>Powered by AMS-IX</NavHeading>
                        <NavList column>
                            {poweredBySiteList.map(
                                (site, index) =>
                                <NavItem key={index}
                                    onMouseOver={() => onHoverLocation(site.handle, site.title)}
                                    onMouseOut={() => handleUnhover()}
                                >
                                    <ExternalNavlink
                                        target="_blank"
                                        href={site.url}>
                                            {site.title}
                                            <LinkIconRight/>
                                    </ExternalNavlink>
                                </NavItem>
                            )}
                        </NavList>
                    </LocationsNavColumn>
                </OverflowContainer>
                <WorldMap locationHandle={location} locationName={title} currentExchange={currentExchange}/>
            </NavWrap>
        </DropdownLocationsContainer>
    );
};

const NavWrap = styled.div`
    display:flex;
    flex-wrap: nowrap;
    justify-content: space-between;
`;

const DropdownLocationsContainer = styled(Container)`
    height: 80vh;
`;

const OverflowContainer = styled.div`
    overflow-y: scroll;
    mask-image: linear-gradient(180deg, #17222a 96%, transparent);
`

const NavLink = styled(Link)<{active: string}>`
    color: ${(props) => props.theme.colors.black};
    font: 1.6rem/2.4rem chalet-new-york-sixty,sans-serif;
    color: ${(props) => props.active === 'true' ? props.theme.colors.tango : props.theme.colors.white};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover{
        color: ${(props) => props.theme.colors.tango};
    }
`;

const ExternalNavlink = styled.a`
    color: ${(props) => props.theme.colors.black};
    font: 1.6rem/2.4rem chalet-new-york-sixty,sans-serif;
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover{
        color: ${(props) => props.theme.colors.tango};
    }
`;

const LocationsNavColumn = styled.div<NavColumnProps>`
    box-sizing: border-box;
    display: inline-block;
    flex-direction: column;
    height: 80vh;
    justify-content: center;
    
    ${({ reverse }) => {
        return reverse
            ? `margin-left: auto;`
            : ``;
    }}
    
    position: relative;
    width: 33.33%;
    padding: 0 2.4rem 0 0;
    margin-right: 3.2rem;

    &:after {
        content: '';
        position: absolute;
        display: block;
        background: #ffffff;
        opacity: 0.1;
        top: 0;
        right: 0;
        bottom: 0;
        width: 1px;
    }

    &:last-child:after {
        content: unset;
    }
`;

export default enhanced(DropdownLocations);
