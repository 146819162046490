/* tslint:disable */
//  This file was automatically generated and should not be edited.

export enum EntryTypesEnum {
  City = "City",
  ConnectedNetworkCollection = "ConnectedNetworkCollection",
  Country = "Country",
  Documentation = "Documentation",
  Event = "Event",
  EventCollection = "EventCollection",
  ExchangePage = "ExchangePage",
  FooterLink = "FooterLink",
  GetStarted = "GetStarted",
  Graph = "Graph",
  Info = "Info",
  Menu = "Menu",
  Network = "Network",
  NetworkConnection = "NetworkConnection",
  News = "News",
  NewsCollection = "NewsCollection",
  Newsletter = "Newsletter",
  NewsletterCollection = "NewsletterCollection",
  Partner = "Partner",
  PartnerCollection = "PartnerCollection",
  Search = "Search",
  Service = "Service",
  Story = "Story",
  StoryCollection = "StoryCollection",
}


export enum MenuScheme {
  dark = "dark",
  light = "light",
  transparent = "transparent",
}


export enum PartnerTypeEnum {
  colocation = "colocation", // Colocation
  partner = "partner", // Partner
}


export enum ApplicationTypeEnum {
  customer = "customer", // Customer
  reseller = "reseller", // Reseller
}


export enum ProgrammingLanguageEnum {
  bash = "bash", // Bash
  css = "css", // CSS
  django = "django", // Django
  html = "html", // HTML
  javascript = "javascript", // JavaScript
  json = "json", // JSON
  makefile = "makefile", // Makefile
  markdown = "markdown", // Markdown
  perl = "perl", // Perl
  php = "php", // PHP
  puppet = "puppet", // Puppet
  python = "python", // Python
  ruby = "ruby", // Ruby
  sql = "sql", // SQL
  text = "text", // None
  xml = "xml", // XML
  yaml = "yaml", // YAML
}


export enum TextAlignmentEnum {
  left = "left", // Left
  right = "right", // Right
}


export enum ImageAlignmentEnum {
  left = "left", // Left
  right = "right", // Right
}


export enum IntervalEnum {
  daily = "daily", // daily
  monthly = "monthly", // monthly
  weekly = "weekly", // weekly
  yearly = "yearly", // yearly
}


export enum MenuDropdownTypeEnum {
  myAmsIx = "myAmsIx", // My AMS-IX
  regular = "regular", // Regular
  services = "services", // Services
  statistics = "statistics", // Statistics
}


export interface AllCollectionsQueryVariables {
  site: string,
  type?: Array< EntryTypesEnum | null > | null,
  collectionType?: Array< EntryTypesEnum | null > | null,
  offset: number,
  itemLimit: number,
  order?: string | null,
  listExpiryDate?: string | null,
  listInPastEvents?: boolean | null,
};

export interface AllCollectionsQuery {
  collectionItems:  {
    __typename: "EntryConnection",
    entries:  Array<( {
        __typename: "ContactRequestPartner",
      } | {
        __typename: "ContactRequestNetwork",
      } | {
        __typename: "ContactRequestInternal",
      } | {
        __typename: "ContactRequestDocument",
      } | {
        __typename: "ContactRequestWishlist",
      } | {
        __typename: "ContactRequestRegistration",
      } | {
        __typename: "City",
      } | {
        __typename: "ConnectedNetworkCollection",
      } | {
        __typename: "Country",
      } | {
        __typename: "Documentation",
      } | {
        __typename: "Event",
        id: number,
        uri: string | null,
        title: string,
        // A short description for display in lists and other overviews.
        description: string | null,
        // The main image for this item.
        heroImage:  Array<( {
            __typename: "ImagesVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          } | {
            __typename: "UploadAssetsVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          }
        ) | null > | null,
        // For a one-day event, only fill in the start date.
        startDate: string | null,
        endDate: string | null,
        date: string | null,
        // Event will be shown in the past events tab.
        listInPastEvents: boolean | null,
        location: string | null,
      } | {
        __typename: "EventCollection",
      } | {
        __typename: "ExchangePage",
      } | {
        __typename: "FooterLink",
      } | {
        __typename: "GetStarted",
      } | {
        __typename: "Graph",
      } | {
        __typename: "Info",
      } | {
        __typename: "Menu",
      } | {
        __typename: "Network",
      } | {
        __typename: "NetworkConnection",
      } | {
        __typename: "News",
        id: number,
        uri: string | null,
        title: string,
        // A short description for display in lists and other overviews.
        description: string | null,
        // The main image for this item.
        heroImage:  Array<( {
            __typename: "ImagesVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          } | {
            __typename: "UploadAssetsVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          }
        ) | null > | null,
        date: string | null,
      } | {
        __typename: "NewsCollection",
      } | {
        __typename: "Newsletter",
        id: number,
        uri: string | null,
        title: string,
        newsletterUrl: string | null,
        newsletterDate: string | null,
        // The main image for this item.
        heroImage:  Array<( {
            __typename: "ImagesVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          } | {
            __typename: "UploadAssetsVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          }
        ) | null > | null,
        date: string | null,
      } | {
        __typename: "NewsletterCollection",
      } | {
        __typename: "Partner",
      } | {
        __typename: "PartnerCollection",
      } | {
        __typename: "Search",
      } | {
        __typename: "Service",
      } | {
        __typename: "Story",
        id: number,
        uri: string | null,
        title: string,
        // A short description for display in lists and other overviews.
        description: string | null,
        // The main image for this item.
        heroImage:  Array<( {
            __typename: "ImagesVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          } | {
            __typename: "UploadAssetsVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          }
        ) | null > | null,
        date: string | null,
      } | {
        __typename: "StoryCollection",
      }
    ) | null > | null,
    totalCount: number,
    pageInfo:  {
      __typename: "PageInfo",
      totalPages: number | null,
    } | null,
  } | null,
  collection: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
      introHeading: string | null,
      introText:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
      introHeading: string | null,
      introText:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
      introHeading: string | null,
      introText:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
      introHeading: string | null,
      introText:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      // Select the stories to appear in the "Featured" section.
      featuredStories:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
          id: number,
          uri: string | null,
          title: string,
          // A short description for display in lists and other overviews.
          description: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          postDate: string | null,
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    }
  ) | null,
};

export interface ConnectedNetworksQueryVariables {
  exchange: string,
  search?: string | null,
};

export interface ConnectedNetworksQuery {
  connectedNetworksPage: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
      title: string,
      introHeading: string | null,
      introText:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      // The links included in the "Export as" dropdown.
      exportLinks:  Array< {
        __typename: "ExportLinksTable",
        // Title
        title: string | null,
        // URL
        url: string | null,
      } | null > | null,
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
  connectedNetworksTable:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
      id: number,
      title: string,
      memberUrl: string | null,
      asNumber: number | null,
      peeringPolicy: string | null,
      routeserver: boolean | null,
      vlan: string | null,
      colocation: string | null,
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
  connectedNetworksStats:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/counts
      routeserverPeers: number | null,
      // amsix/import/counts
      connectedNetworks: number | null,
      // amsix/import/counts
      customerPorts: number | null,
      // amsix/import/counts
      ipv6Peers: number | null,
    } | null,
  } | null,
};

export interface DocumentationQueryVariables {
  exchange: string,
  slug: string,
};

export interface DocumentationQuery {
  entry: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
      title: string,
      // A short description for display in lists and other overviews.
      description: string | null,
      contentBlocks:  Array<( {
          __typename: "ContentBlocksIntro",
          id: string | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksText",
          id: string | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksTextWithImage",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
          imageAlignment: ImageAlignmentEnum | null,
        } | {
          __typename: "ContentBlocksImage",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          heading: string | null,
          text: string | null,
          buttonText: string | null,
          buttonLink:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
          textAlignment: TextAlignmentEnum | null,
        } | {
          __typename: "ContentBlocksGraphic",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              srcOriginal: string | null,
              widthOriginal: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              srcOriginal: string | null,
              widthOriginal: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksVideo",
          // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
          videoUrl: string | null,
        } | {
          __typename: "ContentBlocksAudio",
          // https://player.rss.com/ix-talks/xxxxxx
          podcastUrl: string | null,
          podcastDescription: string | null,
        } | {
          __typename: "ContentBlocksTable",
          heading: string | null,
          table:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksCodeSnippet",
          // The language used for syntax highlighting.
          programmingLanguage: ProgrammingLanguageEnum | null,
          snippet: string | null,
        } | {
          __typename: "ContentBlocksContactForm",
          id: string | null,
          // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
          ctaText: string | null,
          // The text that appears on the button that opens the contact form.
          buttonText: string | null,
          // If enabled, this block will be displayed as a single small button instead of a full sized block.
          compactDisplay: boolean | null,
          // If enabled, there will be a option to upload a document in the contact form.
          documentUpload: boolean | null,
          // This text appears below the title in the contact form overlay.
          description:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksLogos",
          logos:  Array<( {
              __typename: "ImagesVolume",
              src: string | null,
              src2x: string | null,
              title: string | null,
              id: number | null,
            } | {
              __typename: "UploadAssetsVolume",
              src: string | null,
              src2x: string | null,
              title: string | null,
              id: number | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksButton",
          id: string | null,
          label: string | null,
          linkEntry:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksSectionEnding",
        } | {
          __typename: "ContentBlocksUsers",
          id: string | null,
          heading: string | null,
          users:  Array< {
            __typename: "User",
            fullName: string | null,
            // This will be shown in the author information on the website.
            functionTitle: string | null,
            photo:  {
              __typename: "ImagesVolume",
              url: string | null,
            } | null,
          } | null > | null,
        } | {
          __typename: "ContentBlocksTrafficGraph",
          id: string | null,
          dataset:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
              title: string,
              interval: IntervalEnum | null,
              peakIn: number | null,
              peakOut: number | null,
              averageIn: number | null,
              averageOut: number | null,
              currentIn: number | null,
              currentOut: number | null,
              averageInputData:  Array< {
                __typename: "AverageInputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              averageOutputData:  Array< {
                __typename: "AverageOutputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              peakOutputData:  Array< {
                __typename: "PeakOutputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              peakInputData:  Array< {
                __typename: "PeakInputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksApplicationForm",
          id: string | null,
          // The text that appears on the button that opens the application form.
          buttonText: string | null,
          applicationType: ApplicationTypeEnum | null,
        } | {
          __typename: "ContentBlocksRegistrationForm",
          id: string | null,
          // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
          ctaText: string | null,
          // The text that appears on the button that opens the contact form.
          buttonText: string | null,
          // If enabled, this block will be displayed as a single small button instead of a full sized block.
          compactDisplay: boolean | null,
          // This text appears below the title in the contact form overlay.
          description:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        }
      ) | null > | null,
      // Select items to appear in the "Related" section of the page.
      relatedItems:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Event",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Story",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          service:  Array<( {
              __typename: "ContactRequestPartner",
              title: string,
            } | {
              __typename: "ContactRequestNetwork",
              title: string,
            } | {
              __typename: "ContactRequestInternal",
              title: string,
            } | {
              __typename: "ContactRequestDocument",
              title: string,
            } | {
              __typename: "ContactRequestWishlist",
              title: string,
            } | {
              __typename: "ContactRequestRegistration",
              title: string,
            } | {
              __typename: "City",
              title: string,
            } | {
              __typename: "ConnectedNetworkCollection",
              title: string,
            } | {
              __typename: "Country",
              title: string,
            } | {
              __typename: "Documentation",
              title: string,
            } | {
              __typename: "Event",
              title: string,
            } | {
              __typename: "EventCollection",
              title: string,
            } | {
              __typename: "ExchangePage",
              title: string,
            } | {
              __typename: "FooterLink",
              title: string,
            } | {
              __typename: "GetStarted",
              title: string,
            } | {
              __typename: "Graph",
              title: string,
            } | {
              __typename: "Info",
              title: string,
            } | {
              __typename: "Menu",
              title: string,
            } | {
              __typename: "Network",
              title: string,
            } | {
              __typename: "NetworkConnection",
              title: string,
            } | {
              __typename: "News",
              title: string,
            } | {
              __typename: "NewsCollection",
              title: string,
            } | {
              __typename: "Newsletter",
              title: string,
            } | {
              __typename: "NewsletterCollection",
              title: string,
            } | {
              __typename: "Partner",
              title: string,
            } | {
              __typename: "PartnerCollection",
              title: string,
            } | {
              __typename: "Search",
              title: string,
            } | {
              __typename: "Service",
              title: string,
            } | {
              __typename: "Story",
              title: string,
            } | {
              __typename: "StoryCollection",
              title: string,
            }
          ) | null > | null,
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface EventQueryVariables {
  exchange: string,
  slug: string,
};

export interface EventQuery {
  entry: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
      title: string,
      // A short description for display in lists and other overviews.
      description: string | null,
      location: string | null,
      // For a one-day event, only fill in the start date.
      startDate: string | null,
      endDate: string | null,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      contentBlocks:  Array<( {
          __typename: "ContentBlocksIntro",
          id: string | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksText",
          id: string | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksTextWithImage",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
          imageAlignment: ImageAlignmentEnum | null,
        } | {
          __typename: "ContentBlocksImage",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          heading: string | null,
          text: string | null,
          buttonText: string | null,
          buttonLink:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
          textAlignment: TextAlignmentEnum | null,
        } | {
          __typename: "ContentBlocksGraphic",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              srcOriginal: string | null,
              widthOriginal: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              srcOriginal: string | null,
              widthOriginal: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksVideo",
          // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
          videoUrl: string | null,
        } | {
          __typename: "ContentBlocksAudio",
          // https://player.rss.com/ix-talks/xxxxxx
          podcastUrl: string | null,
          podcastDescription: string | null,
        } | {
          __typename: "ContentBlocksTable",
          heading: string | null,
          table:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksCodeSnippet",
          // The language used for syntax highlighting.
          programmingLanguage: ProgrammingLanguageEnum | null,
          snippet: string | null,
        } | {
          __typename: "ContentBlocksContactForm",
          id: string | null,
          // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
          ctaText: string | null,
          // The text that appears on the button that opens the contact form.
          buttonText: string | null,
          // If enabled, this block will be displayed as a single small button instead of a full sized block.
          compactDisplay: boolean | null,
          // If enabled, there will be a option to upload a document in the contact form.
          documentUpload: boolean | null,
          // This text appears below the title in the contact form overlay.
          description:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksLogos",
          logos:  Array<( {
              __typename: "ImagesVolume",
              src: string | null,
              src2x: string | null,
              title: string | null,
              id: number | null,
            } | {
              __typename: "UploadAssetsVolume",
              src: string | null,
              src2x: string | null,
              title: string | null,
              id: number | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksButton",
          id: string | null,
          label: string | null,
          linkEntry:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksSectionEnding",
        } | {
          __typename: "ContentBlocksUsers",
          id: string | null,
          heading: string | null,
          users:  Array< {
            __typename: "User",
            fullName: string | null,
            // This will be shown in the author information on the website.
            functionTitle: string | null,
            photo:  {
              __typename: "ImagesVolume",
              url: string | null,
            } | null,
          } | null > | null,
        } | {
          __typename: "ContentBlocksTrafficGraph",
          id: string | null,
          dataset:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
              title: string,
              interval: IntervalEnum | null,
              peakIn: number | null,
              peakOut: number | null,
              averageIn: number | null,
              averageOut: number | null,
              currentIn: number | null,
              currentOut: number | null,
              averageInputData:  Array< {
                __typename: "AverageInputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              averageOutputData:  Array< {
                __typename: "AverageOutputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              peakOutputData:  Array< {
                __typename: "PeakOutputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              peakInputData:  Array< {
                __typename: "PeakInputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksApplicationForm",
          id: string | null,
          // The text that appears on the button that opens the application form.
          buttonText: string | null,
          applicationType: ApplicationTypeEnum | null,
        } | {
          __typename: "ContentBlocksRegistrationForm",
          id: string | null,
          // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
          ctaText: string | null,
          // The text that appears on the button that opens the contact form.
          buttonText: string | null,
          // If enabled, this block will be displayed as a single small button instead of a full sized block.
          compactDisplay: boolean | null,
          // This text appears below the title in the contact form overlay.
          description:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        }
      ) | null > | null,
      // Select items to appear in the "Related" section of the page.
      relatedItems:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Event",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Story",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          service:  Array<( {
              __typename: "ContactRequestPartner",
              title: string,
            } | {
              __typename: "ContactRequestNetwork",
              title: string,
            } | {
              __typename: "ContactRequestInternal",
              title: string,
            } | {
              __typename: "ContactRequestDocument",
              title: string,
            } | {
              __typename: "ContactRequestWishlist",
              title: string,
            } | {
              __typename: "ContactRequestRegistration",
              title: string,
            } | {
              __typename: "City",
              title: string,
            } | {
              __typename: "ConnectedNetworkCollection",
              title: string,
            } | {
              __typename: "Country",
              title: string,
            } | {
              __typename: "Documentation",
              title: string,
            } | {
              __typename: "Event",
              title: string,
            } | {
              __typename: "EventCollection",
              title: string,
            } | {
              __typename: "ExchangePage",
              title: string,
            } | {
              __typename: "FooterLink",
              title: string,
            } | {
              __typename: "GetStarted",
              title: string,
            } | {
              __typename: "Graph",
              title: string,
            } | {
              __typename: "Info",
              title: string,
            } | {
              __typename: "Menu",
              title: string,
            } | {
              __typename: "Network",
              title: string,
            } | {
              __typename: "NetworkConnection",
              title: string,
            } | {
              __typename: "News",
              title: string,
            } | {
              __typename: "NewsCollection",
              title: string,
            } | {
              __typename: "Newsletter",
              title: string,
            } | {
              __typename: "NewsletterCollection",
              title: string,
            } | {
              __typename: "Partner",
              title: string,
            } | {
              __typename: "PartnerCollection",
              title: string,
            } | {
              __typename: "Search",
              title: string,
            } | {
              __typename: "Service",
              title: string,
            } | {
              __typename: "Story",
              title: string,
            } | {
              __typename: "StoryCollection",
              title: string,
            }
          ) | null > | null,
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface MenuQueryVariables {
  site: string,
};

export interface MenuQuery {
  menuItems:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
      id: number,
      title: string,
      // The page this item links to. This field is ignored if this is a top-level menu item or category.
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
      // Only fill this in if you want to link to an external webpage. It will override the entry-link.
      externalLink: string | null,
      ancestors:  Array<( {
          __typename: "ContactRequestPartner",
          title: string,
        } | {
          __typename: "ContactRequestNetwork",
          title: string,
        } | {
          __typename: "ContactRequestInternal",
          title: string,
        } | {
          __typename: "ContactRequestDocument",
          title: string,
        } | {
          __typename: "ContactRequestWishlist",
          title: string,
        } | {
          __typename: "ContactRequestRegistration",
          title: string,
        } | {
          __typename: "City",
          title: string,
        } | {
          __typename: "ConnectedNetworkCollection",
          title: string,
        } | {
          __typename: "Country",
          title: string,
        } | {
          __typename: "Documentation",
          title: string,
        } | {
          __typename: "Event",
          title: string,
        } | {
          __typename: "EventCollection",
          title: string,
        } | {
          __typename: "ExchangePage",
          title: string,
        } | {
          __typename: "FooterLink",
          title: string,
        } | {
          __typename: "GetStarted",
          title: string,
        } | {
          __typename: "Graph",
          title: string,
        } | {
          __typename: "Info",
          title: string,
        } | {
          __typename: "Menu",
          title: string,
        } | {
          __typename: "Network",
          title: string,
        } | {
          __typename: "NetworkConnection",
          title: string,
        } | {
          __typename: "News",
          title: string,
        } | {
          __typename: "NewsCollection",
          title: string,
        } | {
          __typename: "Newsletter",
          title: string,
        } | {
          __typename: "NewsletterCollection",
          title: string,
        } | {
          __typename: "Partner",
          title: string,
        } | {
          __typename: "PartnerCollection",
          title: string,
        } | {
          __typename: "Search",
          title: string,
        } | {
          __typename: "Service",
          title: string,
        } | {
          __typename: "Story",
          title: string,
        } | {
          __typename: "StoryCollection",
          title: string,
        }
      ) | null > | null,
      // Defines the type of dropdown in the top menu (if this is a top-level item).
      menuDropdownType: MenuDropdownTypeEnum | null,
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          title: string,
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              id: number,
              title: string,
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestNetwork",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestInternal",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestDocument",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestWishlist",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestRegistration",
                  uri: string | null,
                } | {
                  __typename: "City",
                  uri: string | null,
                } | {
                  __typename: "ConnectedNetworkCollection",
                  uri: string | null,
                } | {
                  __typename: "Country",
                  uri: string | null,
                } | {
                  __typename: "Documentation",
                  uri: string | null,
                } | {
                  __typename: "Event",
                  uri: string | null,
                } | {
                  __typename: "EventCollection",
                  uri: string | null,
                } | {
                  __typename: "ExchangePage",
                  uri: string | null,
                } | {
                  __typename: "FooterLink",
                  uri: string | null,
                } | {
                  __typename: "GetStarted",
                  uri: string | null,
                } | {
                  __typename: "Graph",
                  uri: string | null,
                } | {
                  __typename: "Info",
                  uri: string | null,
                } | {
                  __typename: "Menu",
                  uri: string | null,
                } | {
                  __typename: "Network",
                  uri: string | null,
                } | {
                  __typename: "NetworkConnection",
                  uri: string | null,
                } | {
                  __typename: "News",
                  uri: string | null,
                } | {
                  __typename: "NewsCollection",
                  uri: string | null,
                } | {
                  __typename: "Newsletter",
                  uri: string | null,
                } | {
                  __typename: "NewsletterCollection",
                  uri: string | null,
                } | {
                  __typename: "Partner",
                  uri: string | null,
                } | {
                  __typename: "PartnerCollection",
                  uri: string | null,
                } | {
                  __typename: "Search",
                  uri: string | null,
                } | {
                  __typename: "Service",
                  uri: string | null,
                } | {
                  __typename: "Story",
                  uri: string | null,
                } | {
                  __typename: "StoryCollection",
                  uri: string | null,
                }
              ) | null > | null,
              // Only fill this in if you want to link to an external webpage. It will override the entry-link.
              externalLink: string | null,
              ancestors:  Array<( {
                  __typename: "ContactRequestPartner",
                  title: string,
                } | {
                  __typename: "ContactRequestNetwork",
                  title: string,
                } | {
                  __typename: "ContactRequestInternal",
                  title: string,
                } | {
                  __typename: "ContactRequestDocument",
                  title: string,
                } | {
                  __typename: "ContactRequestWishlist",
                  title: string,
                } | {
                  __typename: "ContactRequestRegistration",
                  title: string,
                } | {
                  __typename: "City",
                  title: string,
                } | {
                  __typename: "ConnectedNetworkCollection",
                  title: string,
                } | {
                  __typename: "Country",
                  title: string,
                } | {
                  __typename: "Documentation",
                  title: string,
                } | {
                  __typename: "Event",
                  title: string,
                } | {
                  __typename: "EventCollection",
                  title: string,
                } | {
                  __typename: "ExchangePage",
                  title: string,
                } | {
                  __typename: "FooterLink",
                  title: string,
                } | {
                  __typename: "GetStarted",
                  title: string,
                } | {
                  __typename: "Graph",
                  title: string,
                } | {
                  __typename: "Info",
                  title: string,
                } | {
                  __typename: "Menu",
                  title: string,
                } | {
                  __typename: "Network",
                  title: string,
                } | {
                  __typename: "NetworkConnection",
                  title: string,
                } | {
                  __typename: "News",
                  title: string,
                } | {
                  __typename: "NewsCollection",
                  title: string,
                } | {
                  __typename: "Newsletter",
                  title: string,
                } | {
                  __typename: "NewsletterCollection",
                  title: string,
                } | {
                  __typename: "Partner",
                  title: string,
                } | {
                  __typename: "PartnerCollection",
                  title: string,
                } | {
                  __typename: "Search",
                  title: string,
                } | {
                  __typename: "Service",
                  title: string,
                } | {
                  __typename: "Story",
                  title: string,
                } | {
                  __typename: "StoryCollection",
                  title: string,
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
  menuConfig:  {
    __typename: "MenuConfig",
    scheme: MenuScheme | null,
  },
  globals:  {
    __typename: "GlobalsSet",
    exchangeConfig:  {
      __typename: "ExchangeConfig",
      loginUrl: string | null,
      // This URL is used for the subscription form in the site footer.
      mailChimpSubscriptionUrl: string | null,
      // The text that appears in the contact form that can be opened from the site footer.
      footerContactFormText:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | null,
    modalSettings:  {
      __typename: "ModalSettings",
      // Shows the modal on the website
      showModal: boolean | null,
      modalTitle: string | null,
      modalText: string | null,
      // Entry that is used for the 'More information' button
      modalEntry:  Array<( {
          __typename: "ContactRequestPartner",
          title: string,
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          title: string,
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          title: string,
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          title: string,
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          title: string,
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          title: string,
          uri: string | null,
        } | {
          __typename: "City",
          title: string,
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          title: string,
          uri: string | null,
        } | {
          __typename: "Country",
          title: string,
          uri: string | null,
        } | {
          __typename: "Documentation",
          title: string,
          uri: string | null,
        } | {
          __typename: "Event",
          title: string,
          uri: string | null,
        } | {
          __typename: "EventCollection",
          title: string,
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          title: string,
          uri: string | null,
        } | {
          __typename: "FooterLink",
          title: string,
          uri: string | null,
        } | {
          __typename: "GetStarted",
          title: string,
          uri: string | null,
        } | {
          __typename: "Graph",
          title: string,
          uri: string | null,
        } | {
          __typename: "Info",
          title: string,
          uri: string | null,
        } | {
          __typename: "Menu",
          title: string,
          uri: string | null,
        } | {
          __typename: "Network",
          title: string,
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          title: string,
          uri: string | null,
        } | {
          __typename: "News",
          title: string,
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          title: string,
          uri: string | null,
        } | {
          __typename: "Newsletter",
          title: string,
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          title: string,
          uri: string | null,
        } | {
          __typename: "Partner",
          title: string,
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          title: string,
          uri: string | null,
        } | {
          __typename: "Search",
          title: string,
          uri: string | null,
        } | {
          __typename: "Service",
          title: string,
          uri: string | null,
        } | {
          __typename: "Story",
          title: string,
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          title: string,
          uri: string | null,
        }
      ) | null > | null,
    } | null,
    socialMedia:  {
      __typename: "SocialMedia",
      facebookUrl: string | null,
      linkedinUrl: string | null,
      twitterUrl: string | null,
      youtubeUrl: string | null,
    } | null,
  } | null,
  footerLinks:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
      id: number,
      title: string,
      // The page this item links to. This field is ignored if this is a top-level menu item or category.
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface GetStartedQueryVariables {
  exchange: string,
};

export interface GetStartedQuery {
  entry: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
      // The page this item links to. This field is ignored if this is a top-level menu item or category.
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
          title: string,
          // A short description for display in lists and other overviews.
          description: string | null,
          contentBlocks:  Array<( {
              __typename: "ContentBlocksIntro",
              id: string | null,
              heading: string | null,
              textHTML:  {
                __typename: "RedactorFieldData",
                content: string | null,
              } | null,
            } | {
              __typename: "ContentBlocksText",
              id: string | null,
              heading: string | null,
              textHTML:  {
                __typename: "RedactorFieldData",
                content: string | null,
              } | null,
            } | {
              __typename: "ContentBlocksTextWithImage",
              image:  Array<( {
                  __typename: "ImagesVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                } | {
                  __typename: "UploadAssetsVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                }
              ) | null > | null,
              heading: string | null,
              textHTML:  {
                __typename: "RedactorFieldData",
                content: string | null,
              } | null,
              imageAlignment: ImageAlignmentEnum | null,
            } | {
              __typename: "ContentBlocksImage",
              image:  Array<( {
                  __typename: "ImagesVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                } | {
                  __typename: "UploadAssetsVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                }
              ) | null > | null,
              heading: string | null,
              text: string | null,
              buttonText: string | null,
              buttonLink:  Array<( {
                  __typename: "ContactRequestPartner",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestNetwork",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestInternal",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestDocument",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestWishlist",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestRegistration",
                  uri: string | null,
                } | {
                  __typename: "City",
                  uri: string | null,
                } | {
                  __typename: "ConnectedNetworkCollection",
                  uri: string | null,
                } | {
                  __typename: "Country",
                  uri: string | null,
                } | {
                  __typename: "Documentation",
                  uri: string | null,
                } | {
                  __typename: "Event",
                  uri: string | null,
                } | {
                  __typename: "EventCollection",
                  uri: string | null,
                } | {
                  __typename: "ExchangePage",
                  uri: string | null,
                } | {
                  __typename: "FooterLink",
                  uri: string | null,
                } | {
                  __typename: "GetStarted",
                  uri: string | null,
                } | {
                  __typename: "Graph",
                  uri: string | null,
                } | {
                  __typename: "Info",
                  uri: string | null,
                } | {
                  __typename: "Menu",
                  uri: string | null,
                } | {
                  __typename: "Network",
                  uri: string | null,
                } | {
                  __typename: "NetworkConnection",
                  uri: string | null,
                } | {
                  __typename: "News",
                  uri: string | null,
                } | {
                  __typename: "NewsCollection",
                  uri: string | null,
                } | {
                  __typename: "Newsletter",
                  uri: string | null,
                } | {
                  __typename: "NewsletterCollection",
                  uri: string | null,
                } | {
                  __typename: "Partner",
                  uri: string | null,
                } | {
                  __typename: "PartnerCollection",
                  uri: string | null,
                } | {
                  __typename: "Search",
                  uri: string | null,
                } | {
                  __typename: "Service",
                  uri: string | null,
                } | {
                  __typename: "Story",
                  uri: string | null,
                } | {
                  __typename: "StoryCollection",
                  uri: string | null,
                }
              ) | null > | null,
              textAlignment: TextAlignmentEnum | null,
            } | {
              __typename: "ContentBlocksGraphic",
              image:  Array<( {
                  __typename: "ImagesVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  srcOriginal: string | null,
                  widthOriginal: string | null,
                  title: string | null,
                } | {
                  __typename: "UploadAssetsVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  srcOriginal: string | null,
                  widthOriginal: string | null,
                  title: string | null,
                }
              ) | null > | null,
            } | {
              __typename: "ContentBlocksVideo",
              // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
              videoUrl: string | null,
            } | {
              __typename: "ContentBlocksAudio",
              // https://player.rss.com/ix-talks/xxxxxx
              podcastUrl: string | null,
              podcastDescription: string | null,
            } | {
              __typename: "ContentBlocksTable",
              heading: string | null,
              table:  {
                __typename: "RedactorFieldData",
                content: string | null,
              } | null,
            } | {
              __typename: "ContentBlocksCodeSnippet",
              // The language used for syntax highlighting.
              programmingLanguage: ProgrammingLanguageEnum | null,
              snippet: string | null,
            } | {
              __typename: "ContentBlocksContactForm",
              id: string | null,
              // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
              ctaText: string | null,
              // The text that appears on the button that opens the contact form.
              buttonText: string | null,
              // If enabled, this block will be displayed as a single small button instead of a full sized block.
              compactDisplay: boolean | null,
              // If enabled, there will be a option to upload a document in the contact form.
              documentUpload: boolean | null,
              // This text appears below the title in the contact form overlay.
              description:  {
                __typename: "RedactorFieldData",
                content: string | null,
              } | null,
            } | {
              __typename: "ContentBlocksLogos",
              logos:  Array<( {
                  __typename: "ImagesVolume",
                  src: string | null,
                  src2x: string | null,
                  title: string | null,
                  id: number | null,
                } | {
                  __typename: "UploadAssetsVolume",
                  src: string | null,
                  src2x: string | null,
                  title: string | null,
                  id: number | null,
                }
              ) | null > | null,
            } | {
              __typename: "ContentBlocksButton",
              id: string | null,
              label: string | null,
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestNetwork",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestInternal",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestDocument",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestWishlist",
                  uri: string | null,
                } | {
                  __typename: "ContactRequestRegistration",
                  uri: string | null,
                } | {
                  __typename: "City",
                  uri: string | null,
                } | {
                  __typename: "ConnectedNetworkCollection",
                  uri: string | null,
                } | {
                  __typename: "Country",
                  uri: string | null,
                } | {
                  __typename: "Documentation",
                  uri: string | null,
                } | {
                  __typename: "Event",
                  uri: string | null,
                } | {
                  __typename: "EventCollection",
                  uri: string | null,
                } | {
                  __typename: "ExchangePage",
                  uri: string | null,
                } | {
                  __typename: "FooterLink",
                  uri: string | null,
                } | {
                  __typename: "GetStarted",
                  uri: string | null,
                } | {
                  __typename: "Graph",
                  uri: string | null,
                } | {
                  __typename: "Info",
                  uri: string | null,
                } | {
                  __typename: "Menu",
                  uri: string | null,
                } | {
                  __typename: "Network",
                  uri: string | null,
                } | {
                  __typename: "NetworkConnection",
                  uri: string | null,
                } | {
                  __typename: "News",
                  uri: string | null,
                } | {
                  __typename: "NewsCollection",
                  uri: string | null,
                } | {
                  __typename: "Newsletter",
                  uri: string | null,
                } | {
                  __typename: "NewsletterCollection",
                  uri: string | null,
                } | {
                  __typename: "Partner",
                  uri: string | null,
                } | {
                  __typename: "PartnerCollection",
                  uri: string | null,
                } | {
                  __typename: "Search",
                  uri: string | null,
                } | {
                  __typename: "Service",
                  uri: string | null,
                } | {
                  __typename: "Story",
                  uri: string | null,
                } | {
                  __typename: "StoryCollection",
                  uri: string | null,
                }
              ) | null > | null,
            } | {
              __typename: "ContentBlocksSectionEnding",
            } | {
              __typename: "ContentBlocksUsers",
              id: string | null,
              heading: string | null,
              users:  Array< {
                __typename: "User",
                fullName: string | null,
                // This will be shown in the author information on the website.
                functionTitle: string | null,
                photo:  {
                  __typename: "ImagesVolume",
                  url: string | null,
                } | null,
              } | null > | null,
            } | {
              __typename: "ContentBlocksTrafficGraph",
              id: string | null,
              dataset:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                  title: string,
                  interval: IntervalEnum | null,
                  peakIn: number | null,
                  peakOut: number | null,
                  averageIn: number | null,
                  averageOut: number | null,
                  currentIn: number | null,
                  currentOut: number | null,
                  averageInputData:  Array< {
                    __typename: "AverageInputDataTable",
                    // Time
                    time: number | null,
                    // Value (Mbit/s)
                    value: number | null,
                  } | null > | null,
                  averageOutputData:  Array< {
                    __typename: "AverageOutputDataTable",
                    // Time
                    time: number | null,
                    // Value (Mbit/s)
                    value: number | null,
                  } | null > | null,
                  peakOutputData:  Array< {
                    __typename: "PeakOutputDataTable",
                    // Time
                    time: number | null,
                    // Value (Mbit/s)
                    value: number | null,
                  } | null > | null,
                  peakInputData:  Array< {
                    __typename: "PeakInputDataTable",
                    // Time
                    time: number | null,
                    // Value (Mbit/s)
                    value: number | null,
                  } | null > | null,
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "ContentBlocksApplicationForm",
              id: string | null,
              // The text that appears on the button that opens the application form.
              buttonText: string | null,
              applicationType: ApplicationTypeEnum | null,
            } | {
              __typename: "ContentBlocksRegistrationForm",
              id: string | null,
              // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
              ctaText: string | null,
              // The text that appears on the button that opens the contact form.
              buttonText: string | null,
              // If enabled, this block will be displayed as a single small button instead of a full sized block.
              compactDisplay: boolean | null,
              // This text appears below the title in the contact form overlay.
              description:  {
                __typename: "RedactorFieldData",
                content: string | null,
              } | null,
            }
          ) | null > | null,
          // Select items to appear in the "Related" section of the page.
          relatedItems:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
              id: number,
              uri: string | null,
              title: string,
              postDate: string | null,
              relatedItemImage:  Array<( {
                  __typename: "ImagesVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                } | {
                  __typename: "UploadAssetsVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                }
              ) | null > | null,
            } | {
              __typename: "Event",
              id: number,
              uri: string | null,
              title: string,
              postDate: string | null,
              // The main image for this item.
              heroImage:  Array<( {
                  __typename: "ImagesVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                } | {
                  __typename: "UploadAssetsVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                }
              ) | null > | null,
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
              id: number,
              uri: string | null,
              title: string,
              postDate: string | null,
              relatedItemImage:  Array<( {
                  __typename: "ImagesVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                } | {
                  __typename: "UploadAssetsVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                }
              ) | null > | null,
            } | {
              __typename: "Menu",
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
              id: number,
              uri: string | null,
              title: string,
              postDate: string | null,
              // The main image for this item.
              heroImage:  Array<( {
                  __typename: "ImagesVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                } | {
                  __typename: "UploadAssetsVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                }
              ) | null > | null,
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
              id: number,
              uri: string | null,
              title: string,
              postDate: string | null,
              relatedItemImage:  Array<( {
                  __typename: "ImagesVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                } | {
                  __typename: "UploadAssetsVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                }
              ) | null > | null,
            } | {
              __typename: "Story",
              id: number,
              uri: string | null,
              title: string,
              postDate: string | null,
              // The main image for this item.
              heroImage:  Array<( {
                  __typename: "ImagesVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                } | {
                  __typename: "UploadAssetsVolume",
                  srcSmall: string | null,
                  widthSmall: string | null,
                  srcMedium: string | null,
                  widthMedium: string | null,
                  srcLarge: string | null,
                  widthLarge: string | null,
                  title: string | null,
                }
              ) | null > | null,
              service:  Array<( {
                  __typename: "ContactRequestPartner",
                  title: string,
                } | {
                  __typename: "ContactRequestNetwork",
                  title: string,
                } | {
                  __typename: "ContactRequestInternal",
                  title: string,
                } | {
                  __typename: "ContactRequestDocument",
                  title: string,
                } | {
                  __typename: "ContactRequestWishlist",
                  title: string,
                } | {
                  __typename: "ContactRequestRegistration",
                  title: string,
                } | {
                  __typename: "City",
                  title: string,
                } | {
                  __typename: "ConnectedNetworkCollection",
                  title: string,
                } | {
                  __typename: "Country",
                  title: string,
                } | {
                  __typename: "Documentation",
                  title: string,
                } | {
                  __typename: "Event",
                  title: string,
                } | {
                  __typename: "EventCollection",
                  title: string,
                } | {
                  __typename: "ExchangePage",
                  title: string,
                } | {
                  __typename: "FooterLink",
                  title: string,
                } | {
                  __typename: "GetStarted",
                  title: string,
                } | {
                  __typename: "Graph",
                  title: string,
                } | {
                  __typename: "Info",
                  title: string,
                } | {
                  __typename: "Menu",
                  title: string,
                } | {
                  __typename: "Network",
                  title: string,
                } | {
                  __typename: "NetworkConnection",
                  title: string,
                } | {
                  __typename: "News",
                  title: string,
                } | {
                  __typename: "NewsCollection",
                  title: string,
                } | {
                  __typename: "Newsletter",
                  title: string,
                } | {
                  __typename: "NewsletterCollection",
                  title: string,
                } | {
                  __typename: "Partner",
                  title: string,
                } | {
                  __typename: "PartnerCollection",
                  title: string,
                } | {
                  __typename: "Search",
                  title: string,
                } | {
                  __typename: "Service",
                  title: string,
                } | {
                  __typename: "Story",
                  title: string,
                } | {
                  __typename: "StoryCollection",
                  title: string,
                }
              ) | null > | null,
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface HomeQueryVariables {
  site: string,
};

export interface HomeQuery {
  exchangePage: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
      // This text appears in the contact block that can be opened from this page.
      contactFormText:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      // This text appears in the content block above the "Contact us" button.
      contactBlockCtaText:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface InfoQueryVariables {
  exchange: string,
  slug: string,
};

export interface InfoQuery {
  entry: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
      title: string,
      // A short description for display in lists and other overviews.
      description: string | null,
      contentBlocks:  Array<( {
          __typename: "ContentBlocksIntro",
          id: string | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksText",
          id: string | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksTextWithImage",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
          imageAlignment: ImageAlignmentEnum | null,
        } | {
          __typename: "ContentBlocksImage",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          heading: string | null,
          text: string | null,
          buttonText: string | null,
          buttonLink:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
          textAlignment: TextAlignmentEnum | null,
        } | {
          __typename: "ContentBlocksGraphic",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              srcOriginal: string | null,
              widthOriginal: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              srcOriginal: string | null,
              widthOriginal: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksVideo",
          // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
          videoUrl: string | null,
        } | {
          __typename: "ContentBlocksAudio",
          // https://player.rss.com/ix-talks/xxxxxx
          podcastUrl: string | null,
          podcastDescription: string | null,
        } | {
          __typename: "ContentBlocksTable",
          heading: string | null,
          table:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksCodeSnippet",
          // The language used for syntax highlighting.
          programmingLanguage: ProgrammingLanguageEnum | null,
          snippet: string | null,
        } | {
          __typename: "ContentBlocksContactForm",
          id: string | null,
          // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
          ctaText: string | null,
          // The text that appears on the button that opens the contact form.
          buttonText: string | null,
          // If enabled, this block will be displayed as a single small button instead of a full sized block.
          compactDisplay: boolean | null,
          // If enabled, there will be a option to upload a document in the contact form.
          documentUpload: boolean | null,
          // This text appears below the title in the contact form overlay.
          description:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksLogos",
          logos:  Array<( {
              __typename: "ImagesVolume",
              src: string | null,
              src2x: string | null,
              title: string | null,
              id: number | null,
            } | {
              __typename: "UploadAssetsVolume",
              src: string | null,
              src2x: string | null,
              title: string | null,
              id: number | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksButton",
          id: string | null,
          label: string | null,
          linkEntry:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksSectionEnding",
        } | {
          __typename: "ContentBlocksUsers",
          id: string | null,
          heading: string | null,
          users:  Array< {
            __typename: "User",
            fullName: string | null,
            // This will be shown in the author information on the website.
            functionTitle: string | null,
            photo:  {
              __typename: "ImagesVolume",
              url: string | null,
            } | null,
          } | null > | null,
        } | {
          __typename: "ContentBlocksTrafficGraph",
          id: string | null,
          dataset:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
              title: string,
              interval: IntervalEnum | null,
              peakIn: number | null,
              peakOut: number | null,
              averageIn: number | null,
              averageOut: number | null,
              currentIn: number | null,
              currentOut: number | null,
              averageInputData:  Array< {
                __typename: "AverageInputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              averageOutputData:  Array< {
                __typename: "AverageOutputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              peakOutputData:  Array< {
                __typename: "PeakOutputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              peakInputData:  Array< {
                __typename: "PeakInputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksApplicationForm",
          id: string | null,
          // The text that appears on the button that opens the application form.
          buttonText: string | null,
          applicationType: ApplicationTypeEnum | null,
        } | {
          __typename: "ContentBlocksRegistrationForm",
          id: string | null,
          // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
          ctaText: string | null,
          // The text that appears on the button that opens the contact form.
          buttonText: string | null,
          // If enabled, this block will be displayed as a single small button instead of a full sized block.
          compactDisplay: boolean | null,
          // This text appears below the title in the contact form overlay.
          description:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        }
      ) | null > | null,
      // Select items to appear in the "Related" section of the page.
      relatedItems:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Event",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Story",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          service:  Array<( {
              __typename: "ContactRequestPartner",
              title: string,
            } | {
              __typename: "ContactRequestNetwork",
              title: string,
            } | {
              __typename: "ContactRequestInternal",
              title: string,
            } | {
              __typename: "ContactRequestDocument",
              title: string,
            } | {
              __typename: "ContactRequestWishlist",
              title: string,
            } | {
              __typename: "ContactRequestRegistration",
              title: string,
            } | {
              __typename: "City",
              title: string,
            } | {
              __typename: "ConnectedNetworkCollection",
              title: string,
            } | {
              __typename: "Country",
              title: string,
            } | {
              __typename: "Documentation",
              title: string,
            } | {
              __typename: "Event",
              title: string,
            } | {
              __typename: "EventCollection",
              title: string,
            } | {
              __typename: "ExchangePage",
              title: string,
            } | {
              __typename: "FooterLink",
              title: string,
            } | {
              __typename: "GetStarted",
              title: string,
            } | {
              __typename: "Graph",
              title: string,
            } | {
              __typename: "Info",
              title: string,
            } | {
              __typename: "Menu",
              title: string,
            } | {
              __typename: "Network",
              title: string,
            } | {
              __typename: "NetworkConnection",
              title: string,
            } | {
              __typename: "News",
              title: string,
            } | {
              __typename: "NewsCollection",
              title: string,
            } | {
              __typename: "Newsletter",
              title: string,
            } | {
              __typename: "NewsletterCollection",
              title: string,
            } | {
              __typename: "Partner",
              title: string,
            } | {
              __typename: "PartnerCollection",
              title: string,
            } | {
              __typename: "Search",
              title: string,
            } | {
              __typename: "Service",
              title: string,
            } | {
              __typename: "Story",
              title: string,
            } | {
              __typename: "StoryCollection",
              title: string,
            }
          ) | null > | null,
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface SingleNewsPageQueryVariables {
  exchange: string,
  slug: string,
};

export interface SingleNewsPageQuery {
  entry: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
      title: string,
      // A short description for display in lists and other overviews.
      description: string | null,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          src: string | null,
          width: string | null,
          src2x: string | null,
          width2x: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          src: string | null,
          width: string | null,
          src2x: string | null,
          width2x: string | null,
          title: string | null,
        }
      ) | null > | null,
      author:  {
        __typename: "User",
        name: string,
        // This will be shown in the author information on the website.
        functionTitle: string | null,
        photo:  {
          __typename: "ImagesVolume",
          url: string | null,
          title: string | null,
        } | null,
      },
      contentBlocks:  Array<( {
          __typename: "ContentBlocksIntro",
          id: string | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksText",
          id: string | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksTextWithImage",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
          imageAlignment: ImageAlignmentEnum | null,
        } | {
          __typename: "ContentBlocksImage",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          heading: string | null,
          text: string | null,
          buttonText: string | null,
          buttonLink:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
          textAlignment: TextAlignmentEnum | null,
        } | {
          __typename: "ContentBlocksGraphic",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              srcOriginal: string | null,
              widthOriginal: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              srcOriginal: string | null,
              widthOriginal: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksVideo",
          // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
          videoUrl: string | null,
        } | {
          __typename: "ContentBlocksAudio",
          // https://player.rss.com/ix-talks/xxxxxx
          podcastUrl: string | null,
          podcastDescription: string | null,
        } | {
          __typename: "ContentBlocksTable",
          heading: string | null,
          table:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksCodeSnippet",
          // The language used for syntax highlighting.
          programmingLanguage: ProgrammingLanguageEnum | null,
          snippet: string | null,
        } | {
          __typename: "ContentBlocksContactForm",
          id: string | null,
          // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
          ctaText: string | null,
          // The text that appears on the button that opens the contact form.
          buttonText: string | null,
          // If enabled, this block will be displayed as a single small button instead of a full sized block.
          compactDisplay: boolean | null,
          // If enabled, there will be a option to upload a document in the contact form.
          documentUpload: boolean | null,
          // This text appears below the title in the contact form overlay.
          description:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksLogos",
          logos:  Array<( {
              __typename: "ImagesVolume",
              src: string | null,
              src2x: string | null,
              title: string | null,
              id: number | null,
            } | {
              __typename: "UploadAssetsVolume",
              src: string | null,
              src2x: string | null,
              title: string | null,
              id: number | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksButton",
          id: string | null,
          label: string | null,
          linkEntry:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksSectionEnding",
        } | {
          __typename: "ContentBlocksUsers",
          id: string | null,
          heading: string | null,
          users:  Array< {
            __typename: "User",
            fullName: string | null,
            // This will be shown in the author information on the website.
            functionTitle: string | null,
            photo:  {
              __typename: "ImagesVolume",
              url: string | null,
            } | null,
          } | null > | null,
        } | {
          __typename: "ContentBlocksTrafficGraph",
          id: string | null,
          dataset:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
              title: string,
              interval: IntervalEnum | null,
              peakIn: number | null,
              peakOut: number | null,
              averageIn: number | null,
              averageOut: number | null,
              currentIn: number | null,
              currentOut: number | null,
              averageInputData:  Array< {
                __typename: "AverageInputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              averageOutputData:  Array< {
                __typename: "AverageOutputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              peakOutputData:  Array< {
                __typename: "PeakOutputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              peakInputData:  Array< {
                __typename: "PeakInputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksApplicationForm",
          id: string | null,
          // The text that appears on the button that opens the application form.
          buttonText: string | null,
          applicationType: ApplicationTypeEnum | null,
        } | {
          __typename: "ContentBlocksRegistrationForm",
          id: string | null,
          // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
          ctaText: string | null,
          // The text that appears on the button that opens the contact form.
          buttonText: string | null,
          // If enabled, this block will be displayed as a single small button instead of a full sized block.
          compactDisplay: boolean | null,
          // This text appears below the title in the contact form overlay.
          description:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        }
      ) | null > | null,
      // Select items to appear in the "Related" section of the page.
      relatedItems:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Event",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Story",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          service:  Array<( {
              __typename: "ContactRequestPartner",
              title: string,
            } | {
              __typename: "ContactRequestNetwork",
              title: string,
            } | {
              __typename: "ContactRequestInternal",
              title: string,
            } | {
              __typename: "ContactRequestDocument",
              title: string,
            } | {
              __typename: "ContactRequestWishlist",
              title: string,
            } | {
              __typename: "ContactRequestRegistration",
              title: string,
            } | {
              __typename: "City",
              title: string,
            } | {
              __typename: "ConnectedNetworkCollection",
              title: string,
            } | {
              __typename: "Country",
              title: string,
            } | {
              __typename: "Documentation",
              title: string,
            } | {
              __typename: "Event",
              title: string,
            } | {
              __typename: "EventCollection",
              title: string,
            } | {
              __typename: "ExchangePage",
              title: string,
            } | {
              __typename: "FooterLink",
              title: string,
            } | {
              __typename: "GetStarted",
              title: string,
            } | {
              __typename: "Graph",
              title: string,
            } | {
              __typename: "Info",
              title: string,
            } | {
              __typename: "Menu",
              title: string,
            } | {
              __typename: "Network",
              title: string,
            } | {
              __typename: "NetworkConnection",
              title: string,
            } | {
              __typename: "News",
              title: string,
            } | {
              __typename: "NewsCollection",
              title: string,
            } | {
              __typename: "Newsletter",
              title: string,
            } | {
              __typename: "NewsletterCollection",
              title: string,
            } | {
              __typename: "Partner",
              title: string,
            } | {
              __typename: "PartnerCollection",
              title: string,
            } | {
              __typename: "Search",
              title: string,
            } | {
              __typename: "Service",
              title: string,
            } | {
              __typename: "Story",
              title: string,
            } | {
              __typename: "StoryCollection",
              title: string,
            }
          ) | null > | null,
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface PartnersQueryVariables {
  exchange: string,
  country?: string | null,
  city?: string | null,
  search?: string | null,
  partnerType?: PartnerTypeEnum | null,
};

export interface PartnersQuery {
  partnerPage: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
      title: string,
      introHeading: string | null,
      introText:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
  partnersTable:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
      id: number,
      title: string,
      datacenter: string | null,
      city: string | null,
      country: string | null,
      partnerType: PartnerTypeEnum | null,
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
  countries:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
      title: string,
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
  cities:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
      title: string,
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface SearchQueryVariables {
  site: string,
  offset: number,
  itemLimit: number,
  query: string,
};

export interface SearchQuery {
  items:  {
    __typename: "EntryConnection",
    entries:  Array<( {
        __typename: "ContactRequestPartner",
      } | {
        __typename: "ContactRequestNetwork",
      } | {
        __typename: "ContactRequestInternal",
      } | {
        __typename: "ContactRequestDocument",
      } | {
        __typename: "ContactRequestWishlist",
      } | {
        __typename: "ContactRequestRegistration",
      } | {
        __typename: "City",
      } | {
        __typename: "ConnectedNetworkCollection",
      } | {
        __typename: "Country",
      } | {
        __typename: "Documentation",
        id: number,
        uri: string | null,
        title: string,
        // A short description for display in lists and other overviews.
        description: string | null,
      } | {
        __typename: "Event",
        id: number,
        uri: string | null,
        title: string,
        // A short description for display in lists and other overviews.
        description: string | null,
        // The main image for this item.
        heroImage:  Array<( {
            __typename: "ImagesVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          } | {
            __typename: "UploadAssetsVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          }
        ) | null > | null,
        // For a one-day event, only fill in the start date.
        startDate: string | null,
        endDate: string | null,
        date: string | null,
        // Event will be shown in the past events tab.
        listInPastEvents: boolean | null,
        location: string | null,
      } | {
        __typename: "EventCollection",
      } | {
        __typename: "ExchangePage",
      } | {
        __typename: "FooterLink",
      } | {
        __typename: "GetStarted",
      } | {
        __typename: "Graph",
      } | {
        __typename: "Info",
        id: number,
        uri: string | null,
        title: string,
        // A short description for display in lists and other overviews.
        description: string | null,
      } | {
        __typename: "Menu",
      } | {
        __typename: "Network",
      } | {
        __typename: "NetworkConnection",
      } | {
        __typename: "News",
        id: number,
        uri: string | null,
        title: string,
        // A short description for display in lists and other overviews.
        description: string | null,
        // The main image for this item.
        heroImage:  Array<( {
            __typename: "ImagesVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          } | {
            __typename: "UploadAssetsVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          }
        ) | null > | null,
        date: string | null,
      } | {
        __typename: "NewsCollection",
      } | {
        __typename: "Newsletter",
      } | {
        __typename: "NewsletterCollection",
      } | {
        __typename: "Partner",
      } | {
        __typename: "PartnerCollection",
      } | {
        __typename: "Search",
      } | {
        __typename: "Service",
        id: number,
        uri: string | null,
        title: string,
        // A short description for display in lists and other overviews.
        description: string | null,
        // The main service icon, shown in the Services menu and on the Service page.
        heroImage:  Array<( {
            __typename: "ImagesVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          } | {
            __typename: "UploadAssetsVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          }
        ) | null > | null,
      } | {
        __typename: "Story",
        id: number,
        uri: string | null,
        title: string,
        // A short description for display in lists and other overviews.
        description: string | null,
        // The main image for this item.
        heroImage:  Array<( {
            __typename: "ImagesVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          } | {
            __typename: "UploadAssetsVolume",
            src: string | null,
            width: string | null,
            src2x: string | null,
            width2x: string | null,
            title: string | null,
          }
        ) | null > | null,
        date: string | null,
      } | {
        __typename: "StoryCollection",
      }
    ) | null > | null,
    totalCount: number,
    pageInfo:  {
      __typename: "PageInfo",
      totalPages: number | null,
    } | null,
  } | null,
  page: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
      introHeading: string | null,
      introText:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface ServiceQueryVariables {
  exchange: string,
  slug: string,
};

export interface ServiceQuery {
  service: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
      id: number,
      title: string,
      // Text that appears in the "How it works" section on the service page.
      howItWorks:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      // A short description for display in lists and other overviews.
      description: string | null,
      // Text and/or tables to appear in the "Pricing" section of the Service page.
      pricing:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      // Enable this when the user should be able to select between durations.
      showDurationSwitch: boolean | null,
      commitmentModule:  Array<( {
          __typename: "CommitmentModuleSpeedBlock",
          blockTitle: string | null,
          price3Months: string | null,
          price12Months: string | null,
          price24Months: string | null,
          price36Months: string | null,
          checkmarkList:  Array< {
            __typename: "CheckmarkListTable",
            // List item
            listItem: string | null,
          } | null > | null,
        }
      ) | null > | null,
      // The main service icon, shown in the Services menu and on the Service page.
      icon:  Array<( {
          __typename: "ImagesVolume",
          src: string | null,
          width: string | null,
          src2x: string | null,
          width2x: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          src: string | null,
          width: string | null,
          src2x: string | null,
          width2x: string | null,
          title: string | null,
        }
      ) | null > | null,
      // The benefits that appear in the "Benefits" section of the Service page.
      benefits:  Array<( {
          __typename: "BenefitsBenefit",
          icon:  Array<( {
              __typename: "ImagesVolume",
              url: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              url: string | null,
            }
          ) | null > | null,
          heading: string | null,
          // Short explanation shown below the title.
          description: string | null,
        }
      ) | null > | null,
      // This story will be showcased on the service page. Leave empty to omit the showcase.
      storyShowcase:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
          title: string,
          uri: string | null,
          // A short description for display in lists and other overviews.
          description: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
      // This text appears in the content block above the "Contact us" button.
      contactBlockCtaText:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      // This text appears in the contact block that can be opened from this page.
      contactFormText:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      // Select items to appear in the "Related" section of the page.
      relatedItems:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Event",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Story",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          service:  Array<( {
              __typename: "ContactRequestPartner",
              title: string,
            } | {
              __typename: "ContactRequestNetwork",
              title: string,
            } | {
              __typename: "ContactRequestInternal",
              title: string,
            } | {
              __typename: "ContactRequestDocument",
              title: string,
            } | {
              __typename: "ContactRequestWishlist",
              title: string,
            } | {
              __typename: "ContactRequestRegistration",
              title: string,
            } | {
              __typename: "City",
              title: string,
            } | {
              __typename: "ConnectedNetworkCollection",
              title: string,
            } | {
              __typename: "Country",
              title: string,
            } | {
              __typename: "Documentation",
              title: string,
            } | {
              __typename: "Event",
              title: string,
            } | {
              __typename: "EventCollection",
              title: string,
            } | {
              __typename: "ExchangePage",
              title: string,
            } | {
              __typename: "FooterLink",
              title: string,
            } | {
              __typename: "GetStarted",
              title: string,
            } | {
              __typename: "Graph",
              title: string,
            } | {
              __typename: "Info",
              title: string,
            } | {
              __typename: "Menu",
              title: string,
            } | {
              __typename: "Network",
              title: string,
            } | {
              __typename: "NetworkConnection",
              title: string,
            } | {
              __typename: "News",
              title: string,
            } | {
              __typename: "NewsCollection",
              title: string,
            } | {
              __typename: "Newsletter",
              title: string,
            } | {
              __typename: "NewsletterCollection",
              title: string,
            } | {
              __typename: "Partner",
              title: string,
            } | {
              __typename: "PartnerCollection",
              title: string,
            } | {
              __typename: "Search",
              title: string,
            } | {
              __typename: "Service",
              title: string,
            } | {
              __typename: "Story",
              title: string,
            } | {
              __typename: "StoryCollection",
              title: string,
            }
          ) | null > | null,
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface StoryQueryVariables {
  exchange: string,
  slug: string,
};

export interface StoryQuery {
  entry: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
      title: string,
      // A short description for display in lists and other overviews.
      description: string | null,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      author:  {
        __typename: "User",
        name: string,
        // This will be shown in the author information on the website.
        functionTitle: string | null,
        photo:  {
          __typename: "ImagesVolume",
          url: string | null,
          title: string | null,
        } | null,
      },
      service:  Array<( {
          __typename: "ContactRequestPartner",
          title: string,
        } | {
          __typename: "ContactRequestNetwork",
          title: string,
        } | {
          __typename: "ContactRequestInternal",
          title: string,
        } | {
          __typename: "ContactRequestDocument",
          title: string,
        } | {
          __typename: "ContactRequestWishlist",
          title: string,
        } | {
          __typename: "ContactRequestRegistration",
          title: string,
        } | {
          __typename: "City",
          title: string,
        } | {
          __typename: "ConnectedNetworkCollection",
          title: string,
        } | {
          __typename: "Country",
          title: string,
        } | {
          __typename: "Documentation",
          title: string,
        } | {
          __typename: "Event",
          title: string,
        } | {
          __typename: "EventCollection",
          title: string,
        } | {
          __typename: "ExchangePage",
          title: string,
        } | {
          __typename: "FooterLink",
          title: string,
        } | {
          __typename: "GetStarted",
          title: string,
        } | {
          __typename: "Graph",
          title: string,
        } | {
          __typename: "Info",
          title: string,
        } | {
          __typename: "Menu",
          title: string,
        } | {
          __typename: "Network",
          title: string,
        } | {
          __typename: "NetworkConnection",
          title: string,
        } | {
          __typename: "News",
          title: string,
        } | {
          __typename: "NewsCollection",
          title: string,
        } | {
          __typename: "Newsletter",
          title: string,
        } | {
          __typename: "NewsletterCollection",
          title: string,
        } | {
          __typename: "Partner",
          title: string,
        } | {
          __typename: "PartnerCollection",
          title: string,
        } | {
          __typename: "Search",
          title: string,
        } | {
          __typename: "Service",
          title: string,
        } | {
          __typename: "Story",
          title: string,
        } | {
          __typename: "StoryCollection",
          title: string,
        }
      ) | null > | null,
      contentBlocks:  Array<( {
          __typename: "ContentBlocksIntro",
          id: string | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksText",
          id: string | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksTextWithImage",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
          imageAlignment: ImageAlignmentEnum | null,
        } | {
          __typename: "ContentBlocksImage",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          heading: string | null,
          text: string | null,
          buttonText: string | null,
          buttonLink:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
          textAlignment: TextAlignmentEnum | null,
        } | {
          __typename: "ContentBlocksGraphic",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              srcOriginal: string | null,
              widthOriginal: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              srcOriginal: string | null,
              widthOriginal: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksVideo",
          // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
          videoUrl: string | null,
        } | {
          __typename: "ContentBlocksAudio",
          // https://player.rss.com/ix-talks/xxxxxx
          podcastUrl: string | null,
          podcastDescription: string | null,
        } | {
          __typename: "ContentBlocksTable",
          heading: string | null,
          table:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksCodeSnippet",
          // The language used for syntax highlighting.
          programmingLanguage: ProgrammingLanguageEnum | null,
          snippet: string | null,
        } | {
          __typename: "ContentBlocksContactForm",
          id: string | null,
          // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
          ctaText: string | null,
          // The text that appears on the button that opens the contact form.
          buttonText: string | null,
          // If enabled, this block will be displayed as a single small button instead of a full sized block.
          compactDisplay: boolean | null,
          // If enabled, there will be a option to upload a document in the contact form.
          documentUpload: boolean | null,
          // This text appears below the title in the contact form overlay.
          description:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksLogos",
          logos:  Array<( {
              __typename: "ImagesVolume",
              src: string | null,
              src2x: string | null,
              title: string | null,
              id: number | null,
            } | {
              __typename: "UploadAssetsVolume",
              src: string | null,
              src2x: string | null,
              title: string | null,
              id: number | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksButton",
          id: string | null,
          label: string | null,
          linkEntry:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksSectionEnding",
        } | {
          __typename: "ContentBlocksUsers",
          id: string | null,
          heading: string | null,
          users:  Array< {
            __typename: "User",
            fullName: string | null,
            // This will be shown in the author information on the website.
            functionTitle: string | null,
            photo:  {
              __typename: "ImagesVolume",
              url: string | null,
            } | null,
          } | null > | null,
        } | {
          __typename: "ContentBlocksTrafficGraph",
          id: string | null,
          dataset:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
              title: string,
              interval: IntervalEnum | null,
              peakIn: number | null,
              peakOut: number | null,
              averageIn: number | null,
              averageOut: number | null,
              currentIn: number | null,
              currentOut: number | null,
              averageInputData:  Array< {
                __typename: "AverageInputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              averageOutputData:  Array< {
                __typename: "AverageOutputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              peakOutputData:  Array< {
                __typename: "PeakOutputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              peakInputData:  Array< {
                __typename: "PeakInputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksApplicationForm",
          id: string | null,
          // The text that appears on the button that opens the application form.
          buttonText: string | null,
          applicationType: ApplicationTypeEnum | null,
        } | {
          __typename: "ContentBlocksRegistrationForm",
          id: string | null,
          // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
          ctaText: string | null,
          // The text that appears on the button that opens the contact form.
          buttonText: string | null,
          // If enabled, this block will be displayed as a single small button instead of a full sized block.
          compactDisplay: boolean | null,
          // This text appears below the title in the contact form overlay.
          description:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        }
      ) | null > | null,
      // Select items to appear in the "Related" section of the page.
      relatedItems:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Event",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Story",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          service:  Array<( {
              __typename: "ContactRequestPartner",
              title: string,
            } | {
              __typename: "ContactRequestNetwork",
              title: string,
            } | {
              __typename: "ContactRequestInternal",
              title: string,
            } | {
              __typename: "ContactRequestDocument",
              title: string,
            } | {
              __typename: "ContactRequestWishlist",
              title: string,
            } | {
              __typename: "ContactRequestRegistration",
              title: string,
            } | {
              __typename: "City",
              title: string,
            } | {
              __typename: "ConnectedNetworkCollection",
              title: string,
            } | {
              __typename: "Country",
              title: string,
            } | {
              __typename: "Documentation",
              title: string,
            } | {
              __typename: "Event",
              title: string,
            } | {
              __typename: "EventCollection",
              title: string,
            } | {
              __typename: "ExchangePage",
              title: string,
            } | {
              __typename: "FooterLink",
              title: string,
            } | {
              __typename: "GetStarted",
              title: string,
            } | {
              __typename: "Graph",
              title: string,
            } | {
              __typename: "Info",
              title: string,
            } | {
              __typename: "Menu",
              title: string,
            } | {
              __typename: "Network",
              title: string,
            } | {
              __typename: "NetworkConnection",
              title: string,
            } | {
              __typename: "News",
              title: string,
            } | {
              __typename: "NewsCollection",
              title: string,
            } | {
              __typename: "Newsletter",
              title: string,
            } | {
              __typename: "NewsletterCollection",
              title: string,
            } | {
              __typename: "Partner",
              title: string,
            } | {
              __typename: "PartnerCollection",
              title: string,
            } | {
              __typename: "Search",
              title: string,
            } | {
              __typename: "Service",
              title: string,
            } | {
              __typename: "Story",
              title: string,
            } | {
              __typename: "StoryCollection",
              title: string,
            }
          ) | null > | null,
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface sendContactRequestMutationVariables {
  siteId?: number | null,
  subject?: string | null,
  comment?: string | null,
  emailAddress?: string | null,
  fullName?: string | null,
  company?: string | null,
  phoneNumber?: string | null,
  contactFormId?: number | null,
  recaptcha?: string | null,
  siteLocation?: string | null,
};

export interface sendContactRequestMutation {
  // Create or update existing ContactRequestInternal.
  upsertContactRequestInternal:  {
    __typename: "ContactRequestInternal",
    mailSent: boolean | null,
  } | null,
};

export interface sendContactRequestDocumentMutationVariables {
  siteId?: number | null,
  comment?: string | null,
  emailAddress?: string | null,
  fullName?: string | null,
  company?: string | null,
  phoneNumber?: string | null,
  contactFormId?: number | null,
  recaptcha?: string | null,
  cv?: number | null,
  siteLocation?: string | null,
};

export interface sendContactRequestDocumentMutation {
  // Create or update existing ContactRequestDocument.
  upsertContactRequestDocument:  {
    __typename: "ContactRequestDocument",
    mailSent: boolean | null,
  } | null,
};

export interface sendContactRequestToPartnerMutationVariables {
  siteId?: number | null,
  comment?: string | null,
  emailAddress?: string | null,
  fullName?: string | null,
  company?: string | null,
  phoneNumber?: string | null,
  partner?: Array< number | null > | null,
  recaptcha?: string | null,
  siteLocation?: string | null,
};

export interface sendContactRequestToPartnerMutation {
  // Create or update existing ContactRequestPartner.
  upsertContactRequestPartner:  {
    __typename: "ContactRequestPartner",
    mailSent: boolean | null,
  } | null,
};

export interface sendContactRequestToNetworkMutationVariables {
  siteId?: number | null,
  comment?: string | null,
  emailAddress?: string | null,
  fullName?: string | null,
  company?: string | null,
  phoneNumber?: string | null,
  connectedNetwork?: Array< number | null > | null,
  recaptcha?: string | null,
  siteLocation?: string | null,
};

export interface sendContactRequestToNetworkMutation {
  // Create or update existing ContactRequestNetwork.
  upsertContactRequestNetwork:  {
    __typename: "ContactRequestNetwork",
    mailSent: boolean | null,
  } | null,
};

export interface sendContactRequestForWishlistMutationVariables {
  siteId?: number | null,
  subject?: string | null,
  emailAddress?: string | null,
  fullName?: string | null,
  company?: string | null,
  contactFormId?: number | null,
  recaptcha?: string | null,
  siteLocation?: string | null,
  requestedAs?: string | null,
  yourAsNumber?: string | null,
};

export interface sendContactRequestForWishlistMutation {
  // Create or update existing ContactRequestWishlist.
  upsertContactRequestWishlist:  {
    __typename: "ContactRequestWishlist",
    mailSent: boolean | null,
  } | null,
};

export interface sendContactRequestForRegistrationMutationVariables {
  siteId?: number | null,
  firstName?: string | null,
  lastName?: string | null,
  organization?: string | null,
  jobTitle?: string | null,
  workEmail?: string | null,
  contactFormId?: number | null,
  recaptcha?: string | null,
  siteLocation?: string | null,
};

export interface sendContactRequestForRegistrationMutation {
  // Create or update existing ContactRequestRegistration.
  upsertContactRequestRegistration:  {
    __typename: "ContactRequestRegistration",
    mailSent: boolean | null,
  } | null,
};

export interface MenuStatsQueryVariables {
  site: string,
};

export interface MenuStatsQuery {
  stats:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
      averageInputData:  Array< {
        __typename: "AverageInputDataTable",
        // Time
        time: number | null,
        // Value (Mbit/s)
        value: number | null,
      } | null > | null,
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
  vitals:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/vitals
      currentTraffic: number | null,
      // amsix/import/vitals
      peakTraffic: number | null,
      // amsix/import/counts
      connectedNetworks: number | null,
    } | null,
  } | null,
};

export interface GlossaryQueryQuery {
  globals:  {
    __typename: "GlobalsSet",
    glossary:  {
      __typename: "Glossary",
      glossaryTable:  Array< {
        __typename: "GlossaryTableTable",
        // Meaning
        meaning: string | null,
        // Word
        word: string | null,
      } | null > | null,
    } | null,
  } | null,
};

export interface DropDownStatsQueryVariables {
  site: string,
};

export interface DropDownStatsQuery {
  dropdownStats:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
      title: string,
      interval: IntervalEnum | null,
      peakIn: number | null,
      peakOut: number | null,
      averageIn: number | null,
      averageOut: number | null,
      currentIn: number | null,
      currentOut: number | null,
      averageInputData:  Array< {
        __typename: "AverageInputDataTable",
        // Time
        time: number | null,
        // Value (Mbit/s)
        value: number | null,
      } | null > | null,
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
  vitals:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/vitals
      peakTraffic: number | null,
    } | null,
  } | null,
};

export interface PinpointCardStatsQueryVariables {
  ams: string,
  bay: string,
  car: string,
  chi: string,
  hk: string,
  mum: string,
  kol: string,
  sin: string,
  hyd: string,
  lag: string,
  ban: string,
  dji: string,
};

export interface PinpointCardStatsQuery {
  vitalsAms:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/vitals
      peakTraffic: number | null,
      // amsix/import/counts
      connectedNetworks: number | null,
    } | null,
  } | null,
  vitalsBay:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/vitals
      peakTraffic: number | null,
      // amsix/import/counts
      connectedNetworks: number | null,
    } | null,
  } | null,
  vitalsCar:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/vitals
      peakTraffic: number | null,
      // amsix/import/counts
      connectedNetworks: number | null,
    } | null,
  } | null,
  vitalsChi:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/vitals
      peakTraffic: number | null,
      // amsix/import/counts
      connectedNetworks: number | null,
    } | null,
  } | null,
  vitalsHK:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/vitals
      peakTraffic: number | null,
      // amsix/import/counts
      connectedNetworks: number | null,
    } | null,
  } | null,
  vitalsMum:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/vitals
      peakTraffic: number | null,
      // amsix/import/counts
      connectedNetworks: number | null,
    } | null,
  } | null,
  vitalsKol:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/vitals
      peakTraffic: number | null,
      // amsix/import/counts
      connectedNetworks: number | null,
    } | null,
  } | null,
  vitalsSin:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/vitals
      peakTraffic: number | null,
      // amsix/import/counts
      connectedNetworks: number | null,
    } | null,
  } | null,
  vitalsHyd:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/vitals
      peakTraffic: number | null,
      // amsix/import/counts
      connectedNetworks: number | null,
    } | null,
  } | null,
  vitalsLag:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/vitals
      peakTraffic: number | null,
      // amsix/import/counts
      connectedNetworks: number | null,
    } | null,
  } | null,
  vitalsBan:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/vitals
      peakTraffic: number | null,
      // amsix/import/counts
      connectedNetworks: number | null,
    } | null,
  } | null,
  vitalsDji:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/vitals
      peakTraffic: number | null,
      // amsix/import/counts
      connectedNetworks: number | null,
    } | null,
  } | null,
};

export interface StatsQueryVariables {
  site: string,
};

export interface StatsQuery {
  exchangePage: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
      // The text that appears in the orange block in the "... in numbers" section.
      numbersCtaText: string | null,
      // Hides the block containing the colocation count from the "... in numbers" section.
      hideColocations: boolean | null,
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
  vitals:  {
    __typename: "GlobalsSet",
    globalStats:  {
      __typename: "GlobalStats",
      // amsix/import/counts
      connectedNetworks: number | null,
      // amsix/import/vitals
      peakTraffic: number | null,
      // amsix/import/counts
      colocations: number | null,
    } | null,
  } | null,
  whereToConnectPage: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
      // The page this item links to. This field is ignored if this is a top-level menu item or category.
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface ConnectedNetworkQueryVariables {
  exchange: string,
  id?: Array< number | null > | null,
};

export interface ConnectedNetworkQuery {
  connectedNetwork: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
      connections:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
          IPv4: string | null,
          IPv6: string | null,
          Speed: string | null,
          Location: string | null,
          VLAN: string | null,
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface ContactSlideoverQueryVariables {
  site: string,
};

export interface ContactSlideoverQuery {
  globals:  {
    __typename: "GlobalsSet",
    exchangeConfig:  {
      __typename: "ExchangeConfig",
      address: string | null,
      contactInfo: string | null,
    } | null,
    socialMedia:  {
      __typename: "SocialMedia",
      facebookUrl: string | null,
      linkedinUrl: string | null,
      twitterUrl: string | null,
      youtubeUrl: string | null,
    } | null,
    email:  {
      __typename: "Email",
      // If set, the subject for Registration Emails will include this text
      registrationEmailAdditionalSubject: string | null,
    } | null,
  } | null,
};

export interface EventsQueryVariables {
  listExpiryDate: string,
  site: string,
};

export interface EventsQuery {
  events:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
      title: string,
      location: string | null,
      // For a one-day event, only fill in the start date.
      startDate: string | null,
      endDate: string | null,
      id: number,
      uri: string | null,
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
  next: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
      title: string,
      location: string | null,
      // For a one-day event, only fill in the start date.
      startDate: string | null,
      endDate: string | null,
      id: number,
      uri: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
  exchangePage: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
      // Select an Event to be displayed larger and with image in the "Events" section.
      featuredEvent:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
          title: string,
          location: string | null,
          // For a one-day event, only fill in the start date.
          startDate: string | null,
          endDate: string | null,
          id: number,
          uri: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface HeroQueryVariables {
  site: string,
};

export interface HeroQuery {
  exchangePage: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
      // A short description for display in lists and other overviews.
      description: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface NewsQueryVariables {
  site: string,
};

export interface NewsQuery {
  news:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
      id: number,
      uri: string | null,
      title: string,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          src: string | null,
          width: string | null,
          src2x: string | null,
          width2x: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          src: string | null,
          width: string | null,
          src2x: string | null,
          width2x: string | null,
          title: string | null,
        }
      ) | null > | null,
      postDate: string | null,
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface FeaturedStoriesQueryVariables {
  site: string,
};

export interface FeaturedStoriesQuery {
  exchangePage: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
      // Select the stories to appear in the "Featured" section.
      featuredStories:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
          id: number,
          title: string,
          uri: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          // A short description for display in lists and other overviews.
          description: string | null,
          service:  Array<( {
              __typename: "ContactRequestPartner",
              title: string,
            } | {
              __typename: "ContactRequestNetwork",
              title: string,
            } | {
              __typename: "ContactRequestInternal",
              title: string,
            } | {
              __typename: "ContactRequestDocument",
              title: string,
            } | {
              __typename: "ContactRequestWishlist",
              title: string,
            } | {
              __typename: "ContactRequestRegistration",
              title: string,
            } | {
              __typename: "City",
              title: string,
            } | {
              __typename: "ConnectedNetworkCollection",
              title: string,
            } | {
              __typename: "Country",
              title: string,
            } | {
              __typename: "Documentation",
              title: string,
            } | {
              __typename: "Event",
              title: string,
            } | {
              __typename: "EventCollection",
              title: string,
            } | {
              __typename: "ExchangePage",
              title: string,
            } | {
              __typename: "FooterLink",
              title: string,
            } | {
              __typename: "GetStarted",
              title: string,
            } | {
              __typename: "Graph",
              title: string,
            } | {
              __typename: "Info",
              title: string,
            } | {
              __typename: "Menu",
              title: string,
            } | {
              __typename: "Network",
              title: string,
            } | {
              __typename: "NetworkConnection",
              title: string,
            } | {
              __typename: "News",
              title: string,
            } | {
              __typename: "NewsCollection",
              title: string,
            } | {
              __typename: "Newsletter",
              title: string,
            } | {
              __typename: "NewsletterCollection",
              title: string,
            } | {
              __typename: "Partner",
              title: string,
            } | {
              __typename: "PartnerCollection",
              title: string,
            } | {
              __typename: "Search",
              title: string,
            } | {
              __typename: "Service",
              title: string,
            } | {
              __typename: "Story",
              title: string,
            } | {
              __typename: "StoryCollection",
              title: string,
            }
          ) | null > | null,
          postDate: string | null,
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface ServiceHowItWorksQueryVariables {
  exchange: string,
  slug: string,
};

export interface ServiceHowItWorksQuery {
  service: ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
      id: number,
      contentBlocks:  Array<( {
          __typename: "ContentBlocksIntro",
          id: string | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksText",
          id: string | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksTextWithImage",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
          imageAlignment: ImageAlignmentEnum | null,
        } | {
          __typename: "ContentBlocksImage",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          heading: string | null,
          text: string | null,
          buttonText: string | null,
          buttonLink:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
          textAlignment: TextAlignmentEnum | null,
        } | {
          __typename: "ContentBlocksGraphic",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              srcOriginal: string | null,
              widthOriginal: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              srcOriginal: string | null,
              widthOriginal: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksVideo",
          // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
          videoUrl: string | null,
        } | {
          __typename: "ContentBlocksAudio",
          // https://player.rss.com/ix-talks/xxxxxx
          podcastUrl: string | null,
          podcastDescription: string | null,
        } | {
          __typename: "ContentBlocksTable",
          heading: string | null,
          table:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksCodeSnippet",
          // The language used for syntax highlighting.
          programmingLanguage: ProgrammingLanguageEnum | null,
          snippet: string | null,
        } | {
          __typename: "ContentBlocksContactForm",
          id: string | null,
          // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
          ctaText: string | null,
          // The text that appears on the button that opens the contact form.
          buttonText: string | null,
          // If enabled, this block will be displayed as a single small button instead of a full sized block.
          compactDisplay: boolean | null,
          // If enabled, there will be a option to upload a document in the contact form.
          documentUpload: boolean | null,
          // This text appears below the title in the contact form overlay.
          description:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksLogos",
          logos:  Array<( {
              __typename: "ImagesVolume",
              src: string | null,
              src2x: string | null,
              title: string | null,
              id: number | null,
            } | {
              __typename: "UploadAssetsVolume",
              src: string | null,
              src2x: string | null,
              title: string | null,
              id: number | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksButton",
          id: string | null,
          label: string | null,
          linkEntry:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksSectionEnding",
        } | {
          __typename: "ContentBlocksUsers",
          id: string | null,
          heading: string | null,
          users:  Array< {
            __typename: "User",
            fullName: string | null,
            // This will be shown in the author information on the website.
            functionTitle: string | null,
            photo:  {
              __typename: "ImagesVolume",
              url: string | null,
            } | null,
          } | null > | null,
        } | {
          __typename: "ContentBlocksTrafficGraph",
          id: string | null,
          dataset:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
              title: string,
              interval: IntervalEnum | null,
              peakIn: number | null,
              peakOut: number | null,
              averageIn: number | null,
              averageOut: number | null,
              currentIn: number | null,
              currentOut: number | null,
              averageInputData:  Array< {
                __typename: "AverageInputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              averageOutputData:  Array< {
                __typename: "AverageOutputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              peakOutputData:  Array< {
                __typename: "PeakOutputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              peakInputData:  Array< {
                __typename: "PeakInputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksApplicationForm",
          id: string | null,
          // The text that appears on the button that opens the application form.
          buttonText: string | null,
          applicationType: ApplicationTypeEnum | null,
        } | {
          __typename: "ContentBlocksRegistrationForm",
          id: string | null,
          // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
          ctaText: string | null,
          // The text that appears on the button that opens the contact form.
          buttonText: string | null,
          // If enabled, this block will be displayed as a single small button instead of a full sized block.
          compactDisplay: boolean | null,
          // This text appears below the title in the contact form overlay.
          description:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        }
      ) | null > | null,
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null,
};

export interface ServicesQueryVariables {
  site: string,
};

export interface ServicesQuery {
  services: ( {
      __typename: "ContactRequestPartner",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContactRequestNetwork",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContactRequestInternal",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContactRequestDocument",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContactRequestWishlist",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContactRequestRegistration",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "City",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ConnectedNetworkCollection",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Country",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Documentation",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Event",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "EventCollection",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ExchangePage",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "FooterLink",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "GetStarted",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Graph",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Info",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Menu",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Network",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "NetworkConnection",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "News",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "NewsCollection",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Newsletter",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "NewsletterCollection",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Partner",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "PartnerCollection",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Search",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Service",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Story",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "StoryCollection",
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          children:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
              // The page this item links to. This field is ignored if this is a top-level menu item or category.
              linkEntry:  Array<( {
                  __typename: "ContactRequestPartner",
                } | {
                  __typename: "ContactRequestNetwork",
                } | {
                  __typename: "ContactRequestInternal",
                } | {
                  __typename: "ContactRequestDocument",
                } | {
                  __typename: "ContactRequestWishlist",
                } | {
                  __typename: "ContactRequestRegistration",
                } | {
                  __typename: "City",
                } | {
                  __typename: "ConnectedNetworkCollection",
                } | {
                  __typename: "Country",
                } | {
                  __typename: "Documentation",
                } | {
                  __typename: "Event",
                } | {
                  __typename: "EventCollection",
                } | {
                  __typename: "ExchangePage",
                } | {
                  __typename: "FooterLink",
                } | {
                  __typename: "GetStarted",
                } | {
                  __typename: "Graph",
                } | {
                  __typename: "Info",
                } | {
                  __typename: "Menu",
                } | {
                  __typename: "Network",
                } | {
                  __typename: "NetworkConnection",
                } | {
                  __typename: "News",
                } | {
                  __typename: "NewsCollection",
                } | {
                  __typename: "Newsletter",
                } | {
                  __typename: "NewsletterCollection",
                } | {
                  __typename: "Partner",
                } | {
                  __typename: "PartnerCollection",
                } | {
                  __typename: "Search",
                } | {
                  __typename: "Service",
                  id: number,
                  title: string,
                  // A short description for display in lists and other overviews.
                  description: string | null,
                  uri: string | null,
                  slug: string,
                  // The main service icon, shown in the Services menu and on the Service page.
                  icon:  Array<( {
                      __typename: "ImagesVolume",
                      url: string | null,
                      title: string | null,
                    } | {
                      __typename: "UploadAssetsVolume",
                      url: string | null,
                      title: string | null,
                    }
                  ) | null > | null,
                } | {
                  __typename: "Story",
                } | {
                  __typename: "StoryCollection",
                }
              ) | null > | null,
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    }
  ) | null,
};

export interface WishlistSlideOverQueryVariables {
  site: string,
};

export interface WishlistSlideOverQuery {
  globals:  {
    __typename: "GlobalsSet",
    exchangeConfig:  {
      __typename: "ExchangeConfig",
      address: string | null,
      contactInfo: string | null,
    } | null,
    socialMedia:  {
      __typename: "SocialMedia",
      facebookUrl: string | null,
      linkedinUrl: string | null,
      twitterUrl: string | null,
      youtubeUrl: string | null,
    } | null,
  } | null,
};

export interface setSchemeMutationVariables {
  theme?: MenuScheme | null,
};

export interface setSchemeMutation {
  setScheme:  {
    __typename: "MenuConfig",
    scheme: MenuScheme | null,
  } | null,
};

export interface EventCollectionPageFragment {
  __typename: "EventCollection",
  introHeading: string | null,
  introText:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
};

export interface NewsCollectionPageFragment {
  __typename: "NewsCollection",
  introHeading: string | null,
  introText:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
};

export interface NewsletterCollectionPageFragment {
  __typename: "NewsletterCollection",
  introHeading: string | null,
  introText:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
};

export interface StoriesCollectionPageFragment {
  __typename: "StoryCollection",
  introHeading: string | null,
  introText:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
  // Select the stories to appear in the "Featured" section.
  featuredStories:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
      id: number,
      uri: string | null,
      title: string,
      // A short description for display in lists and other overviews.
      description: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      postDate: string | null,
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface ConnectedNetworksStatsFragment {
  __typename: "GlobalStats",
  // amsix/import/counts
  routeserverPeers: number | null,
  // amsix/import/counts
  connectedNetworks: number | null,
  // amsix/import/counts
  customerPorts: number | null,
  // amsix/import/counts
  ipv6Peers: number | null,
};

export interface ConnectedNetworkInfoFragment {
  __typename: "ConnectedNetworkCollection",
  title: string,
  introHeading: string | null,
  introText:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
  // The links included in the "Export as" dropdown.
  exportLinks:  Array< {
    __typename: "ExportLinksTable",
    // Title
    title: string | null,
    // URL
    url: string | null,
  } | null > | null,
};

export interface ConnectedNetworkkRowFragment {
  __typename: "Network",
  id: number,
  title: string,
  memberUrl: string | null,
  asNumber: number | null,
  peeringPolicy: string | null,
  routeserver: boolean | null,
  vlan: string | null,
  colocation: string | null,
};

export interface DocumentationFragment {
  __typename: "Documentation",
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  contentBlocks:  Array<( {
      __typename: "ContentBlocksIntro",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksText",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksTextWithImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      imageAlignment: ImageAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
      textAlignment: TextAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksGraphic",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksVideo",
      // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
      videoUrl: string | null,
    } | {
      __typename: "ContentBlocksAudio",
      // https://player.rss.com/ix-talks/xxxxxx
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "ContentBlocksTable",
      heading: string | null,
      table:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksCodeSnippet",
      // The language used for syntax highlighting.
      programmingLanguage: ProgrammingLanguageEnum | null,
      snippet: string | null,
    } | {
      __typename: "ContentBlocksContactForm",
      id: string | null,
      // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // If enabled, there will be a option to upload a document in the contact form.
      documentUpload: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksLogos",
      logos:  Array<( {
          __typename: "ImagesVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        } | {
          __typename: "UploadAssetsVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksButton",
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksSectionEnding",
    } | {
      __typename: "ContentBlocksUsers",
      id: string | null,
      heading: string | null,
      users:  Array< {
        __typename: "User",
        fullName: string | null,
        // This will be shown in the author information on the website.
        functionTitle: string | null,
        photo:  {
          __typename: "ImagesVolume",
          url: string | null,
        } | null,
      } | null > | null,
    } | {
      __typename: "ContentBlocksTrafficGraph",
      id: string | null,
      dataset:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
          title: string,
          interval: IntervalEnum | null,
          peakIn: number | null,
          peakOut: number | null,
          averageIn: number | null,
          averageOut: number | null,
          currentIn: number | null,
          currentOut: number | null,
          averageInputData:  Array< {
            __typename: "AverageInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "AverageOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "PeakOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "PeakInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksApplicationForm",
      id: string | null,
      // The text that appears on the button that opens the application form.
      buttonText: string | null,
      applicationType: ApplicationTypeEnum | null,
    } | {
      __typename: "ContentBlocksRegistrationForm",
      id: string | null,
      // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    }
  ) | null > | null,
  // Select items to appear in the "Related" section of the page.
  relatedItems:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Event",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Story",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      service:  Array<( {
          __typename: "ContactRequestPartner",
          title: string,
        } | {
          __typename: "ContactRequestNetwork",
          title: string,
        } | {
          __typename: "ContactRequestInternal",
          title: string,
        } | {
          __typename: "ContactRequestDocument",
          title: string,
        } | {
          __typename: "ContactRequestWishlist",
          title: string,
        } | {
          __typename: "ContactRequestRegistration",
          title: string,
        } | {
          __typename: "City",
          title: string,
        } | {
          __typename: "ConnectedNetworkCollection",
          title: string,
        } | {
          __typename: "Country",
          title: string,
        } | {
          __typename: "Documentation",
          title: string,
        } | {
          __typename: "Event",
          title: string,
        } | {
          __typename: "EventCollection",
          title: string,
        } | {
          __typename: "ExchangePage",
          title: string,
        } | {
          __typename: "FooterLink",
          title: string,
        } | {
          __typename: "GetStarted",
          title: string,
        } | {
          __typename: "Graph",
          title: string,
        } | {
          __typename: "Info",
          title: string,
        } | {
          __typename: "Menu",
          title: string,
        } | {
          __typename: "Network",
          title: string,
        } | {
          __typename: "NetworkConnection",
          title: string,
        } | {
          __typename: "News",
          title: string,
        } | {
          __typename: "NewsCollection",
          title: string,
        } | {
          __typename: "Newsletter",
          title: string,
        } | {
          __typename: "NewsletterCollection",
          title: string,
        } | {
          __typename: "Partner",
          title: string,
        } | {
          __typename: "PartnerCollection",
          title: string,
        } | {
          __typename: "Search",
          title: string,
        } | {
          __typename: "Service",
          title: string,
        } | {
          __typename: "Story",
          title: string,
        } | {
          __typename: "StoryCollection",
          title: string,
        }
      ) | null > | null,
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface EventFragment {
  __typename: "Event",
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  location: string | null,
  // For a one-day event, only fill in the start date.
  startDate: string | null,
  endDate: string | null,
  postDate: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
  contentBlocks:  Array<( {
      __typename: "ContentBlocksIntro",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksText",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksTextWithImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      imageAlignment: ImageAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
      textAlignment: TextAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksGraphic",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksVideo",
      // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
      videoUrl: string | null,
    } | {
      __typename: "ContentBlocksAudio",
      // https://player.rss.com/ix-talks/xxxxxx
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "ContentBlocksTable",
      heading: string | null,
      table:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksCodeSnippet",
      // The language used for syntax highlighting.
      programmingLanguage: ProgrammingLanguageEnum | null,
      snippet: string | null,
    } | {
      __typename: "ContentBlocksContactForm",
      id: string | null,
      // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // If enabled, there will be a option to upload a document in the contact form.
      documentUpload: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksLogos",
      logos:  Array<( {
          __typename: "ImagesVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        } | {
          __typename: "UploadAssetsVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksButton",
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksSectionEnding",
    } | {
      __typename: "ContentBlocksUsers",
      id: string | null,
      heading: string | null,
      users:  Array< {
        __typename: "User",
        fullName: string | null,
        // This will be shown in the author information on the website.
        functionTitle: string | null,
        photo:  {
          __typename: "ImagesVolume",
          url: string | null,
        } | null,
      } | null > | null,
    } | {
      __typename: "ContentBlocksTrafficGraph",
      id: string | null,
      dataset:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
          title: string,
          interval: IntervalEnum | null,
          peakIn: number | null,
          peakOut: number | null,
          averageIn: number | null,
          averageOut: number | null,
          currentIn: number | null,
          currentOut: number | null,
          averageInputData:  Array< {
            __typename: "AverageInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "AverageOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "PeakOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "PeakInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksApplicationForm",
      id: string | null,
      // The text that appears on the button that opens the application form.
      buttonText: string | null,
      applicationType: ApplicationTypeEnum | null,
    } | {
      __typename: "ContentBlocksRegistrationForm",
      id: string | null,
      // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    }
  ) | null > | null,
  // Select items to appear in the "Related" section of the page.
  relatedItems:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Event",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Story",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      service:  Array<( {
          __typename: "ContactRequestPartner",
          title: string,
        } | {
          __typename: "ContactRequestNetwork",
          title: string,
        } | {
          __typename: "ContactRequestInternal",
          title: string,
        } | {
          __typename: "ContactRequestDocument",
          title: string,
        } | {
          __typename: "ContactRequestWishlist",
          title: string,
        } | {
          __typename: "ContactRequestRegistration",
          title: string,
        } | {
          __typename: "City",
          title: string,
        } | {
          __typename: "ConnectedNetworkCollection",
          title: string,
        } | {
          __typename: "Country",
          title: string,
        } | {
          __typename: "Documentation",
          title: string,
        } | {
          __typename: "Event",
          title: string,
        } | {
          __typename: "EventCollection",
          title: string,
        } | {
          __typename: "ExchangePage",
          title: string,
        } | {
          __typename: "FooterLink",
          title: string,
        } | {
          __typename: "GetStarted",
          title: string,
        } | {
          __typename: "Graph",
          title: string,
        } | {
          __typename: "Info",
          title: string,
        } | {
          __typename: "Menu",
          title: string,
        } | {
          __typename: "Network",
          title: string,
        } | {
          __typename: "NetworkConnection",
          title: string,
        } | {
          __typename: "News",
          title: string,
        } | {
          __typename: "NewsCollection",
          title: string,
        } | {
          __typename: "Newsletter",
          title: string,
        } | {
          __typename: "NewsletterCollection",
          title: string,
        } | {
          __typename: "Partner",
          title: string,
        } | {
          __typename: "PartnerCollection",
          title: string,
        } | {
          __typename: "Search",
          title: string,
        } | {
          __typename: "Service",
          title: string,
        } | {
          __typename: "Story",
          title: string,
        } | {
          __typename: "StoryCollection",
          title: string,
        }
      ) | null > | null,
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface SingleEventFragment {
  __typename: "Event",
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  location: string | null,
  // For a one-day event, only fill in the start date.
  startDate: string | null,
  endDate: string | null,
  postDate: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
  contentBlocks:  Array<( {
      __typename: "ContentBlocksIntro",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksText",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksTextWithImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      imageAlignment: ImageAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
      textAlignment: TextAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksGraphic",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksVideo",
      // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
      videoUrl: string | null,
    } | {
      __typename: "ContentBlocksAudio",
      // https://player.rss.com/ix-talks/xxxxxx
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "ContentBlocksTable",
      heading: string | null,
      table:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksCodeSnippet",
      // The language used for syntax highlighting.
      programmingLanguage: ProgrammingLanguageEnum | null,
      snippet: string | null,
    } | {
      __typename: "ContentBlocksContactForm",
      id: string | null,
      // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // If enabled, there will be a option to upload a document in the contact form.
      documentUpload: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksLogos",
      logos:  Array<( {
          __typename: "ImagesVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        } | {
          __typename: "UploadAssetsVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksButton",
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksSectionEnding",
    } | {
      __typename: "ContentBlocksUsers",
      id: string | null,
      heading: string | null,
      users:  Array< {
        __typename: "User",
        fullName: string | null,
        // This will be shown in the author information on the website.
        functionTitle: string | null,
        photo:  {
          __typename: "ImagesVolume",
          url: string | null,
        } | null,
      } | null > | null,
    } | {
      __typename: "ContentBlocksTrafficGraph",
      id: string | null,
      dataset:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
          title: string,
          interval: IntervalEnum | null,
          peakIn: number | null,
          peakOut: number | null,
          averageIn: number | null,
          averageOut: number | null,
          currentIn: number | null,
          currentOut: number | null,
          averageInputData:  Array< {
            __typename: "AverageInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "AverageOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "PeakOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "PeakInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksApplicationForm",
      id: string | null,
      // The text that appears on the button that opens the application form.
      buttonText: string | null,
      applicationType: ApplicationTypeEnum | null,
    } | {
      __typename: "ContentBlocksRegistrationForm",
      id: string | null,
      // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    }
  ) | null > | null,
};

export interface FooterSocialsFragment {
  __typename: "SocialMedia",
  facebookUrl: string | null,
  linkedinUrl: string | null,
  twitterUrl: string | null,
  youtubeUrl: string | null,
};

export interface GetStartedFragment {
  __typename: "GetStarted",
  // The page this item links to. This field is ignored if this is a top-level menu item or category.
  linkEntry:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
      title: string,
      // A short description for display in lists and other overviews.
      description: string | null,
      contentBlocks:  Array<( {
          __typename: "ContentBlocksIntro",
          id: string | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksText",
          id: string | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksTextWithImage",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          heading: string | null,
          textHTML:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
          imageAlignment: ImageAlignmentEnum | null,
        } | {
          __typename: "ContentBlocksImage",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          heading: string | null,
          text: string | null,
          buttonText: string | null,
          buttonLink:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
          textAlignment: TextAlignmentEnum | null,
        } | {
          __typename: "ContentBlocksGraphic",
          image:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              srcOriginal: string | null,
              widthOriginal: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              srcOriginal: string | null,
              widthOriginal: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksVideo",
          // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
          videoUrl: string | null,
        } | {
          __typename: "ContentBlocksAudio",
          // https://player.rss.com/ix-talks/xxxxxx
          podcastUrl: string | null,
          podcastDescription: string | null,
        } | {
          __typename: "ContentBlocksTable",
          heading: string | null,
          table:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksCodeSnippet",
          // The language used for syntax highlighting.
          programmingLanguage: ProgrammingLanguageEnum | null,
          snippet: string | null,
        } | {
          __typename: "ContentBlocksContactForm",
          id: string | null,
          // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
          ctaText: string | null,
          // The text that appears on the button that opens the contact form.
          buttonText: string | null,
          // If enabled, this block will be displayed as a single small button instead of a full sized block.
          compactDisplay: boolean | null,
          // If enabled, there will be a option to upload a document in the contact form.
          documentUpload: boolean | null,
          // This text appears below the title in the contact form overlay.
          description:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        } | {
          __typename: "ContentBlocksLogos",
          logos:  Array<( {
              __typename: "ImagesVolume",
              src: string | null,
              src2x: string | null,
              title: string | null,
              id: number | null,
            } | {
              __typename: "UploadAssetsVolume",
              src: string | null,
              src2x: string | null,
              title: string | null,
              id: number | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksButton",
          id: string | null,
          label: string | null,
          linkEntry:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksSectionEnding",
        } | {
          __typename: "ContentBlocksUsers",
          id: string | null,
          heading: string | null,
          users:  Array< {
            __typename: "User",
            fullName: string | null,
            // This will be shown in the author information on the website.
            functionTitle: string | null,
            photo:  {
              __typename: "ImagesVolume",
              url: string | null,
            } | null,
          } | null > | null,
        } | {
          __typename: "ContentBlocksTrafficGraph",
          id: string | null,
          dataset:  Array<( {
              __typename: "ContactRequestPartner",
            } | {
              __typename: "ContactRequestNetwork",
            } | {
              __typename: "ContactRequestInternal",
            } | {
              __typename: "ContactRequestDocument",
            } | {
              __typename: "ContactRequestWishlist",
            } | {
              __typename: "ContactRequestRegistration",
            } | {
              __typename: "City",
            } | {
              __typename: "ConnectedNetworkCollection",
            } | {
              __typename: "Country",
            } | {
              __typename: "Documentation",
            } | {
              __typename: "Event",
            } | {
              __typename: "EventCollection",
            } | {
              __typename: "ExchangePage",
            } | {
              __typename: "FooterLink",
            } | {
              __typename: "GetStarted",
            } | {
              __typename: "Graph",
              title: string,
              interval: IntervalEnum | null,
              peakIn: number | null,
              peakOut: number | null,
              averageIn: number | null,
              averageOut: number | null,
              currentIn: number | null,
              currentOut: number | null,
              averageInputData:  Array< {
                __typename: "AverageInputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              averageOutputData:  Array< {
                __typename: "AverageOutputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              peakOutputData:  Array< {
                __typename: "PeakOutputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
              peakInputData:  Array< {
                __typename: "PeakInputDataTable",
                // Time
                time: number | null,
                // Value (Mbit/s)
                value: number | null,
              } | null > | null,
            } | {
              __typename: "Info",
            } | {
              __typename: "Menu",
            } | {
              __typename: "Network",
            } | {
              __typename: "NetworkConnection",
            } | {
              __typename: "News",
            } | {
              __typename: "NewsCollection",
            } | {
              __typename: "Newsletter",
            } | {
              __typename: "NewsletterCollection",
            } | {
              __typename: "Partner",
            } | {
              __typename: "PartnerCollection",
            } | {
              __typename: "Search",
            } | {
              __typename: "Service",
            } | {
              __typename: "Story",
            } | {
              __typename: "StoryCollection",
            }
          ) | null > | null,
        } | {
          __typename: "ContentBlocksApplicationForm",
          id: string | null,
          // The text that appears on the button that opens the application form.
          buttonText: string | null,
          applicationType: ApplicationTypeEnum | null,
        } | {
          __typename: "ContentBlocksRegistrationForm",
          id: string | null,
          // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
          ctaText: string | null,
          // The text that appears on the button that opens the contact form.
          buttonText: string | null,
          // If enabled, this block will be displayed as a single small button instead of a full sized block.
          compactDisplay: boolean | null,
          // This text appears below the title in the contact form overlay.
          description:  {
            __typename: "RedactorFieldData",
            content: string | null,
          } | null,
        }
      ) | null > | null,
      // Select items to appear in the "Related" section of the page.
      relatedItems:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Event",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Story",
          id: number,
          uri: string | null,
          title: string,
          postDate: string | null,
          // The main image for this item.
          heroImage:  Array<( {
              __typename: "ImagesVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              srcSmall: string | null,
              widthSmall: string | null,
              srcMedium: string | null,
              widthMedium: string | null,
              srcLarge: string | null,
              widthLarge: string | null,
              title: string | null,
            }
          ) | null > | null,
          service:  Array<( {
              __typename: "ContactRequestPartner",
              title: string,
            } | {
              __typename: "ContactRequestNetwork",
              title: string,
            } | {
              __typename: "ContactRequestInternal",
              title: string,
            } | {
              __typename: "ContactRequestDocument",
              title: string,
            } | {
              __typename: "ContactRequestWishlist",
              title: string,
            } | {
              __typename: "ContactRequestRegistration",
              title: string,
            } | {
              __typename: "City",
              title: string,
            } | {
              __typename: "ConnectedNetworkCollection",
              title: string,
            } | {
              __typename: "Country",
              title: string,
            } | {
              __typename: "Documentation",
              title: string,
            } | {
              __typename: "Event",
              title: string,
            } | {
              __typename: "EventCollection",
              title: string,
            } | {
              __typename: "ExchangePage",
              title: string,
            } | {
              __typename: "FooterLink",
              title: string,
            } | {
              __typename: "GetStarted",
              title: string,
            } | {
              __typename: "Graph",
              title: string,
            } | {
              __typename: "Info",
              title: string,
            } | {
              __typename: "Menu",
              title: string,
            } | {
              __typename: "Network",
              title: string,
            } | {
              __typename: "NetworkConnection",
              title: string,
            } | {
              __typename: "News",
              title: string,
            } | {
              __typename: "NewsCollection",
              title: string,
            } | {
              __typename: "Newsletter",
              title: string,
            } | {
              __typename: "NewsletterCollection",
              title: string,
            } | {
              __typename: "Partner",
              title: string,
            } | {
              __typename: "PartnerCollection",
              title: string,
            } | {
              __typename: "Search",
              title: string,
            } | {
              __typename: "Service",
              title: string,
            } | {
              __typename: "Story",
              title: string,
            } | {
              __typename: "StoryCollection",
              title: string,
            }
          ) | null > | null,
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface GetStartedPageFragment {
  __typename: "Documentation",
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  contentBlocks:  Array<( {
      __typename: "ContentBlocksIntro",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksText",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksTextWithImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      imageAlignment: ImageAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
      textAlignment: TextAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksGraphic",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksVideo",
      // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
      videoUrl: string | null,
    } | {
      __typename: "ContentBlocksAudio",
      // https://player.rss.com/ix-talks/xxxxxx
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "ContentBlocksTable",
      heading: string | null,
      table:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksCodeSnippet",
      // The language used for syntax highlighting.
      programmingLanguage: ProgrammingLanguageEnum | null,
      snippet: string | null,
    } | {
      __typename: "ContentBlocksContactForm",
      id: string | null,
      // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // If enabled, there will be a option to upload a document in the contact form.
      documentUpload: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksLogos",
      logos:  Array<( {
          __typename: "ImagesVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        } | {
          __typename: "UploadAssetsVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksButton",
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksSectionEnding",
    } | {
      __typename: "ContentBlocksUsers",
      id: string | null,
      heading: string | null,
      users:  Array< {
        __typename: "User",
        fullName: string | null,
        // This will be shown in the author information on the website.
        functionTitle: string | null,
        photo:  {
          __typename: "ImagesVolume",
          url: string | null,
        } | null,
      } | null > | null,
    } | {
      __typename: "ContentBlocksTrafficGraph",
      id: string | null,
      dataset:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
          title: string,
          interval: IntervalEnum | null,
          peakIn: number | null,
          peakOut: number | null,
          averageIn: number | null,
          averageOut: number | null,
          currentIn: number | null,
          currentOut: number | null,
          averageInputData:  Array< {
            __typename: "AverageInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "AverageOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "PeakOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "PeakInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksApplicationForm",
      id: string | null,
      // The text that appears on the button that opens the application form.
      buttonText: string | null,
      applicationType: ApplicationTypeEnum | null,
    } | {
      __typename: "ContentBlocksRegistrationForm",
      id: string | null,
      // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    }
  ) | null > | null,
  // Select items to appear in the "Related" section of the page.
  relatedItems:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Event",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Story",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      service:  Array<( {
          __typename: "ContactRequestPartner",
          title: string,
        } | {
          __typename: "ContactRequestNetwork",
          title: string,
        } | {
          __typename: "ContactRequestInternal",
          title: string,
        } | {
          __typename: "ContactRequestDocument",
          title: string,
        } | {
          __typename: "ContactRequestWishlist",
          title: string,
        } | {
          __typename: "ContactRequestRegistration",
          title: string,
        } | {
          __typename: "City",
          title: string,
        } | {
          __typename: "ConnectedNetworkCollection",
          title: string,
        } | {
          __typename: "Country",
          title: string,
        } | {
          __typename: "Documentation",
          title: string,
        } | {
          __typename: "Event",
          title: string,
        } | {
          __typename: "EventCollection",
          title: string,
        } | {
          __typename: "ExchangePage",
          title: string,
        } | {
          __typename: "FooterLink",
          title: string,
        } | {
          __typename: "GetStarted",
          title: string,
        } | {
          __typename: "Graph",
          title: string,
        } | {
          __typename: "Info",
          title: string,
        } | {
          __typename: "Menu",
          title: string,
        } | {
          __typename: "Network",
          title: string,
        } | {
          __typename: "NetworkConnection",
          title: string,
        } | {
          __typename: "News",
          title: string,
        } | {
          __typename: "NewsCollection",
          title: string,
        } | {
          __typename: "Newsletter",
          title: string,
        } | {
          __typename: "NewsletterCollection",
          title: string,
        } | {
          __typename: "Partner",
          title: string,
        } | {
          __typename: "PartnerCollection",
          title: string,
        } | {
          __typename: "Search",
          title: string,
        } | {
          __typename: "Service",
          title: string,
        } | {
          __typename: "Story",
          title: string,
        } | {
          __typename: "StoryCollection",
          title: string,
        }
      ) | null > | null,
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface InfoFragment {
  __typename: "Info",
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  contentBlocks:  Array<( {
      __typename: "ContentBlocksIntro",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksText",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksTextWithImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      imageAlignment: ImageAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
      textAlignment: TextAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksGraphic",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksVideo",
      // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
      videoUrl: string | null,
    } | {
      __typename: "ContentBlocksAudio",
      // https://player.rss.com/ix-talks/xxxxxx
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "ContentBlocksTable",
      heading: string | null,
      table:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksCodeSnippet",
      // The language used for syntax highlighting.
      programmingLanguage: ProgrammingLanguageEnum | null,
      snippet: string | null,
    } | {
      __typename: "ContentBlocksContactForm",
      id: string | null,
      // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // If enabled, there will be a option to upload a document in the contact form.
      documentUpload: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksLogos",
      logos:  Array<( {
          __typename: "ImagesVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        } | {
          __typename: "UploadAssetsVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksButton",
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksSectionEnding",
    } | {
      __typename: "ContentBlocksUsers",
      id: string | null,
      heading: string | null,
      users:  Array< {
        __typename: "User",
        fullName: string | null,
        // This will be shown in the author information on the website.
        functionTitle: string | null,
        photo:  {
          __typename: "ImagesVolume",
          url: string | null,
        } | null,
      } | null > | null,
    } | {
      __typename: "ContentBlocksTrafficGraph",
      id: string | null,
      dataset:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
          title: string,
          interval: IntervalEnum | null,
          peakIn: number | null,
          peakOut: number | null,
          averageIn: number | null,
          averageOut: number | null,
          currentIn: number | null,
          currentOut: number | null,
          averageInputData:  Array< {
            __typename: "AverageInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "AverageOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "PeakOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "PeakInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksApplicationForm",
      id: string | null,
      // The text that appears on the button that opens the application form.
      buttonText: string | null,
      applicationType: ApplicationTypeEnum | null,
    } | {
      __typename: "ContentBlocksRegistrationForm",
      id: string | null,
      // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    }
  ) | null > | null,
  // Select items to appear in the "Related" section of the page.
  relatedItems:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Event",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Story",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      service:  Array<( {
          __typename: "ContactRequestPartner",
          title: string,
        } | {
          __typename: "ContactRequestNetwork",
          title: string,
        } | {
          __typename: "ContactRequestInternal",
          title: string,
        } | {
          __typename: "ContactRequestDocument",
          title: string,
        } | {
          __typename: "ContactRequestWishlist",
          title: string,
        } | {
          __typename: "ContactRequestRegistration",
          title: string,
        } | {
          __typename: "City",
          title: string,
        } | {
          __typename: "ConnectedNetworkCollection",
          title: string,
        } | {
          __typename: "Country",
          title: string,
        } | {
          __typename: "Documentation",
          title: string,
        } | {
          __typename: "Event",
          title: string,
        } | {
          __typename: "EventCollection",
          title: string,
        } | {
          __typename: "ExchangePage",
          title: string,
        } | {
          __typename: "FooterLink",
          title: string,
        } | {
          __typename: "GetStarted",
          title: string,
        } | {
          __typename: "Graph",
          title: string,
        } | {
          __typename: "Info",
          title: string,
        } | {
          __typename: "Menu",
          title: string,
        } | {
          __typename: "Network",
          title: string,
        } | {
          __typename: "NetworkConnection",
          title: string,
        } | {
          __typename: "News",
          title: string,
        } | {
          __typename: "NewsCollection",
          title: string,
        } | {
          __typename: "Newsletter",
          title: string,
        } | {
          __typename: "NewsletterCollection",
          title: string,
        } | {
          __typename: "Partner",
          title: string,
        } | {
          __typename: "PartnerCollection",
          title: string,
        } | {
          __typename: "Search",
          title: string,
        } | {
          __typename: "Service",
          title: string,
        } | {
          __typename: "Story",
          title: string,
        } | {
          __typename: "StoryCollection",
          title: string,
        }
      ) | null > | null,
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface SingleNewsPageFragment {
  __typename: "News",
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  postDate: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    }
  ) | null > | null,
  author:  {
    __typename: "User",
    name: string,
    // This will be shown in the author information on the website.
    functionTitle: string | null,
    photo:  {
      __typename: "ImagesVolume",
      url: string | null,
      title: string | null,
    } | null,
  },
  contentBlocks:  Array<( {
      __typename: "ContentBlocksIntro",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksText",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksTextWithImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      imageAlignment: ImageAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
      textAlignment: TextAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksGraphic",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksVideo",
      // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
      videoUrl: string | null,
    } | {
      __typename: "ContentBlocksAudio",
      // https://player.rss.com/ix-talks/xxxxxx
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "ContentBlocksTable",
      heading: string | null,
      table:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksCodeSnippet",
      // The language used for syntax highlighting.
      programmingLanguage: ProgrammingLanguageEnum | null,
      snippet: string | null,
    } | {
      __typename: "ContentBlocksContactForm",
      id: string | null,
      // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // If enabled, there will be a option to upload a document in the contact form.
      documentUpload: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksLogos",
      logos:  Array<( {
          __typename: "ImagesVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        } | {
          __typename: "UploadAssetsVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksButton",
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksSectionEnding",
    } | {
      __typename: "ContentBlocksUsers",
      id: string | null,
      heading: string | null,
      users:  Array< {
        __typename: "User",
        fullName: string | null,
        // This will be shown in the author information on the website.
        functionTitle: string | null,
        photo:  {
          __typename: "ImagesVolume",
          url: string | null,
        } | null,
      } | null > | null,
    } | {
      __typename: "ContentBlocksTrafficGraph",
      id: string | null,
      dataset:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
          title: string,
          interval: IntervalEnum | null,
          peakIn: number | null,
          peakOut: number | null,
          averageIn: number | null,
          averageOut: number | null,
          currentIn: number | null,
          currentOut: number | null,
          averageInputData:  Array< {
            __typename: "AverageInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "AverageOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "PeakOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "PeakInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksApplicationForm",
      id: string | null,
      // The text that appears on the button that opens the application form.
      buttonText: string | null,
      applicationType: ApplicationTypeEnum | null,
    } | {
      __typename: "ContentBlocksRegistrationForm",
      id: string | null,
      // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    }
  ) | null > | null,
  // Select items to appear in the "Related" section of the page.
  relatedItems:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Event",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Story",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      service:  Array<( {
          __typename: "ContactRequestPartner",
          title: string,
        } | {
          __typename: "ContactRequestNetwork",
          title: string,
        } | {
          __typename: "ContactRequestInternal",
          title: string,
        } | {
          __typename: "ContactRequestDocument",
          title: string,
        } | {
          __typename: "ContactRequestWishlist",
          title: string,
        } | {
          __typename: "ContactRequestRegistration",
          title: string,
        } | {
          __typename: "City",
          title: string,
        } | {
          __typename: "ConnectedNetworkCollection",
          title: string,
        } | {
          __typename: "Country",
          title: string,
        } | {
          __typename: "Documentation",
          title: string,
        } | {
          __typename: "Event",
          title: string,
        } | {
          __typename: "EventCollection",
          title: string,
        } | {
          __typename: "ExchangePage",
          title: string,
        } | {
          __typename: "FooterLink",
          title: string,
        } | {
          __typename: "GetStarted",
          title: string,
        } | {
          __typename: "Graph",
          title: string,
        } | {
          __typename: "Info",
          title: string,
        } | {
          __typename: "Menu",
          title: string,
        } | {
          __typename: "Network",
          title: string,
        } | {
          __typename: "NetworkConnection",
          title: string,
        } | {
          __typename: "News",
          title: string,
        } | {
          __typename: "NewsCollection",
          title: string,
        } | {
          __typename: "Newsletter",
          title: string,
        } | {
          __typename: "NewsletterCollection",
          title: string,
        } | {
          __typename: "Partner",
          title: string,
        } | {
          __typename: "PartnerCollection",
          title: string,
        } | {
          __typename: "Search",
          title: string,
        } | {
          __typename: "Service",
          title: string,
        } | {
          __typename: "Story",
          title: string,
        } | {
          __typename: "StoryCollection",
          title: string,
        }
      ) | null > | null,
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface SingleNewsFragment {
  __typename: "News",
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  postDate: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    }
  ) | null > | null,
  author:  {
    __typename: "User",
    name: string,
    // This will be shown in the author information on the website.
    functionTitle: string | null,
    photo:  {
      __typename: "ImagesVolume",
      url: string | null,
      title: string | null,
    } | null,
  },
  contentBlocks:  Array<( {
      __typename: "ContentBlocksIntro",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksText",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksTextWithImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      imageAlignment: ImageAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
      textAlignment: TextAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksGraphic",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksVideo",
      // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
      videoUrl: string | null,
    } | {
      __typename: "ContentBlocksAudio",
      // https://player.rss.com/ix-talks/xxxxxx
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "ContentBlocksTable",
      heading: string | null,
      table:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksCodeSnippet",
      // The language used for syntax highlighting.
      programmingLanguage: ProgrammingLanguageEnum | null,
      snippet: string | null,
    } | {
      __typename: "ContentBlocksContactForm",
      id: string | null,
      // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // If enabled, there will be a option to upload a document in the contact form.
      documentUpload: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksLogos",
      logos:  Array<( {
          __typename: "ImagesVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        } | {
          __typename: "UploadAssetsVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksButton",
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksSectionEnding",
    } | {
      __typename: "ContentBlocksUsers",
      id: string | null,
      heading: string | null,
      users:  Array< {
        __typename: "User",
        fullName: string | null,
        // This will be shown in the author information on the website.
        functionTitle: string | null,
        photo:  {
          __typename: "ImagesVolume",
          url: string | null,
        } | null,
      } | null > | null,
    } | {
      __typename: "ContentBlocksTrafficGraph",
      id: string | null,
      dataset:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
          title: string,
          interval: IntervalEnum | null,
          peakIn: number | null,
          peakOut: number | null,
          averageIn: number | null,
          averageOut: number | null,
          currentIn: number | null,
          currentOut: number | null,
          averageInputData:  Array< {
            __typename: "AverageInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "AverageOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "PeakOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "PeakInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksApplicationForm",
      id: string | null,
      // The text that appears on the button that opens the application form.
      buttonText: string | null,
      applicationType: ApplicationTypeEnum | null,
    } | {
      __typename: "ContentBlocksRegistrationForm",
      id: string | null,
      // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    }
  ) | null > | null,
};

export interface PartnerInfoFragment {
  __typename: "PartnerCollection",
  title: string,
  introHeading: string | null,
  introText:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
};

export interface PartnerTableRowFragment {
  __typename: "Partner",
  id: number,
  title: string,
  datacenter: string | null,
  city: string | null,
  country: string | null,
  partnerType: PartnerTypeEnum | null,
};

export interface CountriesFragment {
  __typename: "Country",
  title: string,
};

export interface CitiesFragment {
  __typename: "City",
  title: string,
};

export interface SearchPageFragment {
  __typename: "Search",
  introHeading: string | null,
  introText:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
};

export interface ServicePageFragment {
  __typename: "Service",
  id: number,
  title: string,
  // Text that appears in the "How it works" section on the service page.
  howItWorks:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
  // A short description for display in lists and other overviews.
  description: string | null,
  // Text and/or tables to appear in the "Pricing" section of the Service page.
  pricing:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
  // Enable this when the user should be able to select between durations.
  showDurationSwitch: boolean | null,
  commitmentModule:  Array<( {
      __typename: "CommitmentModuleSpeedBlock",
      blockTitle: string | null,
      price3Months: string | null,
      price12Months: string | null,
      price24Months: string | null,
      price36Months: string | null,
      checkmarkList:  Array< {
        __typename: "CheckmarkListTable",
        // List item
        listItem: string | null,
      } | null > | null,
    }
  ) | null > | null,
  // The main service icon, shown in the Services menu and on the Service page.
  icon:  Array<( {
      __typename: "ImagesVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    }
  ) | null > | null,
  // The benefits that appear in the "Benefits" section of the Service page.
  benefits:  Array<( {
      __typename: "BenefitsBenefit",
      icon:  Array<( {
          __typename: "ImagesVolume",
          url: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          url: string | null,
        }
      ) | null > | null,
      heading: string | null,
      // Short explanation shown below the title.
      description: string | null,
    }
  ) | null > | null,
  // This story will be showcased on the service page. Leave empty to omit the showcase.
  storyShowcase:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
      title: string,
      uri: string | null,
      // A short description for display in lists and other overviews.
      description: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
  // This text appears in the content block above the "Contact us" button.
  contactBlockCtaText:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
  // This text appears in the contact block that can be opened from this page.
  contactFormText:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
  // Select items to appear in the "Related" section of the page.
  relatedItems:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Event",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Story",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      service:  Array<( {
          __typename: "ContactRequestPartner",
          title: string,
        } | {
          __typename: "ContactRequestNetwork",
          title: string,
        } | {
          __typename: "ContactRequestInternal",
          title: string,
        } | {
          __typename: "ContactRequestDocument",
          title: string,
        } | {
          __typename: "ContactRequestWishlist",
          title: string,
        } | {
          __typename: "ContactRequestRegistration",
          title: string,
        } | {
          __typename: "City",
          title: string,
        } | {
          __typename: "ConnectedNetworkCollection",
          title: string,
        } | {
          __typename: "Country",
          title: string,
        } | {
          __typename: "Documentation",
          title: string,
        } | {
          __typename: "Event",
          title: string,
        } | {
          __typename: "EventCollection",
          title: string,
        } | {
          __typename: "ExchangePage",
          title: string,
        } | {
          __typename: "FooterLink",
          title: string,
        } | {
          __typename: "GetStarted",
          title: string,
        } | {
          __typename: "Graph",
          title: string,
        } | {
          __typename: "Info",
          title: string,
        } | {
          __typename: "Menu",
          title: string,
        } | {
          __typename: "Network",
          title: string,
        } | {
          __typename: "NetworkConnection",
          title: string,
        } | {
          __typename: "News",
          title: string,
        } | {
          __typename: "NewsCollection",
          title: string,
        } | {
          __typename: "Newsletter",
          title: string,
        } | {
          __typename: "NewsletterCollection",
          title: string,
        } | {
          __typename: "Partner",
          title: string,
        } | {
          __typename: "PartnerCollection",
          title: string,
        } | {
          __typename: "Search",
          title: string,
        } | {
          __typename: "Service",
          title: string,
        } | {
          __typename: "Story",
          title: string,
        } | {
          __typename: "StoryCollection",
          title: string,
        }
      ) | null > | null,
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface StoryFragment {
  __typename: "Story",
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  postDate: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
  author:  {
    __typename: "User",
    name: string,
    // This will be shown in the author information on the website.
    functionTitle: string | null,
    photo:  {
      __typename: "ImagesVolume",
      url: string | null,
      title: string | null,
    } | null,
  },
  service:  Array<( {
      __typename: "ContactRequestPartner",
      title: string,
    } | {
      __typename: "ContactRequestNetwork",
      title: string,
    } | {
      __typename: "ContactRequestInternal",
      title: string,
    } | {
      __typename: "ContactRequestDocument",
      title: string,
    } | {
      __typename: "ContactRequestWishlist",
      title: string,
    } | {
      __typename: "ContactRequestRegistration",
      title: string,
    } | {
      __typename: "City",
      title: string,
    } | {
      __typename: "ConnectedNetworkCollection",
      title: string,
    } | {
      __typename: "Country",
      title: string,
    } | {
      __typename: "Documentation",
      title: string,
    } | {
      __typename: "Event",
      title: string,
    } | {
      __typename: "EventCollection",
      title: string,
    } | {
      __typename: "ExchangePage",
      title: string,
    } | {
      __typename: "FooterLink",
      title: string,
    } | {
      __typename: "GetStarted",
      title: string,
    } | {
      __typename: "Graph",
      title: string,
    } | {
      __typename: "Info",
      title: string,
    } | {
      __typename: "Menu",
      title: string,
    } | {
      __typename: "Network",
      title: string,
    } | {
      __typename: "NetworkConnection",
      title: string,
    } | {
      __typename: "News",
      title: string,
    } | {
      __typename: "NewsCollection",
      title: string,
    } | {
      __typename: "Newsletter",
      title: string,
    } | {
      __typename: "NewsletterCollection",
      title: string,
    } | {
      __typename: "Partner",
      title: string,
    } | {
      __typename: "PartnerCollection",
      title: string,
    } | {
      __typename: "Search",
      title: string,
    } | {
      __typename: "Service",
      title: string,
    } | {
      __typename: "Story",
      title: string,
    } | {
      __typename: "StoryCollection",
      title: string,
    }
  ) | null > | null,
  contentBlocks:  Array<( {
      __typename: "ContentBlocksIntro",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksText",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksTextWithImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      imageAlignment: ImageAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
      textAlignment: TextAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksGraphic",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksVideo",
      // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
      videoUrl: string | null,
    } | {
      __typename: "ContentBlocksAudio",
      // https://player.rss.com/ix-talks/xxxxxx
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "ContentBlocksTable",
      heading: string | null,
      table:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksCodeSnippet",
      // The language used for syntax highlighting.
      programmingLanguage: ProgrammingLanguageEnum | null,
      snippet: string | null,
    } | {
      __typename: "ContentBlocksContactForm",
      id: string | null,
      // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // If enabled, there will be a option to upload a document in the contact form.
      documentUpload: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksLogos",
      logos:  Array<( {
          __typename: "ImagesVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        } | {
          __typename: "UploadAssetsVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksButton",
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksSectionEnding",
    } | {
      __typename: "ContentBlocksUsers",
      id: string | null,
      heading: string | null,
      users:  Array< {
        __typename: "User",
        fullName: string | null,
        // This will be shown in the author information on the website.
        functionTitle: string | null,
        photo:  {
          __typename: "ImagesVolume",
          url: string | null,
        } | null,
      } | null > | null,
    } | {
      __typename: "ContentBlocksTrafficGraph",
      id: string | null,
      dataset:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
          title: string,
          interval: IntervalEnum | null,
          peakIn: number | null,
          peakOut: number | null,
          averageIn: number | null,
          averageOut: number | null,
          currentIn: number | null,
          currentOut: number | null,
          averageInputData:  Array< {
            __typename: "AverageInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "AverageOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "PeakOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "PeakInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksApplicationForm",
      id: string | null,
      // The text that appears on the button that opens the application form.
      buttonText: string | null,
      applicationType: ApplicationTypeEnum | null,
    } | {
      __typename: "ContentBlocksRegistrationForm",
      id: string | null,
      // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    }
  ) | null > | null,
  // Select items to appear in the "Related" section of the page.
  relatedItems:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Event",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Story",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      service:  Array<( {
          __typename: "ContactRequestPartner",
          title: string,
        } | {
          __typename: "ContactRequestNetwork",
          title: string,
        } | {
          __typename: "ContactRequestInternal",
          title: string,
        } | {
          __typename: "ContactRequestDocument",
          title: string,
        } | {
          __typename: "ContactRequestWishlist",
          title: string,
        } | {
          __typename: "ContactRequestRegistration",
          title: string,
        } | {
          __typename: "City",
          title: string,
        } | {
          __typename: "ConnectedNetworkCollection",
          title: string,
        } | {
          __typename: "Country",
          title: string,
        } | {
          __typename: "Documentation",
          title: string,
        } | {
          __typename: "Event",
          title: string,
        } | {
          __typename: "EventCollection",
          title: string,
        } | {
          __typename: "ExchangePage",
          title: string,
        } | {
          __typename: "FooterLink",
          title: string,
        } | {
          __typename: "GetStarted",
          title: string,
        } | {
          __typename: "Graph",
          title: string,
        } | {
          __typename: "Info",
          title: string,
        } | {
          __typename: "Menu",
          title: string,
        } | {
          __typename: "Network",
          title: string,
        } | {
          __typename: "NetworkConnection",
          title: string,
        } | {
          __typename: "News",
          title: string,
        } | {
          __typename: "NewsCollection",
          title: string,
        } | {
          __typename: "Newsletter",
          title: string,
        } | {
          __typename: "NewsletterCollection",
          title: string,
        } | {
          __typename: "Partner",
          title: string,
        } | {
          __typename: "PartnerCollection",
          title: string,
        } | {
          __typename: "Search",
          title: string,
        } | {
          __typename: "Service",
          title: string,
        } | {
          __typename: "Story",
          title: string,
        } | {
          __typename: "StoryCollection",
          title: string,
        }
      ) | null > | null,
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface SingleStoryFragment {
  __typename: "Story",
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  postDate: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
  author:  {
    __typename: "User",
    name: string,
    // This will be shown in the author information on the website.
    functionTitle: string | null,
    photo:  {
      __typename: "ImagesVolume",
      url: string | null,
      title: string | null,
    } | null,
  },
  service:  Array<( {
      __typename: "ContactRequestPartner",
      title: string,
    } | {
      __typename: "ContactRequestNetwork",
      title: string,
    } | {
      __typename: "ContactRequestInternal",
      title: string,
    } | {
      __typename: "ContactRequestDocument",
      title: string,
    } | {
      __typename: "ContactRequestWishlist",
      title: string,
    } | {
      __typename: "ContactRequestRegistration",
      title: string,
    } | {
      __typename: "City",
      title: string,
    } | {
      __typename: "ConnectedNetworkCollection",
      title: string,
    } | {
      __typename: "Country",
      title: string,
    } | {
      __typename: "Documentation",
      title: string,
    } | {
      __typename: "Event",
      title: string,
    } | {
      __typename: "EventCollection",
      title: string,
    } | {
      __typename: "ExchangePage",
      title: string,
    } | {
      __typename: "FooterLink",
      title: string,
    } | {
      __typename: "GetStarted",
      title: string,
    } | {
      __typename: "Graph",
      title: string,
    } | {
      __typename: "Info",
      title: string,
    } | {
      __typename: "Menu",
      title: string,
    } | {
      __typename: "Network",
      title: string,
    } | {
      __typename: "NetworkConnection",
      title: string,
    } | {
      __typename: "News",
      title: string,
    } | {
      __typename: "NewsCollection",
      title: string,
    } | {
      __typename: "Newsletter",
      title: string,
    } | {
      __typename: "NewsletterCollection",
      title: string,
    } | {
      __typename: "Partner",
      title: string,
    } | {
      __typename: "PartnerCollection",
      title: string,
    } | {
      __typename: "Search",
      title: string,
    } | {
      __typename: "Service",
      title: string,
    } | {
      __typename: "Story",
      title: string,
    } | {
      __typename: "StoryCollection",
      title: string,
    }
  ) | null > | null,
  contentBlocks:  Array<( {
      __typename: "ContentBlocksIntro",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksText",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksTextWithImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      imageAlignment: ImageAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
      textAlignment: TextAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksGraphic",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksVideo",
      // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
      videoUrl: string | null,
    } | {
      __typename: "ContentBlocksAudio",
      // https://player.rss.com/ix-talks/xxxxxx
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "ContentBlocksTable",
      heading: string | null,
      table:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksCodeSnippet",
      // The language used for syntax highlighting.
      programmingLanguage: ProgrammingLanguageEnum | null,
      snippet: string | null,
    } | {
      __typename: "ContentBlocksContactForm",
      id: string | null,
      // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // If enabled, there will be a option to upload a document in the contact form.
      documentUpload: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksLogos",
      logos:  Array<( {
          __typename: "ImagesVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        } | {
          __typename: "UploadAssetsVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksButton",
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksSectionEnding",
    } | {
      __typename: "ContentBlocksUsers",
      id: string | null,
      heading: string | null,
      users:  Array< {
        __typename: "User",
        fullName: string | null,
        // This will be shown in the author information on the website.
        functionTitle: string | null,
        photo:  {
          __typename: "ImagesVolume",
          url: string | null,
        } | null,
      } | null > | null,
    } | {
      __typename: "ContentBlocksTrafficGraph",
      id: string | null,
      dataset:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
          title: string,
          interval: IntervalEnum | null,
          peakIn: number | null,
          peakOut: number | null,
          averageIn: number | null,
          averageOut: number | null,
          currentIn: number | null,
          currentOut: number | null,
          averageInputData:  Array< {
            __typename: "AverageInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "AverageOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "PeakOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "PeakInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksApplicationForm",
      id: string | null,
      // The text that appears on the button that opens the application form.
      buttonText: string | null,
      applicationType: ApplicationTypeEnum | null,
    } | {
      __typename: "ContentBlocksRegistrationForm",
      id: string | null,
      // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    }
  ) | null > | null,
};

export interface ApplicationFormContentBlockFragment {
  __typename: "ContentBlocksApplicationForm",
  id: string | null,
  // The text that appears on the button that opens the application form.
  buttonText: string | null,
  applicationType: ApplicationTypeEnum | null,
};

export interface AudioContentBlockFragment {
  __typename: "ContentBlocksAudio",
  // https://player.rss.com/ix-talks/xxxxxx
  podcastUrl: string | null,
  podcastDescription: string | null,
};

export interface ButtonContentBlockFragment {
  __typename: "ContentBlocksButton",
  id: string | null,
  label: string | null,
  linkEntry:  Array<( {
      __typename: "ContactRequestPartner",
      uri: string | null,
    } | {
      __typename: "ContactRequestNetwork",
      uri: string | null,
    } | {
      __typename: "ContactRequestInternal",
      uri: string | null,
    } | {
      __typename: "ContactRequestDocument",
      uri: string | null,
    } | {
      __typename: "ContactRequestWishlist",
      uri: string | null,
    } | {
      __typename: "ContactRequestRegistration",
      uri: string | null,
    } | {
      __typename: "City",
      uri: string | null,
    } | {
      __typename: "ConnectedNetworkCollection",
      uri: string | null,
    } | {
      __typename: "Country",
      uri: string | null,
    } | {
      __typename: "Documentation",
      uri: string | null,
    } | {
      __typename: "Event",
      uri: string | null,
    } | {
      __typename: "EventCollection",
      uri: string | null,
    } | {
      __typename: "ExchangePage",
      uri: string | null,
    } | {
      __typename: "FooterLink",
      uri: string | null,
    } | {
      __typename: "GetStarted",
      uri: string | null,
    } | {
      __typename: "Graph",
      uri: string | null,
    } | {
      __typename: "Info",
      uri: string | null,
    } | {
      __typename: "Menu",
      uri: string | null,
    } | {
      __typename: "Network",
      uri: string | null,
    } | {
      __typename: "NetworkConnection",
      uri: string | null,
    } | {
      __typename: "News",
      uri: string | null,
    } | {
      __typename: "NewsCollection",
      uri: string | null,
    } | {
      __typename: "Newsletter",
      uri: string | null,
    } | {
      __typename: "NewsletterCollection",
      uri: string | null,
    } | {
      __typename: "Partner",
      uri: string | null,
    } | {
      __typename: "PartnerCollection",
      uri: string | null,
    } | {
      __typename: "Search",
      uri: string | null,
    } | {
      __typename: "Service",
      uri: string | null,
    } | {
      __typename: "Story",
      uri: string | null,
    } | {
      __typename: "StoryCollection",
      uri: string | null,
    }
  ) | null > | null,
};

export interface CodeContentBlockFragment {
  __typename: "ContentBlocksCodeSnippet",
  // The language used for syntax highlighting.
  programmingLanguage: ProgrammingLanguageEnum | null,
  snippet: string | null,
};

export interface ContactFormContentBlockFragment {
  __typename: "ContentBlocksContactForm",
  id: string | null,
  // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
  ctaText: string | null,
  // The text that appears on the button that opens the contact form.
  buttonText: string | null,
  // If enabled, this block will be displayed as a single small button instead of a full sized block.
  compactDisplay: boolean | null,
  // If enabled, there will be a option to upload a document in the contact form.
  documentUpload: boolean | null,
  // This text appears below the title in the contact form overlay.
  description:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
};

export interface ContactInfoSocialsFragment {
  __typename: "SocialMedia",
  facebookUrl: string | null,
  linkedinUrl: string | null,
  twitterUrl: string | null,
  youtubeUrl: string | null,
};

export interface GraphicContentBlockFragment {
  __typename: "ContentBlocksGraphic",
  image:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      srcOriginal: string | null,
      widthOriginal: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      srcOriginal: string | null,
      widthOriginal: string | null,
      title: string | null,
    }
  ) | null > | null,
};

export interface MenuStatsFragment {
  __typename: "Graph",
  averageInputData:  Array< {
    __typename: "AverageInputDataTable",
    // Time
    time: number | null,
    // Value (Mbit/s)
    value: number | null,
  } | null > | null,
};

export interface ImageContentBlockFragment {
  __typename: "ContentBlocksImage",
  image:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
  heading: string | null,
  text: string | null,
  buttonText: string | null,
  buttonLink:  Array<( {
      __typename: "ContactRequestPartner",
      uri: string | null,
    } | {
      __typename: "ContactRequestNetwork",
      uri: string | null,
    } | {
      __typename: "ContactRequestInternal",
      uri: string | null,
    } | {
      __typename: "ContactRequestDocument",
      uri: string | null,
    } | {
      __typename: "ContactRequestWishlist",
      uri: string | null,
    } | {
      __typename: "ContactRequestRegistration",
      uri: string | null,
    } | {
      __typename: "City",
      uri: string | null,
    } | {
      __typename: "ConnectedNetworkCollection",
      uri: string | null,
    } | {
      __typename: "Country",
      uri: string | null,
    } | {
      __typename: "Documentation",
      uri: string | null,
    } | {
      __typename: "Event",
      uri: string | null,
    } | {
      __typename: "EventCollection",
      uri: string | null,
    } | {
      __typename: "ExchangePage",
      uri: string | null,
    } | {
      __typename: "FooterLink",
      uri: string | null,
    } | {
      __typename: "GetStarted",
      uri: string | null,
    } | {
      __typename: "Graph",
      uri: string | null,
    } | {
      __typename: "Info",
      uri: string | null,
    } | {
      __typename: "Menu",
      uri: string | null,
    } | {
      __typename: "Network",
      uri: string | null,
    } | {
      __typename: "NetworkConnection",
      uri: string | null,
    } | {
      __typename: "News",
      uri: string | null,
    } | {
      __typename: "NewsCollection",
      uri: string | null,
    } | {
      __typename: "Newsletter",
      uri: string | null,
    } | {
      __typename: "NewsletterCollection",
      uri: string | null,
    } | {
      __typename: "Partner",
      uri: string | null,
    } | {
      __typename: "PartnerCollection",
      uri: string | null,
    } | {
      __typename: "Search",
      uri: string | null,
    } | {
      __typename: "Service",
      uri: string | null,
    } | {
      __typename: "Story",
      uri: string | null,
    } | {
      __typename: "StoryCollection",
      uri: string | null,
    }
  ) | null > | null,
  textAlignment: TextAlignmentEnum | null,
};

export interface IntroContentBlockFragment {
  __typename: "ContentBlocksIntro",
  id: string | null,
  heading: string | null,
  textHTML:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
};

export interface LogosContentBlockFragment {
  __typename: "ContentBlocksLogos",
  logos:  Array<( {
      __typename: "ImagesVolume",
      src: string | null,
      src2x: string | null,
      title: string | null,
      id: number | null,
    } | {
      __typename: "UploadAssetsVolume",
      src: string | null,
      src2x: string | null,
      title: string | null,
      id: number | null,
    }
  ) | null > | null,
};

export interface RegistrationFormContentBlockFragment {
  __typename: "ContentBlocksRegistrationForm",
  id: string | null,
  // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
  ctaText: string | null,
  // The text that appears on the button that opens the contact form.
  buttonText: string | null,
  // If enabled, this block will be displayed as a single small button instead of a full sized block.
  compactDisplay: boolean | null,
  // This text appears below the title in the contact form overlay.
  description:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
};

export interface ServicePageBenefitFragment {
  __typename: "BenefitsBenefit",
  icon:  Array<( {
      __typename: "ImagesVolume",
      url: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      url: string | null,
    }
  ) | null > | null,
  heading: string | null,
  // Short explanation shown below the title.
  description: string | null,
};

export interface ShowcaseStoryFragment {
  __typename: "Story",
  title: string,
  uri: string | null,
  // A short description for display in lists and other overviews.
  description: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
};

export interface TableContentBlockFragment {
  __typename: "ContentBlocksTable",
  heading: string | null,
  table:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
};

export interface TextContentBlockFragment {
  __typename: "ContentBlocksText",
  id: string | null,
  heading: string | null,
  textHTML:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
};

export interface TextWithImageContentBlockFragment {
  __typename: "ContentBlocksTextWithImage",
  image:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
  heading: string | null,
  textHTML:  {
    __typename: "RedactorFieldData",
    content: string | null,
  } | null,
  imageAlignment: ImageAlignmentEnum | null,
};

export interface TrafficGraphContentBlockFragment {
  __typename: "ContentBlocksTrafficGraph",
  id: string | null,
  dataset:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
      title: string,
      interval: IntervalEnum | null,
      peakIn: number | null,
      peakOut: number | null,
      averageIn: number | null,
      averageOut: number | null,
      currentIn: number | null,
      currentOut: number | null,
      averageInputData:  Array< {
        __typename: "AverageInputDataTable",
        // Time
        time: number | null,
        // Value (Mbit/s)
        value: number | null,
      } | null > | null,
      averageOutputData:  Array< {
        __typename: "AverageOutputDataTable",
        // Time
        time: number | null,
        // Value (Mbit/s)
        value: number | null,
      } | null > | null,
      peakOutputData:  Array< {
        __typename: "PeakOutputDataTable",
        // Time
        time: number | null,
        // Value (Mbit/s)
        value: number | null,
      } | null > | null,
      peakInputData:  Array< {
        __typename: "PeakInputDataTable",
        // Time
        time: number | null,
        // Value (Mbit/s)
        value: number | null,
      } | null > | null,
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface DropdownStatsCompleteFragment {
  __typename: "Graph",
  title: string,
  interval: IntervalEnum | null,
  peakIn: number | null,
  peakOut: number | null,
  averageIn: number | null,
  averageOut: number | null,
  currentIn: number | null,
  currentOut: number | null,
  averageInputData:  Array< {
    __typename: "AverageInputDataTable",
    // Time
    time: number | null,
    // Value (Mbit/s)
    value: number | null,
  } | null > | null,
  averageOutputData:  Array< {
    __typename: "AverageOutputDataTable",
    // Time
    time: number | null,
    // Value (Mbit/s)
    value: number | null,
  } | null > | null,
  peakOutputData:  Array< {
    __typename: "PeakOutputDataTable",
    // Time
    time: number | null,
    // Value (Mbit/s)
    value: number | null,
  } | null > | null,
  peakInputData:  Array< {
    __typename: "PeakInputDataTable",
    // Time
    time: number | null,
    // Value (Mbit/s)
    value: number | null,
  } | null > | null,
};

export interface UsersContentBlockFragment {
  __typename: "ContentBlocksUsers",
  id: string | null,
  heading: string | null,
  users:  Array< {
    __typename: "User",
    fullName: string | null,
    // This will be shown in the author information on the website.
    functionTitle: string | null,
    photo:  {
      __typename: "ImagesVolume",
      url: string | null,
    } | null,
  } | null > | null,
};

export interface VideoContentBlockFragment {
  __typename: "ContentBlocksVideo",
  // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
  videoUrl: string | null,
};

export interface DropdownStatsFragment {
  __typename: "Graph",
  title: string,
  interval: IntervalEnum | null,
  peakIn: number | null,
  peakOut: number | null,
  averageIn: number | null,
  averageOut: number | null,
  currentIn: number | null,
  currentOut: number | null,
  averageInputData:  Array< {
    __typename: "AverageInputDataTable",
    // Time
    time: number | null,
    // Value (Mbit/s)
    value: number | null,
  } | null > | null,
};

export interface WhereToConnectLinkFragment {
  __typename: "Menu",
  // The page this item links to. This field is ignored if this is a top-level menu item or category.
  linkEntry:  Array<( {
      __typename: "ContactRequestPartner",
      uri: string | null,
    } | {
      __typename: "ContactRequestNetwork",
      uri: string | null,
    } | {
      __typename: "ContactRequestInternal",
      uri: string | null,
    } | {
      __typename: "ContactRequestDocument",
      uri: string | null,
    } | {
      __typename: "ContactRequestWishlist",
      uri: string | null,
    } | {
      __typename: "ContactRequestRegistration",
      uri: string | null,
    } | {
      __typename: "City",
      uri: string | null,
    } | {
      __typename: "ConnectedNetworkCollection",
      uri: string | null,
    } | {
      __typename: "Country",
      uri: string | null,
    } | {
      __typename: "Documentation",
      uri: string | null,
    } | {
      __typename: "Event",
      uri: string | null,
    } | {
      __typename: "EventCollection",
      uri: string | null,
    } | {
      __typename: "ExchangePage",
      uri: string | null,
    } | {
      __typename: "FooterLink",
      uri: string | null,
    } | {
      __typename: "GetStarted",
      uri: string | null,
    } | {
      __typename: "Graph",
      uri: string | null,
    } | {
      __typename: "Info",
      uri: string | null,
    } | {
      __typename: "Menu",
      uri: string | null,
    } | {
      __typename: "Network",
      uri: string | null,
    } | {
      __typename: "NetworkConnection",
      uri: string | null,
    } | {
      __typename: "News",
      uri: string | null,
    } | {
      __typename: "NewsCollection",
      uri: string | null,
    } | {
      __typename: "Newsletter",
      uri: string | null,
    } | {
      __typename: "NewsletterCollection",
      uri: string | null,
    } | {
      __typename: "Partner",
      uri: string | null,
    } | {
      __typename: "PartnerCollection",
      uri: string | null,
    } | {
      __typename: "Search",
      uri: string | null,
    } | {
      __typename: "Service",
      uri: string | null,
    } | {
      __typename: "Story",
      uri: string | null,
    } | {
      __typename: "StoryCollection",
      uri: string | null,
    }
  ) | null > | null,
};

export interface StatsFragment {
  __typename: "ExchangePage",
  // The text that appears in the orange block in the "... in numbers" section.
  numbersCtaText: string | null,
  // Hides the block containing the colocation count from the "... in numbers" section.
  hideColocations: boolean | null,
};

export interface ConnectedNetworkDetailsFragment {
  __typename: "Network",
  connections:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
      IPv4: string | null,
      IPv6: string | null,
      Speed: string | null,
      Location: string | null,
      VLAN: string | null,
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface ConnectedNetworkConnectionFragment {
  __typename: "NetworkConnection",
  IPv4: string | null,
  IPv6: string | null,
  Speed: string | null,
  Location: string | null,
  VLAN: string | null,
};

export type ContentBlockFragment = ( {
      __typename: "ContentBlocksIntro",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksText",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksTextWithImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      imageAlignment: ImageAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
      textAlignment: TextAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksGraphic",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksVideo",
      // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
      videoUrl: string | null,
    } | {
      __typename: "ContentBlocksAudio",
      // https://player.rss.com/ix-talks/xxxxxx
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "ContentBlocksTable",
      heading: string | null,
      table:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksCodeSnippet",
      // The language used for syntax highlighting.
      programmingLanguage: ProgrammingLanguageEnum | null,
      snippet: string | null,
    } | {
      __typename: "ContentBlocksContactForm",
      id: string | null,
      // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // If enabled, there will be a option to upload a document in the contact form.
      documentUpload: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksLogos",
      logos:  Array<( {
          __typename: "ImagesVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        } | {
          __typename: "UploadAssetsVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksButton",
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksSectionEnding",
    } | {
      __typename: "ContentBlocksUsers",
      id: string | null,
      heading: string | null,
      users:  Array< {
        __typename: "User",
        fullName: string | null,
        // This will be shown in the author information on the website.
        functionTitle: string | null,
        photo:  {
          __typename: "ImagesVolume",
          url: string | null,
        } | null,
      } | null > | null,
    } | {
      __typename: "ContentBlocksTrafficGraph",
      id: string | null,
      dataset:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
          title: string,
          interval: IntervalEnum | null,
          peakIn: number | null,
          peakOut: number | null,
          averageIn: number | null,
          averageOut: number | null,
          currentIn: number | null,
          currentOut: number | null,
          averageInputData:  Array< {
            __typename: "AverageInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "AverageOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "PeakOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "PeakInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksApplicationForm",
      id: string | null,
      // The text that appears on the button that opens the application form.
      buttonText: string | null,
      applicationType: ApplicationTypeEnum | null,
    } | {
      __typename: "ContentBlocksRegistrationForm",
      id: string | null,
      // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    }
  );

export interface ExchangePageEventsFragment {
  __typename: "ExchangePage",
  // Select an Event to be displayed larger and with image in the "Events" section.
  featuredEvent:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
      title: string,
      location: string | null,
      // For a one-day event, only fill in the start date.
      startDate: string | null,
      endDate: string | null,
      id: number,
      uri: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface EventSummaryFragment {
  __typename: "Event",
  title: string,
  location: string | null,
  // For a one-day event, only fill in the start date.
  startDate: string | null,
  endDate: string | null,
  id: number,
  uri: string | null,
};

export interface FeaturedEventFragment {
  __typename: "Event",
  title: string,
  location: string | null,
  // For a one-day event, only fill in the start date.
  startDate: string | null,
  endDate: string | null,
  id: number,
  uri: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
};

export interface FooterLinkFragment {
  __typename: "FooterLink",
  id: number,
  title: string,
  // The page this item links to. This field is ignored if this is a top-level menu item or category.
  linkEntry:  Array<( {
      __typename: "ContactRequestPartner",
      uri: string | null,
    } | {
      __typename: "ContactRequestNetwork",
      uri: string | null,
    } | {
      __typename: "ContactRequestInternal",
      uri: string | null,
    } | {
      __typename: "ContactRequestDocument",
      uri: string | null,
    } | {
      __typename: "ContactRequestWishlist",
      uri: string | null,
    } | {
      __typename: "ContactRequestRegistration",
      uri: string | null,
    } | {
      __typename: "City",
      uri: string | null,
    } | {
      __typename: "ConnectedNetworkCollection",
      uri: string | null,
    } | {
      __typename: "Country",
      uri: string | null,
    } | {
      __typename: "Documentation",
      uri: string | null,
    } | {
      __typename: "Event",
      uri: string | null,
    } | {
      __typename: "EventCollection",
      uri: string | null,
    } | {
      __typename: "ExchangePage",
      uri: string | null,
    } | {
      __typename: "FooterLink",
      uri: string | null,
    } | {
      __typename: "GetStarted",
      uri: string | null,
    } | {
      __typename: "Graph",
      uri: string | null,
    } | {
      __typename: "Info",
      uri: string | null,
    } | {
      __typename: "Menu",
      uri: string | null,
    } | {
      __typename: "Network",
      uri: string | null,
    } | {
      __typename: "NetworkConnection",
      uri: string | null,
    } | {
      __typename: "News",
      uri: string | null,
    } | {
      __typename: "NewsCollection",
      uri: string | null,
    } | {
      __typename: "Newsletter",
      uri: string | null,
    } | {
      __typename: "NewsletterCollection",
      uri: string | null,
    } | {
      __typename: "Partner",
      uri: string | null,
    } | {
      __typename: "PartnerCollection",
      uri: string | null,
    } | {
      __typename: "Search",
      uri: string | null,
    } | {
      __typename: "Service",
      uri: string | null,
    } | {
      __typename: "Story",
      uri: string | null,
    } | {
      __typename: "StoryCollection",
      uri: string | null,
    }
  ) | null > | null,
};

export interface MenuFragment {
  __typename: "Menu",
  id: number,
  title: string,
  // The page this item links to. This field is ignored if this is a top-level menu item or category.
  linkEntry:  Array<( {
      __typename: "ContactRequestPartner",
      uri: string | null,
    } | {
      __typename: "ContactRequestNetwork",
      uri: string | null,
    } | {
      __typename: "ContactRequestInternal",
      uri: string | null,
    } | {
      __typename: "ContactRequestDocument",
      uri: string | null,
    } | {
      __typename: "ContactRequestWishlist",
      uri: string | null,
    } | {
      __typename: "ContactRequestRegistration",
      uri: string | null,
    } | {
      __typename: "City",
      uri: string | null,
    } | {
      __typename: "ConnectedNetworkCollection",
      uri: string | null,
    } | {
      __typename: "Country",
      uri: string | null,
    } | {
      __typename: "Documentation",
      uri: string | null,
    } | {
      __typename: "Event",
      uri: string | null,
    } | {
      __typename: "EventCollection",
      uri: string | null,
    } | {
      __typename: "ExchangePage",
      uri: string | null,
    } | {
      __typename: "FooterLink",
      uri: string | null,
    } | {
      __typename: "GetStarted",
      uri: string | null,
    } | {
      __typename: "Graph",
      uri: string | null,
    } | {
      __typename: "Info",
      uri: string | null,
    } | {
      __typename: "Menu",
      uri: string | null,
    } | {
      __typename: "Network",
      uri: string | null,
    } | {
      __typename: "NetworkConnection",
      uri: string | null,
    } | {
      __typename: "News",
      uri: string | null,
    } | {
      __typename: "NewsCollection",
      uri: string | null,
    } | {
      __typename: "Newsletter",
      uri: string | null,
    } | {
      __typename: "NewsletterCollection",
      uri: string | null,
    } | {
      __typename: "Partner",
      uri: string | null,
    } | {
      __typename: "PartnerCollection",
      uri: string | null,
    } | {
      __typename: "Search",
      uri: string | null,
    } | {
      __typename: "Service",
      uri: string | null,
    } | {
      __typename: "Story",
      uri: string | null,
    } | {
      __typename: "StoryCollection",
      uri: string | null,
    }
  ) | null > | null,
  // Only fill this in if you want to link to an external webpage. It will override the entry-link.
  externalLink: string | null,
  ancestors:  Array<( {
      __typename: "ContactRequestPartner",
      title: string,
    } | {
      __typename: "ContactRequestNetwork",
      title: string,
    } | {
      __typename: "ContactRequestInternal",
      title: string,
    } | {
      __typename: "ContactRequestDocument",
      title: string,
    } | {
      __typename: "ContactRequestWishlist",
      title: string,
    } | {
      __typename: "ContactRequestRegistration",
      title: string,
    } | {
      __typename: "City",
      title: string,
    } | {
      __typename: "ConnectedNetworkCollection",
      title: string,
    } | {
      __typename: "Country",
      title: string,
    } | {
      __typename: "Documentation",
      title: string,
    } | {
      __typename: "Event",
      title: string,
    } | {
      __typename: "EventCollection",
      title: string,
    } | {
      __typename: "ExchangePage",
      title: string,
    } | {
      __typename: "FooterLink",
      title: string,
    } | {
      __typename: "GetStarted",
      title: string,
    } | {
      __typename: "Graph",
      title: string,
    } | {
      __typename: "Info",
      title: string,
    } | {
      __typename: "Menu",
      title: string,
    } | {
      __typename: "Network",
      title: string,
    } | {
      __typename: "NetworkConnection",
      title: string,
    } | {
      __typename: "News",
      title: string,
    } | {
      __typename: "NewsCollection",
      title: string,
    } | {
      __typename: "Newsletter",
      title: string,
    } | {
      __typename: "NewsletterCollection",
      title: string,
    } | {
      __typename: "Partner",
      title: string,
    } | {
      __typename: "PartnerCollection",
      title: string,
    } | {
      __typename: "Search",
      title: string,
    } | {
      __typename: "Service",
      title: string,
    } | {
      __typename: "Story",
      title: string,
    } | {
      __typename: "StoryCollection",
      title: string,
    }
  ) | null > | null,
  // Defines the type of dropdown in the top menu (if this is a top-level item).
  menuDropdownType: MenuDropdownTypeEnum | null,
  children:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
      title: string,
      children:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
          id: number,
          title: string,
          // The page this item links to. This field is ignored if this is a top-level menu item or category.
          linkEntry:  Array<( {
              __typename: "ContactRequestPartner",
              uri: string | null,
            } | {
              __typename: "ContactRequestNetwork",
              uri: string | null,
            } | {
              __typename: "ContactRequestInternal",
              uri: string | null,
            } | {
              __typename: "ContactRequestDocument",
              uri: string | null,
            } | {
              __typename: "ContactRequestWishlist",
              uri: string | null,
            } | {
              __typename: "ContactRequestRegistration",
              uri: string | null,
            } | {
              __typename: "City",
              uri: string | null,
            } | {
              __typename: "ConnectedNetworkCollection",
              uri: string | null,
            } | {
              __typename: "Country",
              uri: string | null,
            } | {
              __typename: "Documentation",
              uri: string | null,
            } | {
              __typename: "Event",
              uri: string | null,
            } | {
              __typename: "EventCollection",
              uri: string | null,
            } | {
              __typename: "ExchangePage",
              uri: string | null,
            } | {
              __typename: "FooterLink",
              uri: string | null,
            } | {
              __typename: "GetStarted",
              uri: string | null,
            } | {
              __typename: "Graph",
              uri: string | null,
            } | {
              __typename: "Info",
              uri: string | null,
            } | {
              __typename: "Menu",
              uri: string | null,
            } | {
              __typename: "Network",
              uri: string | null,
            } | {
              __typename: "NetworkConnection",
              uri: string | null,
            } | {
              __typename: "News",
              uri: string | null,
            } | {
              __typename: "NewsCollection",
              uri: string | null,
            } | {
              __typename: "Newsletter",
              uri: string | null,
            } | {
              __typename: "NewsletterCollection",
              uri: string | null,
            } | {
              __typename: "Partner",
              uri: string | null,
            } | {
              __typename: "PartnerCollection",
              uri: string | null,
            } | {
              __typename: "Search",
              uri: string | null,
            } | {
              __typename: "Service",
              uri: string | null,
            } | {
              __typename: "Story",
              uri: string | null,
            } | {
              __typename: "StoryCollection",
              uri: string | null,
            }
          ) | null > | null,
          // Only fill this in if you want to link to an external webpage. It will override the entry-link.
          externalLink: string | null,
          ancestors:  Array<( {
              __typename: "ContactRequestPartner",
              title: string,
            } | {
              __typename: "ContactRequestNetwork",
              title: string,
            } | {
              __typename: "ContactRequestInternal",
              title: string,
            } | {
              __typename: "ContactRequestDocument",
              title: string,
            } | {
              __typename: "ContactRequestWishlist",
              title: string,
            } | {
              __typename: "ContactRequestRegistration",
              title: string,
            } | {
              __typename: "City",
              title: string,
            } | {
              __typename: "ConnectedNetworkCollection",
              title: string,
            } | {
              __typename: "Country",
              title: string,
            } | {
              __typename: "Documentation",
              title: string,
            } | {
              __typename: "Event",
              title: string,
            } | {
              __typename: "EventCollection",
              title: string,
            } | {
              __typename: "ExchangePage",
              title: string,
            } | {
              __typename: "FooterLink",
              title: string,
            } | {
              __typename: "GetStarted",
              title: string,
            } | {
              __typename: "Graph",
              title: string,
            } | {
              __typename: "Info",
              title: string,
            } | {
              __typename: "Menu",
              title: string,
            } | {
              __typename: "Network",
              title: string,
            } | {
              __typename: "NetworkConnection",
              title: string,
            } | {
              __typename: "News",
              title: string,
            } | {
              __typename: "NewsCollection",
              title: string,
            } | {
              __typename: "Newsletter",
              title: string,
            } | {
              __typename: "NewsletterCollection",
              title: string,
            } | {
              __typename: "Partner",
              title: string,
            } | {
              __typename: "PartnerCollection",
              title: string,
            } | {
              __typename: "Search",
              title: string,
            } | {
              __typename: "Service",
              title: string,
            } | {
              __typename: "Story",
              title: string,
            } | {
              __typename: "StoryCollection",
              title: string,
            }
          ) | null > | null,
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface MenuColumnFragment {
  __typename: "Menu",
  title: string,
  children:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
      id: number,
      title: string,
      // The page this item links to. This field is ignored if this is a top-level menu item or category.
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
      // Only fill this in if you want to link to an external webpage. It will override the entry-link.
      externalLink: string | null,
      ancestors:  Array<( {
          __typename: "ContactRequestPartner",
          title: string,
        } | {
          __typename: "ContactRequestNetwork",
          title: string,
        } | {
          __typename: "ContactRequestInternal",
          title: string,
        } | {
          __typename: "ContactRequestDocument",
          title: string,
        } | {
          __typename: "ContactRequestWishlist",
          title: string,
        } | {
          __typename: "ContactRequestRegistration",
          title: string,
        } | {
          __typename: "City",
          title: string,
        } | {
          __typename: "ConnectedNetworkCollection",
          title: string,
        } | {
          __typename: "Country",
          title: string,
        } | {
          __typename: "Documentation",
          title: string,
        } | {
          __typename: "Event",
          title: string,
        } | {
          __typename: "EventCollection",
          title: string,
        } | {
          __typename: "ExchangePage",
          title: string,
        } | {
          __typename: "FooterLink",
          title: string,
        } | {
          __typename: "GetStarted",
          title: string,
        } | {
          __typename: "Graph",
          title: string,
        } | {
          __typename: "Info",
          title: string,
        } | {
          __typename: "Menu",
          title: string,
        } | {
          __typename: "Network",
          title: string,
        } | {
          __typename: "NetworkConnection",
          title: string,
        } | {
          __typename: "News",
          title: string,
        } | {
          __typename: "NewsCollection",
          title: string,
        } | {
          __typename: "Newsletter",
          title: string,
        } | {
          __typename: "NewsletterCollection",
          title: string,
        } | {
          __typename: "Partner",
          title: string,
        } | {
          __typename: "PartnerCollection",
          title: string,
        } | {
          __typename: "Search",
          title: string,
        } | {
          __typename: "Service",
          title: string,
        } | {
          __typename: "Story",
          title: string,
        } | {
          __typename: "StoryCollection",
          title: string,
        }
      ) | null > | null,
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface MenuItemFragment {
  __typename: "Menu",
  id: number,
  title: string,
  // The page this item links to. This field is ignored if this is a top-level menu item or category.
  linkEntry:  Array<( {
      __typename: "ContactRequestPartner",
      uri: string | null,
    } | {
      __typename: "ContactRequestNetwork",
      uri: string | null,
    } | {
      __typename: "ContactRequestInternal",
      uri: string | null,
    } | {
      __typename: "ContactRequestDocument",
      uri: string | null,
    } | {
      __typename: "ContactRequestWishlist",
      uri: string | null,
    } | {
      __typename: "ContactRequestRegistration",
      uri: string | null,
    } | {
      __typename: "City",
      uri: string | null,
    } | {
      __typename: "ConnectedNetworkCollection",
      uri: string | null,
    } | {
      __typename: "Country",
      uri: string | null,
    } | {
      __typename: "Documentation",
      uri: string | null,
    } | {
      __typename: "Event",
      uri: string | null,
    } | {
      __typename: "EventCollection",
      uri: string | null,
    } | {
      __typename: "ExchangePage",
      uri: string | null,
    } | {
      __typename: "FooterLink",
      uri: string | null,
    } | {
      __typename: "GetStarted",
      uri: string | null,
    } | {
      __typename: "Graph",
      uri: string | null,
    } | {
      __typename: "Info",
      uri: string | null,
    } | {
      __typename: "Menu",
      uri: string | null,
    } | {
      __typename: "Network",
      uri: string | null,
    } | {
      __typename: "NetworkConnection",
      uri: string | null,
    } | {
      __typename: "News",
      uri: string | null,
    } | {
      __typename: "NewsCollection",
      uri: string | null,
    } | {
      __typename: "Newsletter",
      uri: string | null,
    } | {
      __typename: "NewsletterCollection",
      uri: string | null,
    } | {
      __typename: "Partner",
      uri: string | null,
    } | {
      __typename: "PartnerCollection",
      uri: string | null,
    } | {
      __typename: "Search",
      uri: string | null,
    } | {
      __typename: "Service",
      uri: string | null,
    } | {
      __typename: "Story",
      uri: string | null,
    } | {
      __typename: "StoryCollection",
      uri: string | null,
    }
  ) | null > | null,
  // Only fill this in if you want to link to an external webpage. It will override the entry-link.
  externalLink: string | null,
  ancestors:  Array<( {
      __typename: "ContactRequestPartner",
      title: string,
    } | {
      __typename: "ContactRequestNetwork",
      title: string,
    } | {
      __typename: "ContactRequestInternal",
      title: string,
    } | {
      __typename: "ContactRequestDocument",
      title: string,
    } | {
      __typename: "ContactRequestWishlist",
      title: string,
    } | {
      __typename: "ContactRequestRegistration",
      title: string,
    } | {
      __typename: "City",
      title: string,
    } | {
      __typename: "ConnectedNetworkCollection",
      title: string,
    } | {
      __typename: "Country",
      title: string,
    } | {
      __typename: "Documentation",
      title: string,
    } | {
      __typename: "Event",
      title: string,
    } | {
      __typename: "EventCollection",
      title: string,
    } | {
      __typename: "ExchangePage",
      title: string,
    } | {
      __typename: "FooterLink",
      title: string,
    } | {
      __typename: "GetStarted",
      title: string,
    } | {
      __typename: "Graph",
      title: string,
    } | {
      __typename: "Info",
      title: string,
    } | {
      __typename: "Menu",
      title: string,
    } | {
      __typename: "Network",
      title: string,
    } | {
      __typename: "NetworkConnection",
      title: string,
    } | {
      __typename: "News",
      title: string,
    } | {
      __typename: "NewsCollection",
      title: string,
    } | {
      __typename: "Newsletter",
      title: string,
    } | {
      __typename: "NewsletterCollection",
      title: string,
    } | {
      __typename: "Partner",
      title: string,
    } | {
      __typename: "PartnerCollection",
      title: string,
    } | {
      __typename: "Search",
      title: string,
    } | {
      __typename: "Service",
      title: string,
    } | {
      __typename: "Story",
      title: string,
    } | {
      __typename: "StoryCollection",
      title: string,
    }
  ) | null > | null,
};

export interface HeroFragment {
  __typename: "ExchangePage",
  // A short description for display in lists and other overviews.
  description: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
};

export interface NewsItemFragment {
  __typename: "News",
  id: number,
  uri: string | null,
  title: string,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    }
  ) | null > | null,
  postDate: string | null,
};

export interface NewsItemsFragment {
  __typename: "News",
  id: number,
  uri: string | null,
  title: string,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    }
  ) | null > | null,
  postDate: string | null,
};

export interface RelatedStoryFragment {
  __typename: "Story",
  id: number,
  uri: string | null,
  title: string,
  postDate: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
  service:  Array<( {
      __typename: "ContactRequestPartner",
      title: string,
    } | {
      __typename: "ContactRequestNetwork",
      title: string,
    } | {
      __typename: "ContactRequestInternal",
      title: string,
    } | {
      __typename: "ContactRequestDocument",
      title: string,
    } | {
      __typename: "ContactRequestWishlist",
      title: string,
    } | {
      __typename: "ContactRequestRegistration",
      title: string,
    } | {
      __typename: "City",
      title: string,
    } | {
      __typename: "ConnectedNetworkCollection",
      title: string,
    } | {
      __typename: "Country",
      title: string,
    } | {
      __typename: "Documentation",
      title: string,
    } | {
      __typename: "Event",
      title: string,
    } | {
      __typename: "EventCollection",
      title: string,
    } | {
      __typename: "ExchangePage",
      title: string,
    } | {
      __typename: "FooterLink",
      title: string,
    } | {
      __typename: "GetStarted",
      title: string,
    } | {
      __typename: "Graph",
      title: string,
    } | {
      __typename: "Info",
      title: string,
    } | {
      __typename: "Menu",
      title: string,
    } | {
      __typename: "Network",
      title: string,
    } | {
      __typename: "NetworkConnection",
      title: string,
    } | {
      __typename: "News",
      title: string,
    } | {
      __typename: "NewsCollection",
      title: string,
    } | {
      __typename: "Newsletter",
      title: string,
    } | {
      __typename: "NewsletterCollection",
      title: string,
    } | {
      __typename: "Partner",
      title: string,
    } | {
      __typename: "PartnerCollection",
      title: string,
    } | {
      __typename: "Search",
      title: string,
    } | {
      __typename: "Service",
      title: string,
    } | {
      __typename: "Story",
      title: string,
    } | {
      __typename: "StoryCollection",
      title: string,
    }
  ) | null > | null,
};

export interface RelatedEventFragment {
  __typename: "Event",
  id: number,
  uri: string | null,
  title: string,
  postDate: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
};

export interface RelatedServiceFragment {
  __typename: "Service",
  id: number,
  uri: string | null,
  title: string,
  postDate: string | null,
  relatedItemImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
};

export interface RelatedInfoFragment {
  __typename: "Info",
  id: number,
  uri: string | null,
  title: string,
  postDate: string | null,
  relatedItemImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
};

export interface RelatedDocumentationFragment {
  __typename: "Documentation",
  id: number,
  uri: string | null,
  title: string,
  postDate: string | null,
  relatedItemImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
};

export interface RelatedNewsFragment {
  __typename: "News",
  id: number,
  uri: string | null,
  title: string,
  postDate: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
};

export type RelatedFragment = ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Event",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Story",
      id: number,
      uri: string | null,
      title: string,
      postDate: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      service:  Array<( {
          __typename: "ContactRequestPartner",
          title: string,
        } | {
          __typename: "ContactRequestNetwork",
          title: string,
        } | {
          __typename: "ContactRequestInternal",
          title: string,
        } | {
          __typename: "ContactRequestDocument",
          title: string,
        } | {
          __typename: "ContactRequestWishlist",
          title: string,
        } | {
          __typename: "ContactRequestRegistration",
          title: string,
        } | {
          __typename: "City",
          title: string,
        } | {
          __typename: "ConnectedNetworkCollection",
          title: string,
        } | {
          __typename: "Country",
          title: string,
        } | {
          __typename: "Documentation",
          title: string,
        } | {
          __typename: "Event",
          title: string,
        } | {
          __typename: "EventCollection",
          title: string,
        } | {
          __typename: "ExchangePage",
          title: string,
        } | {
          __typename: "FooterLink",
          title: string,
        } | {
          __typename: "GetStarted",
          title: string,
        } | {
          __typename: "Graph",
          title: string,
        } | {
          __typename: "Info",
          title: string,
        } | {
          __typename: "Menu",
          title: string,
        } | {
          __typename: "Network",
          title: string,
        } | {
          __typename: "NetworkConnection",
          title: string,
        } | {
          __typename: "News",
          title: string,
        } | {
          __typename: "NewsCollection",
          title: string,
        } | {
          __typename: "Newsletter",
          title: string,
        } | {
          __typename: "NewsletterCollection",
          title: string,
        } | {
          __typename: "Partner",
          title: string,
        } | {
          __typename: "PartnerCollection",
          title: string,
        } | {
          __typename: "Search",
          title: string,
        } | {
          __typename: "Service",
          title: string,
        } | {
          __typename: "Story",
          title: string,
        } | {
          __typename: "StoryCollection",
          title: string,
        }
      ) | null > | null,
    } | {
      __typename: "StoryCollection",
    }
  );

export interface FeaturedStoryFragment {
  __typename: "Story",
  id: number,
  title: string,
  uri: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
  // A short description for display in lists and other overviews.
  description: string | null,
  service:  Array<( {
      __typename: "ContactRequestPartner",
      title: string,
    } | {
      __typename: "ContactRequestNetwork",
      title: string,
    } | {
      __typename: "ContactRequestInternal",
      title: string,
    } | {
      __typename: "ContactRequestDocument",
      title: string,
    } | {
      __typename: "ContactRequestWishlist",
      title: string,
    } | {
      __typename: "ContactRequestRegistration",
      title: string,
    } | {
      __typename: "City",
      title: string,
    } | {
      __typename: "ConnectedNetworkCollection",
      title: string,
    } | {
      __typename: "Country",
      title: string,
    } | {
      __typename: "Documentation",
      title: string,
    } | {
      __typename: "Event",
      title: string,
    } | {
      __typename: "EventCollection",
      title: string,
    } | {
      __typename: "ExchangePage",
      title: string,
    } | {
      __typename: "FooterLink",
      title: string,
    } | {
      __typename: "GetStarted",
      title: string,
    } | {
      __typename: "Graph",
      title: string,
    } | {
      __typename: "Info",
      title: string,
    } | {
      __typename: "Menu",
      title: string,
    } | {
      __typename: "Network",
      title: string,
    } | {
      __typename: "NetworkConnection",
      title: string,
    } | {
      __typename: "News",
      title: string,
    } | {
      __typename: "NewsCollection",
      title: string,
    } | {
      __typename: "Newsletter",
      title: string,
    } | {
      __typename: "NewsletterCollection",
      title: string,
    } | {
      __typename: "Partner",
      title: string,
    } | {
      __typename: "PartnerCollection",
      title: string,
    } | {
      __typename: "Search",
      title: string,
    } | {
      __typename: "Service",
      title: string,
    } | {
      __typename: "Story",
      title: string,
    } | {
      __typename: "StoryCollection",
      title: string,
    }
  ) | null > | null,
  postDate: string | null,
};

export interface FeaturedStoriesFragment {
  __typename: "ExchangePage",
  // Select the stories to appear in the "Featured" section.
  featuredStories:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
      id: number,
      title: string,
      uri: string | null,
      // The main image for this item.
      heroImage:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      // A short description for display in lists and other overviews.
      description: string | null,
      service:  Array<( {
          __typename: "ContactRequestPartner",
          title: string,
        } | {
          __typename: "ContactRequestNetwork",
          title: string,
        } | {
          __typename: "ContactRequestInternal",
          title: string,
        } | {
          __typename: "ContactRequestDocument",
          title: string,
        } | {
          __typename: "ContactRequestWishlist",
          title: string,
        } | {
          __typename: "ContactRequestRegistration",
          title: string,
        } | {
          __typename: "City",
          title: string,
        } | {
          __typename: "ConnectedNetworkCollection",
          title: string,
        } | {
          __typename: "Country",
          title: string,
        } | {
          __typename: "Documentation",
          title: string,
        } | {
          __typename: "Event",
          title: string,
        } | {
          __typename: "EventCollection",
          title: string,
        } | {
          __typename: "ExchangePage",
          title: string,
        } | {
          __typename: "FooterLink",
          title: string,
        } | {
          __typename: "GetStarted",
          title: string,
        } | {
          __typename: "Graph",
          title: string,
        } | {
          __typename: "Info",
          title: string,
        } | {
          __typename: "Menu",
          title: string,
        } | {
          __typename: "Network",
          title: string,
        } | {
          __typename: "NetworkConnection",
          title: string,
        } | {
          __typename: "News",
          title: string,
        } | {
          __typename: "NewsCollection",
          title: string,
        } | {
          __typename: "Newsletter",
          title: string,
        } | {
          __typename: "NewsletterCollection",
          title: string,
        } | {
          __typename: "Partner",
          title: string,
        } | {
          __typename: "PartnerCollection",
          title: string,
        } | {
          __typename: "Search",
          title: string,
        } | {
          __typename: "Service",
          title: string,
        } | {
          __typename: "Story",
          title: string,
        } | {
          __typename: "StoryCollection",
          title: string,
        }
      ) | null > | null,
      postDate: string | null,
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface ServiceHowItWorksFragment {
  __typename: "Service",
  id: number,
  contentBlocks:  Array<( {
      __typename: "ContentBlocksIntro",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksText",
      id: string | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksTextWithImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      textHTML:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
      imageAlignment: ImageAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksImage",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          title: string | null,
        }
      ) | null > | null,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
      textAlignment: TextAlignmentEnum | null,
    } | {
      __typename: "ContentBlocksGraphic",
      image:  Array<( {
          __typename: "ImagesVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          srcSmall: string | null,
          widthSmall: string | null,
          srcMedium: string | null,
          widthMedium: string | null,
          srcLarge: string | null,
          widthLarge: string | null,
          srcOriginal: string | null,
          widthOriginal: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksVideo",
      // Enter URL for the video to be embedded. Supports YouTube, Vimeo, Facebook, Streamable, Wistia, Mixcloud, and DailyMotion.
      videoUrl: string | null,
    } | {
      __typename: "ContentBlocksAudio",
      // https://player.rss.com/ix-talks/xxxxxx
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "ContentBlocksTable",
      heading: string | null,
      table:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksCodeSnippet",
      // The language used for syntax highlighting.
      programmingLanguage: ProgrammingLanguageEnum | null,
      snippet: string | null,
    } | {
      __typename: "ContentBlocksContactForm",
      id: string | null,
      // This text appears in the content block above the "Contact us" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // If enabled, there will be a option to upload a document in the contact form.
      documentUpload: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    } | {
      __typename: "ContentBlocksLogos",
      logos:  Array<( {
          __typename: "ImagesVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        } | {
          __typename: "UploadAssetsVolume",
          src: string | null,
          src2x: string | null,
          title: string | null,
          id: number | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksButton",
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
          uri: string | null,
        } | {
          __typename: "ContactRequestNetwork",
          uri: string | null,
        } | {
          __typename: "ContactRequestInternal",
          uri: string | null,
        } | {
          __typename: "ContactRequestDocument",
          uri: string | null,
        } | {
          __typename: "ContactRequestWishlist",
          uri: string | null,
        } | {
          __typename: "ContactRequestRegistration",
          uri: string | null,
        } | {
          __typename: "City",
          uri: string | null,
        } | {
          __typename: "ConnectedNetworkCollection",
          uri: string | null,
        } | {
          __typename: "Country",
          uri: string | null,
        } | {
          __typename: "Documentation",
          uri: string | null,
        } | {
          __typename: "Event",
          uri: string | null,
        } | {
          __typename: "EventCollection",
          uri: string | null,
        } | {
          __typename: "ExchangePage",
          uri: string | null,
        } | {
          __typename: "FooterLink",
          uri: string | null,
        } | {
          __typename: "GetStarted",
          uri: string | null,
        } | {
          __typename: "Graph",
          uri: string | null,
        } | {
          __typename: "Info",
          uri: string | null,
        } | {
          __typename: "Menu",
          uri: string | null,
        } | {
          __typename: "Network",
          uri: string | null,
        } | {
          __typename: "NetworkConnection",
          uri: string | null,
        } | {
          __typename: "News",
          uri: string | null,
        } | {
          __typename: "NewsCollection",
          uri: string | null,
        } | {
          __typename: "Newsletter",
          uri: string | null,
        } | {
          __typename: "NewsletterCollection",
          uri: string | null,
        } | {
          __typename: "Partner",
          uri: string | null,
        } | {
          __typename: "PartnerCollection",
          uri: string | null,
        } | {
          __typename: "Search",
          uri: string | null,
        } | {
          __typename: "Service",
          uri: string | null,
        } | {
          __typename: "Story",
          uri: string | null,
        } | {
          __typename: "StoryCollection",
          uri: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksSectionEnding",
    } | {
      __typename: "ContentBlocksUsers",
      id: string | null,
      heading: string | null,
      users:  Array< {
        __typename: "User",
        fullName: string | null,
        // This will be shown in the author information on the website.
        functionTitle: string | null,
        photo:  {
          __typename: "ImagesVolume",
          url: string | null,
        } | null,
      } | null > | null,
    } | {
      __typename: "ContentBlocksTrafficGraph",
      id: string | null,
      dataset:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
          title: string,
          interval: IntervalEnum | null,
          peakIn: number | null,
          peakOut: number | null,
          averageIn: number | null,
          averageOut: number | null,
          currentIn: number | null,
          currentOut: number | null,
          averageInputData:  Array< {
            __typename: "AverageInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "AverageOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "PeakOutputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "PeakInputDataTable",
            // Time
            time: number | null,
            // Value (Mbit/s)
            value: number | null,
          } | null > | null,
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "ContentBlocksApplicationForm",
      id: string | null,
      // The text that appears on the button that opens the application form.
      buttonText: string | null,
      applicationType: ApplicationTypeEnum | null,
    } | {
      __typename: "ContentBlocksRegistrationForm",
      id: string | null,
      // This text appears in the content block above the "Register" button. Will not be shown if "Compact Display" is enabled.
      ctaText: string | null,
      // The text that appears on the button that opens the contact form.
      buttonText: string | null,
      // If enabled, this block will be displayed as a single small button instead of a full sized block.
      compactDisplay: boolean | null,
      // This text appears below the title in the contact form overlay.
      description:  {
        __typename: "RedactorFieldData",
        content: string | null,
      } | null,
    }
  ) | null > | null,
};

export interface ServiceMenuParentFragment {
  __typename: "Menu",
  children:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
      // The page this item links to. This field is ignored if this is a top-level menu item or category.
      linkEntry:  Array<( {
          __typename: "ContactRequestPartner",
        } | {
          __typename: "ContactRequestNetwork",
        } | {
          __typename: "ContactRequestInternal",
        } | {
          __typename: "ContactRequestDocument",
        } | {
          __typename: "ContactRequestWishlist",
        } | {
          __typename: "ContactRequestRegistration",
        } | {
          __typename: "City",
        } | {
          __typename: "ConnectedNetworkCollection",
        } | {
          __typename: "Country",
        } | {
          __typename: "Documentation",
        } | {
          __typename: "Event",
        } | {
          __typename: "EventCollection",
        } | {
          __typename: "ExchangePage",
        } | {
          __typename: "FooterLink",
        } | {
          __typename: "GetStarted",
        } | {
          __typename: "Graph",
        } | {
          __typename: "Info",
        } | {
          __typename: "Menu",
        } | {
          __typename: "Network",
        } | {
          __typename: "NetworkConnection",
        } | {
          __typename: "News",
        } | {
          __typename: "NewsCollection",
        } | {
          __typename: "Newsletter",
        } | {
          __typename: "NewsletterCollection",
        } | {
          __typename: "Partner",
        } | {
          __typename: "PartnerCollection",
        } | {
          __typename: "Search",
        } | {
          __typename: "Service",
          id: number,
          title: string,
          // A short description for display in lists and other overviews.
          description: string | null,
          uri: string | null,
          slug: string,
          // The main service icon, shown in the Services menu and on the Service page.
          icon:  Array<( {
              __typename: "ImagesVolume",
              url: string | null,
              title: string | null,
            } | {
              __typename: "UploadAssetsVolume",
              url: string | null,
              title: string | null,
            }
          ) | null > | null,
        } | {
          __typename: "Story",
        } | {
          __typename: "StoryCollection",
        }
      ) | null > | null,
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface ServiceMenuFragment {
  __typename: "Menu",
  // The page this item links to. This field is ignored if this is a top-level menu item or category.
  linkEntry:  Array<( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
      id: number,
      title: string,
      // A short description for display in lists and other overviews.
      description: string | null,
      uri: string | null,
      slug: string,
      // The main service icon, shown in the Services menu and on the Service page.
      icon:  Array<( {
          __typename: "ImagesVolume",
          url: string | null,
          title: string | null,
        } | {
          __typename: "UploadAssetsVolume",
          url: string | null,
          title: string | null,
        }
      ) | null > | null,
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  ) | null > | null,
};

export interface ServiceFragment {
  __typename: "Service",
  id: number,
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  uri: string | null,
  slug: string,
  // The main service icon, shown in the Services menu and on the Service page.
  icon:  Array<( {
      __typename: "ImagesVolume",
      url: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      url: string | null,
      title: string | null,
    }
  ) | null > | null,
};

export interface FeaturedStoryCollectionItemFragment {
  __typename: "Story",
  id: number,
  uri: string | null,
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      srcSmall: string | null,
      widthSmall: string | null,
      srcMedium: string | null,
      widthMedium: string | null,
      srcLarge: string | null,
      widthLarge: string | null,
      title: string | null,
    }
  ) | null > | null,
  postDate: string | null,
};

export interface NewsCollectionItemFragment {
  __typename: "News",
  id: number,
  uri: string | null,
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    }
  ) | null > | null,
  date: string | null,
};

export interface NewsletterCollectionItemFragment {
  __typename: "Newsletter",
  id: number,
  uri: string | null,
  title: string,
  newsletterUrl: string | null,
  newsletterDate: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    }
  ) | null > | null,
  date: string | null,
};

export interface EventCollectionItemFragment {
  __typename: "Event",
  id: number,
  uri: string | null,
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    }
  ) | null > | null,
  // For a one-day event, only fill in the start date.
  startDate: string | null,
  endDate: string | null,
  date: string | null,
  // Event will be shown in the past events tab.
  listInPastEvents: boolean | null,
  location: string | null,
};

export interface StoryCollectionItemFragment {
  __typename: "Story",
  id: number,
  uri: string | null,
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  // The main image for this item.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    }
  ) | null > | null,
  date: string | null,
};

export interface InfoCollectionItemFragment {
  __typename: "Info",
  id: number,
  uri: string | null,
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
};

export interface DocumentationCollectionItemFragment {
  __typename: "Documentation",
  id: number,
  uri: string | null,
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
};

export interface ServiceCollectionItemFragment {
  __typename: "Service",
  id: number,
  uri: string | null,
  title: string,
  // A short description for display in lists and other overviews.
  description: string | null,
  // The main service icon, shown in the Services menu and on the Service page.
  heroImage:  Array<( {
      __typename: "ImagesVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    } | {
      __typename: "UploadAssetsVolume",
      src: string | null,
      width: string | null,
      src2x: string | null,
      width2x: string | null,
      title: string | null,
    }
  ) | null > | null,
};

export type EntryTypesFragment = ( {
      __typename: "ContactRequestPartner",
    } | {
      __typename: "ContactRequestNetwork",
    } | {
      __typename: "ContactRequestInternal",
    } | {
      __typename: "ContactRequestDocument",
    } | {
      __typename: "ContactRequestWishlist",
    } | {
      __typename: "ContactRequestRegistration",
    } | {
      __typename: "City",
    } | {
      __typename: "ConnectedNetworkCollection",
    } | {
      __typename: "Country",
    } | {
      __typename: "Documentation",
    } | {
      __typename: "Event",
    } | {
      __typename: "EventCollection",
    } | {
      __typename: "ExchangePage",
    } | {
      __typename: "FooterLink",
    } | {
      __typename: "GetStarted",
    } | {
      __typename: "Graph",
    } | {
      __typename: "Info",
    } | {
      __typename: "Menu",
    } | {
      __typename: "Network",
    } | {
      __typename: "NetworkConnection",
    } | {
      __typename: "News",
    } | {
      __typename: "NewsCollection",
    } | {
      __typename: "Newsletter",
    } | {
      __typename: "NewsletterCollection",
    } | {
      __typename: "Partner",
    } | {
      __typename: "PartnerCollection",
    } | {
      __typename: "Search",
    } | {
      __typename: "Service",
    } | {
      __typename: "Story",
    } | {
      __typename: "StoryCollection",
    }
  );
