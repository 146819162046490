import gql from 'graphql-tag';
import React, { SFC } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/styles/hljs';
import { CodeContentBlockFragment } from '../../entities/operationResults';
import styled from '../../styled-components';
import Container from '../atoms/Container';

export const fragment = gql`
    fragment CodeContentBlock on ContentBlocksCodeSnippet {
        programmingLanguage
        snippet
    }
`;

const styles = {
    ...atomOneDark,
    hljs: {
        ...atomOneDark.hljs,
        fontFamily: '"Source Code Pro", Monaco, monospace',
        color: '#FFF',
        fontSize: '1.4rem',
        padding: '3.2rem',
        borderRadius: '4px',
        marginTop: 0,
        marginBottom: 0,
    },
};

interface Props {
    contentBlock: CodeContentBlockFragment;
    narrow?: boolean;
}

const CodeContentBlock: SFC<Props> = ({ contentBlock: { programmingLanguage, snippet }, narrow }) => {
    return (
        <CodeContainer maxWidth paddingBottom="4rem" paddingBottomL="8rem">
            <Wrapper narrow={narrow}>
                <SyntaxHighlighter language={programmingLanguage || 'text'} style={styles}>
                    {snippet}
                </SyntaxHighlighter>
            </Wrapper>
        </CodeContainer>
    );
};

const CodeContainer = styled(Container)`
    pre {
        @media screen and (max-width: ${props => props.theme.mediaQueries.s}) {
            padding: 2.4rem !important;
            margin-left: -2.4rem !important;
            margin-right: -2.4rem !important;
        }
    }
`;

const Wrapper = styled.div<{ narrow?: boolean }>`
    ${props =>
        props.narrow
            ? `
        max-width: 900px;
        margin: 0 auto;
        
        @media screen and (min-width: ${props.theme.mediaQueries.l}) {
            > div, > pre {
                max-width: 75%;
            }
        }
    `
            : ''}
`;

export default CodeContentBlock;
