import React from 'react';
import styled from '../../../styled-components';
import { getEntriesOfType } from '../../../services/entryTypes';
import { Link } from 'react-router-dom';
import { MenuFragment } from '../../../entities/operationResults';

interface Props {
    menuItems: MenuFragment[];
}

function getMenuItemChildren(menuItem: MenuFragment): MenuFragment[] {
    return getEntriesOfType('Menu', menuItem && menuItem.children);
}

const FooterMenu = ({ menuItems }: Props) => {
    return (
        <FooterMenuWrap>
            {menuItems.map((menuItem, index) => {
                if (menuItem.menuDropdownType === 'statistics') { return null; }
                return (
                    <React.Fragment key={menuItem.id}>
                        <li>
                            <h4>{menuItem.title}</h4>
                            <ul>
                                {getMenuItemChildren(menuItem).map((menuItemChild) => {
                                    return(

                                        getMenuItemChildren(menuItemChild).map((menuItemGrandchild) => {
                                            const link = menuItemGrandchild.linkEntry && menuItemGrandchild.linkEntry[0];
                                            const externalLink = menuItemGrandchild.externalLink;
                                            return (
                                                <List key={menuItemGrandchild.id}>
                                                    {menuItemGrandchild.linkEntry && link &&
                                                    <NavLink to={link && link.uri ? `/${link.uri}` : '#'}>
                                                        {menuItemGrandchild.title}
                                                    </NavLink>
                                                    }
                                                    {menuItemGrandchild.linkEntry && !link && externalLink &&
                                                        <ExternalNavlink target="_blank" href={externalLink}>{menuItemGrandchild.title}</ExternalNavlink>
                                                    }
                                                </List>
                                            );
                                        })
                                    );
                                })}
                            </ul>
                        </li>
                    </React.Fragment>
                );
            })}
        </FooterMenuWrap>
    );
};

const List = styled.li`
    max-width: 15rem;
`;

const FooterMenuWrap = styled.ul`
  user-select: none;
  display: inline-flex;
  flex: 2;
  justify-content: space-between;
  color: white;
  text-align: left;
  padding: 0;
  list-style: none;
  margin: 0 8rem 0 0;
  ul {
    padding: 0;
    list-style: none;
  }
  h4{
    font: ${(props) => props.theme.fonts.large.eventDate};
    color: ${(props) => props.theme.colors.baliHai};
    text-transform: uppercase;
    margin: 0 0 0.8rem 0;
  }
  @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
    margin: 0 2rem 0 0;
  }
`;

const NavLink = styled(Link)`
  font-weight: bold;
  display: block;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 0.8rem 0;
`;

const ExternalNavlink = styled.a`
  font-weight: bold;
  display: block;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 0.8rem 0;
`;

export default FooterMenu;
