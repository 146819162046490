import { Form as FormikForm, FormikBag, FormikProps, withFormik } from 'formik';
import gql from 'graphql-tag';
import React, { Fragment, SFC } from 'react';
import { graphql, MutationFunction } from 'react-apollo';
import ReactGA from 'react-ga';
import ReCAPTCHA from 'react-google-recaptcha';
import { branch, compose } from 'recompose';
import { object, string } from 'yup';
import { default as sites, Site } from '../../constants/sites';
import {
    sendContactRequestMutation,
    sendContactRequestMutationVariables,
    sendContactRequestToNetworkMutation,
    sendContactRequestToNetworkMutationVariables,
    sendContactRequestDocumentMutation,
    sendContactRequestToPartnerMutation,
    sendContactRequestDocumentMutationVariables,
    sendContactRequestToPartnerMutationVariables,
} from '../../entities/operationResults';
import matchRoute from '../../services/matchRoute';
import styled from '../../styled-components';
import DefaultButton from '../atoms/buttons/DefaultButton';
import * as Form from '../atoms/Form';
import { ExchangeParams } from '../Exchange';
import * as Intro from '../molecules/Intro';
import { uploadCandidateAsset } from '../../constants/apiUrls';

interface Values {
    fullName: string;
    phoneNumber: string;
    comment: string;
    emailAddress: string;
    recaptcha: string;
    company: string;
    cv?: any;
    subject: string;
}

const CONTACT_REQUEST_MUTATION = gql`
    mutation sendContactRequest(
        $siteId: Int
        $subject: String
        $comment: String
        $emailAddress: String
        $fullName: String
        $company: String
        $phoneNumber: String
        $contactFormId: Int
        $recaptcha: String
        $siteLocation: String
    ) {
        upsertContactRequestInternal(
            siteId: $siteId
            subject: $subject
            comment: $comment
            title: "dummy"
            emailAddress: $emailAddress
            fullName: $fullName
            company: $company
            phoneNumber: $phoneNumber
            contactFormId: $contactFormId
            recaptchaValue: $recaptcha
            siteLocation: $siteLocation
        ) {
            mailSent
        }
    }
`;

const CONTACT_REQUEST_MUTATION_DOCUMENT = gql`
    mutation sendContactRequestDocument(
        $siteId: Int
        $comment: String
        $emailAddress: String
        $fullName: String
        $company: String
        $phoneNumber: String
        $contactFormId: Int
        $recaptcha: String
        $cv: Int
        $siteLocation: String
    ) {
        upsertContactRequestDocument(
            siteId: $siteId
            comment: $comment
            title: "dummy"
            emailAddress: $emailAddress
            fullName: $fullName
            company: $company
            phoneNumber: $phoneNumber
            contactFormId: $contactFormId
            recaptchaValue: $recaptcha
            cv: { id: $cv }
            siteLocation: $siteLocation
        ) {
            mailSent
        }
    }
`;

const CONTACT_TO_PARTNER_REQUEST_MUTATION = gql`
    mutation sendContactRequestToPartner(
        $siteId: Int
        $comment: String
        $emailAddress: String
        $fullName: String
        $company: String
        $phoneNumber: String
        $partner: [Int]
        $recaptcha: String
        $siteLocation: String
    ) {
        upsertContactRequestPartner(
            siteId: $siteId
            comment: $comment
            title: "dummy"
            emailAddress: $emailAddress
            fullName: $fullName
            company: $company
            phoneNumber: $phoneNumber
            partner: $partner
            recaptchaValue: $recaptcha
            siteLocation: $siteLocation
        ) {
            mailSent
        }
    }
`;

const CONTACT_TO_NETWORK_REQUEST_MUTATION = gql`
    mutation sendContactRequestToNetwork(
        $siteId: Int
        $comment: String
        $emailAddress: String
        $fullName: String
        $company: String
        $phoneNumber: String
        $connectedNetwork: [Int]
        $recaptcha: String
        $siteLocation: String
    ) {
        upsertContactRequestNetwork(
            siteId: $siteId
            comment: $comment
            title: "dummy"
            emailAddress: $emailAddress
            fullName: $fullName
            company: $company
            phoneNumber: $phoneNumber
            connectedNetwork: $connectedNetwork
            recaptchaValue: $recaptcha
            siteLocation: $siteLocation
        ) {
            mailSent
        }
    }
`;

const trackSubmitFormEvent = () => {
    ReactGA.event({
        category: 'Contactform',
        action: 'Form submitted',
        label: location.pathname,
    });
};

interface InProps {
    contactBlockId?: number | null;
    skipPhoneNumber?: boolean;
    isDark?: boolean;
    toPartner?: number;
    toNetwork?: number;
    documentUpload?: boolean;
}

interface OutProps extends FormikProps<Values> {
    sendContactRequest?: MutationFunction<sendContactRequestMutation, sendContactRequestMutationVariables>;
    sendContactRequestDocument?: MutationFunction<sendContactRequestDocumentMutation, sendContactRequestDocumentMutationVariables>;
    sendContactRequestToPartner?: MutationFunction<
        sendContactRequestToPartnerMutation,
        sendContactRequestToPartnerMutationVariables
    >;
    sendContactRequestToNetwork?: MutationFunction<
        sendContactRequestToNetworkMutation,
        sendContactRequestToNetworkMutationVariables
    >;
}

type Props = InProps & OutProps;

const connectedNetworkList = ['Peering request', 'Security issue', 'Commercial enquiry'];
const partnersList = ['Connection request', 'Information request'];

const ContactForm: SFC<Props> = ({
    submitForm,
    status,
    isSubmitting,
    isValid,
    skipPhoneNumber,
    isDark,
    toPartner,
    toNetwork,
    documentUpload,
    setFieldValue,
    errors,
    touched,
}) => {
    return (
        <FormContainer>
            <FormError>{status && status.type === 'error' && <Form.Error>{status.value}</Form.Error>}</FormError>
            <FormikForm className={
                window.location.href.search('pricing') > -1 ? 'Sales' :
                    window.location.href.search('events') > -1 ? 'Events' :
                        window.location.href.search('service') ?'Customer-service' : ''}>
                {status && status.type === 'success' ? (
                    <Intro.Text>
                        <p className={'success-message'}>
                            {toPartner
                                ? 'Thank you! Your message has been received. Our partner will get back to you soon.'
                                : toNetwork
                                ? 'Thank you! Your message has been sent.'
                                : 'Thank you! Your message has been received. We will get back to you soon.'}
                        </p>
                    </Intro.Text>
                ) : (
                    <Fragment>
                        <Form.Field name="fullName" label="Name*" placeholder="Your full name" />
                        <Form.Field name="company" label="Company*" placeholder="Your company name" />
                        <Form.Field name="emailAddress" label="Email address*" placeholder="Your email address" />
                        {!skipPhoneNumber && (
                            <Form.Field name="phoneNumber" label="Phone number" placeholder="Your phone number" />
                        )}
                        
                        {toNetwork && (
                            <Form.Field
                                name="comment"
                                label="Subject*"
                                placeholder="Choose..."
                                type="multipleRadio"
                                options={connectedNetworkList}
                            />
                        )}
                        
                        {toPartner && (
                            <Form.Field
                                name="comment"
                                label="Subject*"
                                type="multipleRadio"
                                placeholder="Choose..."
                                options={partnersList}
                            />
                        )}

                        {!toNetwork && !toPartner && documentUpload && (
                            <Form.FieldContainer>
                                <Form.Label htmlFor="file" >Add your CV</Form.Label>
                                <input id="file" name="file" type="file" onChange={(event) => {
                                    if (event.currentTarget.files!.length > 0) {
                                        onFileUpload(event, 'cv').then(
                                            fileId => setFieldValue('cv', fileId)
                                        );                             
                                    } else {
                                        setFieldValue('file', null)
                                    }
                                }} />
                            </Form.FieldContainer>
                        )}
                   
                        {!toNetwork && !toPartner && (
                            <Fragment>
                                {!documentUpload &&
                                    <Form.Field name="subject" label="Subject*" placeholder="Your subject"/>
                                }
                                <Form.Field name="comment" type="textarea" label="Message*" placeholder="Your message" />
                            </Fragment>
                        )}
                        <ReCAPTCHAContainer>
                            <ReCAPTCHA
                                sitekey={process.env.RAZZLE_RECAPTCHA_SITEKEY || ''}
                                onChange={response => setFieldValue('recaptcha', response)}
                                theme={isDark ? 'dark' : 'light'}
                            />
                            {errors.recaptcha && touched.recaptcha && <Form.Error>{errors.recaptcha}</Form.Error>}
                        </ReCAPTCHAContainer>
                        <DefaultButton
                            to="#"
                            colorScheme={isDark ? 'colored' : 'dark'}
                            onClick={e => {
                                e.preventDefault();
                                submitForm();
                            }}
                            disabled={isSubmitting || !isValid}
                        >
                            SEND
                        </DefaultButton>
                    </Fragment>
                )}
            </FormikForm>
        </FormContainer>
    );
};

const FormContainer = styled(Intro.Container)`
    padding-top: 0;
    padding-bottom: 6rem;

    textarea {
        resize: none;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding-top: 2.2rem;
    }
`;

const FormError = styled.div`
    margin-bottom: 2rem;
`;

const ReCAPTCHAContainer = styled.div`
    margin-bottom: 3.2rem;
`;

const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const input: any = event.target as HTMLInputElement;
    const file = input.files[0];
    const formData = new FormData();

    // Theres a file so it will add that
    formData.append('file', file);

    try {
        // POST file to endpoint
        const response = await fetch(uploadCandidateAsset(), {
            method: 'POST',
            body: formData,
        });

        // Parse id (get first number in string)
        const responseText = await response.text();
        const fileId : string = /^\d*/.exec(responseText)![0];

        // Setting field value is case the response is correct
        if (fileId) {
            return fileId ? Number(fileId) : undefined;
        } else {
            throw new Error('incorrect response from server');
        }
    } catch (error) {
        console.error(error); // tslint:disable-line no-console
    }

    return null
};

const handleSubmit = async (
    { fullName, company, emailAddress, phoneNumber, comment, recaptcha, subject, cv }: Values,
    { props, setSubmitting, setStatus }: FormikBag<Props, Values>
) => {
    const matchedRoute = matchRoute<ExchangeParams>(location.pathname);
    const site: Site | undefined = sites.find(
        current => !!matchedRoute && current.handle === matchedRoute.match.params.exchange
    );
    const siteId = site ? site.id : sites[0].id;
    const siteLocation = site ? site.title : sites[0].title;

    if (props.documentUpload && cv === null) {
        subject = 'Contact Us: Careers';
    }

    try {
        if (!props.toPartner && !props.toNetwork && props.sendContactRequest && !props.documentUpload) {
            const { data } = await props.sendContactRequest({
                variables: {
                    siteId,
                    emailAddress,
                    fullName,
                    company,
                    phoneNumber,
                    subject,
                    comment,
                    contactFormId: props.contactBlockId,
                    recaptcha,
                    siteLocation,
                },
            });

            if (data && data.upsertContactRequestInternal && !data.upsertContactRequestInternal.mailSent) {
                return setStatus({
                    type: 'error',
                    value: 'Failed to send message to recipient, try again later.',
                });
            }
        } else if (!props.toPartner && !props.toNetwork && props.documentUpload) {
            if (props.sendContactRequestDocument && cv !== null) {
                const { data } = await props.sendContactRequestDocument({
                    variables: {
                        siteId,
                        emailAddress,
                        fullName,
                        company,
                        phoneNumber,
                        comment,
                        cv,
                        contactFormId: props.contactBlockId,
                        recaptcha,
                        siteLocation,
                    },
                });

                if (data && data.upsertContactRequestDocument && !data.upsertContactRequestDocument.mailSent) {
                    return setStatus({
                        type: 'error',
                        value: 'Failed to send message to recipient, try again later.',
                    });
                }
            } 
            
            if (props.sendContactRequest && cv === null){
                const { data } = await props.sendContactRequest({
                    variables: {
                        siteId,
                        emailAddress,
                        fullName,
                        company,
                        phoneNumber,
                        subject,
                        comment,
                        contactFormId: props.contactBlockId,
                        recaptcha,
                        siteLocation,
                    },
                });

                if (data && data.upsertContactRequestInternal && !data.upsertContactRequestInternal.mailSent) {
                    return setStatus({
                        type: 'error',
                        value: 'Failed to send message to recipient, try again later.',
                    });
                }
            }
        } else if (!!props.toPartner && props.sendContactRequestToPartner) {
            const { data } = await props.sendContactRequestToPartner({
                variables: {
                    siteId,
                    emailAddress,
                    fullName,
                    company,
                    phoneNumber,
                    comment,
                    partner: [props.toPartner],
                    recaptcha,
                    siteLocation,
                },
            });

            if (data && data.upsertContactRequestPartner && !data.upsertContactRequestPartner.mailSent) {
                return setStatus({
                    type: 'error',
                    value: 'Failed to send message to recipient, try again later.',
                });
            }
        } else if (!!props.toNetwork && props.sendContactRequestToNetwork) {
            const { data } = await props.sendContactRequestToNetwork({
                variables: {
                    siteId,
                    emailAddress,
                    fullName,
                    company,
                    phoneNumber,
                    comment,
                    connectedNetwork: [props.toNetwork],
                    recaptcha,
                    siteLocation,
                },
            });

            if (data && data.upsertContactRequestNetwork && !data.upsertContactRequestNetwork.mailSent) {
                return setStatus({
                    type: 'error',
                    value: 'Failed to send message to recipient, try again later.',
                });
            }
        }

        trackSubmitFormEvent();

        setStatus({
            type: 'success',
        });
    } catch (ex) {
        setStatus({
            type: 'error',
            value: ex.toString(),
        });
    } finally {
        setSubmitting(false);
    }
};

const enhance = compose<OutProps, InProps>(
    branch(
        (props: InProps) => !props.toPartner && !props.toNetwork && props.documentUpload === false,
        graphql(CONTACT_REQUEST_MUTATION, {
            name: 'sendContactRequest',
        })
    ),
    branch(
        (props: InProps) => !props.toPartner && !props.toNetwork && props.documentUpload === true,
        graphql(CONTACT_REQUEST_MUTATION_DOCUMENT, {
            name: 'sendContactRequestDocument',
        })
    ),
    branch(
        (props: InProps) => !!props.toPartner,
        graphql(CONTACT_TO_PARTNER_REQUEST_MUTATION, {
            name: 'sendContactRequestToPartner',
        })
    ),
    branch(
        (props: InProps) => !!props.toNetwork,
        graphql(CONTACT_TO_NETWORK_REQUEST_MUTATION, {
            name: 'sendContactRequestToNetwork',
        })
    ),
    graphql(CONTACT_REQUEST_MUTATION, {
        name: 'sendContactRequest',
    }),
    withFormik<Props, Values>({
        validationSchema: object().shape({
            emailAddress: string()
                .required('Please enter an email address')
                .email('Please enter a valid email address'),
            fullName: string().required('Please enter your name'),
            company: string().required('Please enter your company name'),
            comment: string().required('Please enter a question or comment'),
            recaptcha: string()
                .nullable(true)
                .required('Please complete the reCAPTCHA'),
        }),
        handleSubmit,
        mapPropsToValues: () => ({
            emailAddress: '',
            fullName: '',
            company: '',
            subject: '',
            phoneNumber: '',
            comment: '',
            recaptcha: '',
            cv: null,
        }),
    })
);

export default enhance(ContactForm);
