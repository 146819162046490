import React from 'react';
import styled from '../../../../styled-components';
import Container from '../../../atoms/Container';
import { MenuColumnFragment, MenuFragment, MenuItemFragment } from '../../../../entities/operationResults';
import { NavColumn, NavHeading, NavItem, NavList, NavLink } from '../../../atoms/NavItems';
import { getEntriesOfType } from '../../../../services/entryTypes';
import LinkIconRight from '../../../atoms/icons/LinkIconRight';

interface Props {
    menuSubs: MenuFragment;
    setVisible: (visible: boolean) => void;
    reverse?: boolean;
}

function getMenuItemsLayout(menuColumn: MenuColumnFragment): MenuItemFragment[] {
    return getEntriesOfType('Menu', menuColumn.children);
}

const DropdownNav = ({ menuSubs, setVisible, reverse }: Props) => {
    if (!menuSubs) {
        return null;
    }

    const menuColumns: MenuColumnFragment[] = getEntriesOfType('Menu', menuSubs.children);
    return (
        <Container paddingTop={'3.2rem'} paddingBottom={'4rem'} maxWidth>
            <NavWrap>
                {menuColumns.map((menuColumn, index) => {
                    if (!menuColumn) {
                        return null;
                    }

                    return(
                        <NavColumn key={index} reverse={reverse}>
                            <NavHeading>{menuColumn.title}</NavHeading>
                            <NavList column>
                                {getMenuItemsLayout(menuColumn).map((menuItem) => {
                                    const menuItemLink = menuItem.externalLink
                                        ? menuItem.externalLink
                                        : menuItem.linkEntry && menuItem.linkEntry[0]
                                            ? '/' + menuItem.linkEntry[0]!.uri
                                            : null;
                                    return(
                                        menuItemLink &&
                                        <NavItem key={menuItem.id}>
                                            {
                                                menuItem.externalLink ?
                                                    <NavExternalLink href={ menuItemLink } target={ '_blank' }>
                                                        {menuItem.title}
                                                        <LinkIconRight/>
                                                    </NavExternalLink>
                                                    :
                                                    <NavLink
                                                        to={ menuItemLink }
                                                        onClick={() => { setVisible(false); }}
                                                    >
                                                        {menuItem.title} <LinkIconRight/>
                                                    </NavLink>
                                            }
                                        </NavItem>
                                    );
                                })}
                            </NavList>
                        </NavColumn>
                    );
                })}
            </NavWrap>
        </Container>
    );
};

const NavExternalLink = styled.a<{ 'data-active'?: boolean }>`
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ 'data-active': active, theme }) => (active ? theme.colors.tango : 'inherit')};

    svg {
        margin-left: 0.7rem;
    }

    &:hover {
        color: ${props => props.theme.colors.tango};
    }
`;

const NavWrap = styled.div`
    display:flex;
    flex-wrap: nowrap;
`;

export default DropdownNav;
