import React, { Fragment, SFC } from 'react';
import Hero from './organisms/Hero';
import Services from './organisms/Services';
import RelatedStories from './organisms/RelatedStories';
import LatestNews from './organisms/LatestNews';
import Events from './organisms/Events';
import ContactUs from './organisms/ContactUs';
import ColocationsAndNetworks from './organisms/ColocationsAndNetworks';
import gql from 'graphql-tag';
import { DataProps, graphql } from 'react-apollo';
import { HomeQuery as Response } from '../entities/operationResults';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router';
import { ExchangeParams } from './Exchange';
import { path } from 'ramda';
import ContactSlideover from './organisms/ContactSlideover';
import withSpinner from '../hocs/withSpinner';
import ComingSoon from './molecules/ComingSoon';
import comingSoonHandles from '../constants/comingSoonHandles';

const GET_HOME_QUERY = gql`
    query Home ($site: String!) {
        exchangePage: entry(site:$site, type:ExchangePage) {
            ... on ExchangePage {
                contactFormText {
                    content
                }
                contactBlockCtaText {
                    content
                }
            }
        }
    }
`;

type Props = DataProps<Response> & RouteComponentProps<ExchangeParams>;

const Home: SFC<Props> = ({ data }) => {
    const contactFormText = path<string>(['exchangePage', 'contactFormText', 'content'], data || {});
    const contactBlockCtaText = path<string>(['exchangePage', 'contactBlockCtaText', 'content'], data || {});

    return (
        <Fragment>
            {comingSoonHandles.includes(data.variables.site) &&
                <ComingSoon/>
            }
            {!comingSoonHandles.includes(data.variables.site) &&
                <Fragment>
                    <Hero />
                    <ColocationsAndNetworks />
                    <Services />
                    <RelatedStories />
                    <LatestNews />
                    <Events />
                </Fragment>
            }
            <ContactUs slideover={ContactSlideover} contactFormText={contactFormText} ctaText={contactBlockCtaText} />
        </Fragment>
    );
};

const enhance = compose(
    withRouter,
    graphql(GET_HOME_QUERY, {
        options: ((props: Props) => ({
            variables: {
                site: props.match.params.exchange
            }
        }))
    }),
    withSpinner
);
export default enhance(Home);
