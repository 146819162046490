import gql from 'graphql-tag';
import React, { SFC } from 'react';
import styled from '../../styled-components';
import { DropdownStatsCompleteFragment, TrafficGraphContentBlockFragment } from '../../entities/operationResults';
import Container from '../atoms/Container';
import ContentBody from '../atoms/ContentBody';
import ReactScroll from 'react-scroll';
import TrafficGraph from './TrafficGraph';
import { getEntriesOfType } from '../../services/entryTypes';

export const fragment = gql`
    fragment TrafficGraphContentBlock on ContentBlocksTrafficGraph {
        id
        dataset {
            ...DropdownStatsComplete
        }
    }

    fragment DropdownStatsComplete on Graph {
        title
        interval
        peakIn
        peakOut
        averageIn
        averageOut
        currentIn
        currentOut
        averageInputData {
            time
            value
        }
        averageOutputData {
            time
            value
        }
        peakOutputData {
            time
            value
        }
        peakInputData {
            time
            value
        }
    }
`;

interface Props {
    contentBlock: TrafficGraphContentBlockFragment;
}

const TrafficGraphContentBlock: SFC<Props> = ({ contentBlock: { id, dataset } }) => {
    const stats: DropdownStatsCompleteFragment[] | null = getEntriesOfType('Graph', dataset);

    return (
        <Container maxWidth paddingBottom={'6rem'} paddingBottomL={'9.6rem'}>
            <Block name={`section-${id}`}>
                <TrafficGraph stats={stats[0]} />
            </Block>
        </Container>
    );
};

const Block = styled(ReactScroll.Element)`
    box-sizing: border-box;
    
    ${ContentBody} p:last-child {
      margin-bottom: 0;
    }
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        max-width: 900px;
        margin: 0 auto;
        
        > h2 {
            max-width: 60%;
        }
    }
`;

export default TrafficGraphContentBlock;