import { NavLink as RouterNavLink, NavLinkProps as RouterNavLinkProps } from 'react-router-dom';
import styled from '../../styled-components';

interface NavColumnProps {
    reverse?: boolean;
}

export const NavColumn = styled.div<NavColumnProps>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    
    ${({ reverse }) => {
        return reverse
            ? `margin-left: auto;`
            : ``;
    }}
    
    position: relative;
    width: 33.33%;
    padding: 0 2.4rem 0 0;
    margin-right: 3.2rem;

    &:after {
        content: '';
        position: absolute;
        display: block;
        background: ${props => props.theme.colors.transparentGrey};
        top: 0;
        right: 0;
        bottom: 0;
        width: 1px;
    }

    &:last-child:after {
        content: unset;
    }
`;

export const NavHeading = styled.h2`
    font: ${props => props.theme.fonts.small.smallCaps};
    text-transform: uppercase;
    color: ${props => props.theme.colors.dustyGray};
`;

interface NavListProps {
    column?: boolean;
}

export const NavList = styled.ul<NavListProps>`
    list-style: none;
    padding:0;
    margin:0;
    display: flex;

    ${({ column }) => {
        return column
            ? `
            flex-direction: column;
        `
            : ``;
    }}

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        min-width: 22rem;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        min-width: 32rem;
    }
`;

export const NavItem = styled.li`
    display: inline-block;
    font-family: chalet-new-york-sixty;
    font-size: 1.6rem;
    line-height: 2.2rem;
    position: relative;
    margin-bottom: 1.3rem
    margin-right: 2.4rem;

    svg {
        overflow: visible;
        margin-left: 0.6rem;
    }
`;

export const NavMainItem = styled(NavItem)`
    font-size: 1.2rem;
    margin-bottom: 0rem

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        font-size: 1.4rem;
        margin-right: 2.8rem;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xxl}) {
        font-size: 1.6rem;
        margin-right: 4.1rem;
    }
`;

export const NavLink = styled(RouterNavLink)<RouterNavLinkProps & { 'data-active'?: boolean }>`
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ 'data-active': active, theme }) => (active ? theme.colors.tango : 'inherit')};

    svg {
        margin-left: 0.7rem;
    }

    &:hover {
        color: ${props => props.theme.colors.tango};
    }
`;
