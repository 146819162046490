import gql from 'graphql-tag';
import { path } from 'ramda';
import React, { Fragment, SFC } from 'react';
import { DataProps, graphql } from 'react-apollo';
import { Img } from 'react-image-loading';
import { RouteComponentProps, withRouter } from 'react-router';
import generatePath from 'react-router-dom/generatePath';
import Scroll from 'react-scroll';
import { branch, compose, lifecycle, renderComponent, withState } from 'recompose';

import { getStartedRoute } from '../constants/routes';
import {
  ServicePageFragment,
  ServiceQuery,
  ServiceQueryVariables,
  ShowcaseStoryFragment,
} from '../entities/operationResults';
import withSpinner from '../hocs/withSpinner';
import { getEntryOfType, getRelatedItems } from '../services/entryTypes';
import styled, { ThemeProps, withTheme } from '../styled-components';
import DefaultButton from './atoms/buttons/DefaultButton';
import Container from './atoms/Container';
import ContentBody from './atoms/ContentBody';
import ContentTitle from './atoms/ContentTitle';
import Meta from './atoms/Meta';
import Separator from './atoms/Separator';
import { ExchangeParams } from './Exchange';
import * as Intro from './molecules/Intro';
import ServiceBenefits, { serviceBenefitsFragment } from './molecules/ServiceBenefits';
import StoryShowcase, { storyShowcaseFragment } from './molecules/StoryShowcase';
import NotFound from './NotFound';
import ContactSlideover from './organisms/ContactSlideover';
import ContactUs from './organisms/ContactUs';
import PageNav, { MenuItem } from './organisms/PageNav';
import ServiceSlideover from './organisms/ServiceSlideover';
import RelatedDetail, { relatedFragments } from './organisms/RelatedDetail';
import PricingTable, {CommitmentModuleSpeedBlock} from "./Pricing";

export const SERVICE_NAV_WIDTH = 125;
const EASY_ACCESS_TITLE = "EasyAccess";

const GET_SERVICE_QUERY = gql`
    query Service($exchange: String!, $slug: String!) {
        service: entry(type: Service, site: $exchange, slug: $slug) {
            ...ServicePage
        }
    }

    fragment ServicePage on Service {
        id
        title
        howItWorks {
            content
        }
        description
        pricing {
            content
        }
        showDurationSwitch
        commitmentModule {
            ... on CommitmentModuleSpeedBlock {
                blockTitle
                price3Months
                price12Months
                price24Months
                price36Months
                checkmarkList {
                    listItem
                }
            }
        }
        icon {
            src: url(transform: serviceLogo)
            width: width(transform: serviceLogo)
            src2x: url(transform: serviceLogo2x)
            width2x: width(transform: serviceLogo2x)
            title
        }
        benefits {
            ...ServicePageBenefit
        }
        storyShowcase {
            ...ShowcaseStory
        }
        contactBlockCtaText {
            content
        }
        contactFormText {
            content
        }
        relatedItems {
            ...Related
        }
    }

    ${relatedFragments}
    ${serviceBenefitsFragment}
    ${storyShowcaseFragment}
`;

export interface ServiceParams extends ExchangeParams {
    slug: string;
}

type Props = DataProps<ServiceQuery, ServiceQueryVariables> &
    RouteComponentProps<ServiceParams> &
    ThemeProps & {
        isModalOpen: boolean;
        setIsModalOpen: (isModalOpen: boolean) => void;
    };

const menu: MenuItem[] = [
    {
        id: 'intro',
        title: 'Introduction',
    },
    {
        id: 'how-it-works',
        title: 'How it works',
    },
    {
        id: 'benefits',
        title: 'Benefits',
    },
    {
        id: 'pricing',
        title: 'Pricing',
    },
];

const Service: SFC<Props> = ({ data, theme, isModalOpen, setIsModalOpen, match }) => {
    const service: ServicePageFragment | null = getEntryOfType('Service', data.service);
    if (!service) {
        return null;
    }
    const showcaseStory: ShowcaseStoryFragment | null = getEntryOfType(
        'Story',
        service.storyShowcase && service.storyShowcase[0],
    );
    const alt = path<string>(['icon', 0, 'title'], service);
    const icon = path<string>(['icon', 0, 'src'], service);
    const icon2x = path<string>(['icon', 0, 'src2x'], service);
    const howItWorks = path<string>(['howItWorks', 'content'], service);
    const pricing = path<string>(['pricing', 'content'], service);
    const showDurationSwitch = path<boolean>(['showDurationSwitch'], service);
    const commitmentModule = path<CommitmentModuleSpeedBlock[]>(['commitmentModule'], service);
    const relatedItems = getRelatedItems(service.relatedItems);

    console.log(commitmentModule)

    return (
        <Fragment>
            <Meta title={service.title} description={service.description} image={icon} />

            <ServiceContainerDark maxWidth>
                <ServiceHero>
                    <ServiceNav menu={menu} />

                    <ServiceContent>
                        <Scroll.Element name="intro">
                            <ServiceSection>
                                {icon && (
                                    <ServiceIconWrapper>
                                        <ServiceIconMobile alt={alt} src={icon} srcSet={`${icon2x} 2x, ${icon} 1x`} />
                                    </ServiceIconWrapper>
                                )}
                                <ServiceTitle>{service.title}</ServiceTitle>
                                <ServiceSeparator />
                                {service.description && <Intro.Text>{service.description}</Intro.Text>}
                            </ServiceSection>
                        </Scroll.Element>

                        <Scroll.Element name="how-it-works">
                            {howItWorks && (
                                <ServiceSection>
                                    <ServiceContentTitle>How it works</ServiceContentTitle>
                                    <ServiceHowTo dangerouslySetInnerHTML={{ __html: howItWorks }} />
                                    {service.title === EASY_ACCESS_TITLE ?
                                        <DefaultButton colorScheme={'light'} to={'#'} onClick={() => setIsModalOpen(true)}>
                                            See locations
                                        </DefaultButton> 
                                        :   
                                        <DefaultButton colorScheme={'light'} to={'#'} onClick={() => setIsModalOpen(true)}>
                                            Learn more
                                        </DefaultButton>
                                    }
                                </ServiceSection>
                            )}
                        </Scroll.Element>
                    </ServiceContent>
                    {icon && (
                        <ServiceIconWrapper>
                            <ServiceIcon alt={alt} src={icon} srcSet={`${icon2x} 2x, ${icon} 1x`} />
                        </ServiceIconWrapper>
                    )}
                </ServiceHero>
            </ServiceContainerDark>
            <BenefitsContainerDark maxWidth>
                {service.benefits && <ServiceBenefits benefits={service.benefits} serviceTitle={service.title} />}
            </BenefitsContainerDark>
            {showcaseStory && <StoryShowcase story={showcaseStory} />}
            <ServiceContainer
                id="pricing"
                maxWidth
                paddingBottomL="10rem"
                paddingBottom="6rem"
                paddingTopL="10rem"
                paddingTop="6rem"
            >
                <LowerServiceContent>
                    <Scroll.Element name="pricing">
                        <ContentTitle>Pricing</ContentTitle>
                        {pricing && <Table dangerouslySetInnerHTML={{ __html: pricing }} />}
                    </Scroll.Element>
                    {commitmentModule && commitmentModule.length && <ServiceContainer
                        id="commitment"
                    >
                        <LowerServiceContent>
                            <Scroll.Element name="commitmentModule">
                                <PricingTable showDurationSwitch={!!showDurationSwitch} data={commitmentModule} />
                            </Scroll.Element>
                        </LowerServiceContent>
                    </ServiceContainer>}
                    <ContactUs
                        slideover={ContactSlideover}
                        contactFormText={path<string>(['contactFormText', 'content'], service)}
                        ctaText={path<string>(['contactBlockCtaText', 'content'], service)}
                    />
                </LowerServiceContent>
            </ServiceContainer>
            {relatedItems && relatedItems.length > 0 && <RelatedDetail relatedItems={relatedItems} />}
            <ServiceSlideover
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                getStartedPath={generatePath<ExchangeParams>(getStartedRoute.path, {
                    exchange: match.params.exchange,
                })}
            />
        </Fragment>
    );
};

const enhance = compose(
    withTheme,
    withRouter,
    graphql(GET_SERVICE_QUERY, {
        options: ({ match }: Props) => ({
            variables: match.params,
        }),
    }),
    lifecycle<Props, {}, { jump: (this: { props: Props }) => void }>({
        jump() {
            if (typeof window === 'undefined') {
                return;
            }

            // we're using the global location object here because pushing changes to
            // the history don't trigger the jump for whatever reason
            const { hash } = this.props.location;
            setTimeout(() => {
                // need to clear hash in order to trigger jump...
                window.location.hash = '';
                window.location.hash = hash;
            });
        },

        componentDidMount() {
            if (this.props.location.hash && typeof window !== 'undefined') {
                this.jump();
            }
        },

        componentDidUpdate(prev: Props) {
            const service: ServicePageFragment | null = getEntryOfType('Service', this.props.data.service);
            const nextService: ServicePageFragment | null = getEntryOfType('Service', prev.data.service);
            const shouldTriggerJump =
                (service && nextService && service.id !== nextService.id) ||
                (!this.props.data.loading && prev.data.loading !== this.props.data.loading);
            if (shouldTriggerJump && this.props.location.hash) {
                this.jump();
            }
        },
    }),
    withSpinner,
    branch(({ data }: Props) => !data.service, renderComponent(NotFound)),
    withState('isModalOpen', 'setIsModalOpen', false),
);

const ServiceNav = styled(PageNav)`
    min-width: 11rem;
    width: 100%;
    padding: 0;
    margin-bottom: 1.6rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        margin-bottom: 4.8rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        position: fixed;
        right: 5rem;
        top: 10rem;
        align-self: flex-start;
        z-index: 5;
        width: ${SERVICE_NAV_WIDTH}px;
    }
`;

const ServiceContent = styled.div`
    flex: 1;
`;

const LowerServiceContent = styled.div`
    width: 100%;
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        flex: 1;
    }
`;

const ServiceHero = styled.div`
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        display: flex;
    }
`;

const ServiceContainer = styled(Container)`
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) and (max-width: ${props =>
            props.theme.mediaQueriesValues.xxl + SERVICE_NAV_WIDTH}px) {
        > div {
            padding-right: 12rem;
        }
    }
`;

const ServiceContainerDark = styled(ServiceContainer)`
    background: ${props => props.theme.colors.mirage};
    color: ${props => props.theme.colors.white};
    padding-top: 0.8rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding-top: calc(50vh - 20rem - 7.2rem);
    }
`;

const BenefitsContainerDark = styled(ServiceContainer)`
    padding-top: 6.4rem;
    padding-bottom: 1.6rem;
    background: ${({ theme }) => theme.colors.vulcan};

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding-top: 12rem;
        padding-bottom: 5.6rem;
    }
`;

const ServiceTitle = styled.h2`
    font: 4.2rem/4.4rem chalet-new-york-sixty, sans-serif;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        font-size: 6rem;
        line-height: 6.4rem;
    }
`;

const ServiceIconWrapper = styled.div`
    margin: 0 auto;
    display: none;
    position: relative;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        position: sticky;
        align-self: flex-start;
        top: calc(50vh - 16rem);
        margin-left: 4rem;
        display: inherit;
        width: 32rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        top: calc(50vh - 20rem);
        width: 40rem;
        margin-left: 8rem;
        margin-bottom: 10.4rem;
    }
`;

const ServiceIcon = styled(Img)`
    width: 100%;
`;

const ServiceIconMobile = styled(ServiceIcon)`
    display: block;
    width: 28rem;
    margin-bottom: 2rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        display: none;
    }
`;

const ServiceSection = styled.div`
    padding-bottom: 6.4rem;
    max-width: 70rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding-bottom: 13.2rem;
    }
`;
const ServiceContentTitle = styled(ContentTitle)`
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        margin-bottom: 2.4rem;
    }
`;
const ServiceHowTo = styled.div`
    margin-bottom: 2.4rem;
    max-width: 70rem;

    p {
        font: ${props => props.theme.fonts.small.contentBody};
    }
`;

const Table = styled(ContentBody)`
    overflow-y: auto;
`;

export const ServiceSeparator = styled(Separator).attrs(props => ({
    color: props.theme.colors.tango,
}))`
    height: 3px;
    width: 10%;
`;

export default enhance(Service);
