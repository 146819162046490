export interface ThemeInterface {
    colors: {
        tango: string,
        tangoTransparent: string,
        christine: string,
        bamboo: string,
        blazeOrange: string,
        yellowOrange: string,
        mustard: string,
        crayola: string,
        black: string,
        codGray: string,
        mirage: string,
        vulcan: string,
        shuttleGray: string,
        baliHai: string,
        dustyGray: string,
        mystic: string,
        aquaHaze: string,
        alto: string,
        seashellPeach: string,
        white: string,
        transparentGrey: string;
    };
    fonts: {
        small: {
            exchangePicker: string,
            storyTitle: string;
            // from here it's okish
            sectionHeaderTitle: string;
            textLink: string;
            sectionItemTitle: string;
            sectionItemSubtitle: string;
            contactText: string;
            contentTitle: string;
            contentBody: string;
            introTitle: string;
            introText: string;
            smallCaps: string;
            registerSubtitle: string;
        },
        medium: {
            exchangePicker: string,
            // from here it's okish
            sectionHeaderTitle: string;
            textLink: string;
            sectionItemTitle: string;
            sectionItemSubtitle: string;
            contactText: string;
            contentTitle: string;
            introTitle: string;
        },
        large: {
            contentBody: string,
            storySubtitle: string,
            storyTitle: string,
            exchangePicker: string,
            smallCaps: string,
            eventDate: string,
            eventTitle: string,
            // from here it's okish
            sectionHeaderTitle: string;
            textLink: string;
            sectionItemTitle: string;
            sectionItemSubtitle: string;
            contactText: string;
            contentTitle: string;
            introTitle: string;
            introText: string;
            filterTitle: string;
            registerSubtitle: string;
        }
    };
    mediaQueries: {
        xs: string,
        s: string,
        m: string,
        l: string,
        xl: string,
        xxl: string,
    };
    mediaQueriesValues: {
        xs: number,
        s: number,
        m: number,
        l: number,
        xl: number,
        xxl: number,
    };
    maxWidths: {
        xs: string,
        s: string,
        m: string,
        l: string,
        xl: string,
        xxl: string,
    };
}

const theme: ThemeInterface = {
    colors: {
        tango: '#f47f22',
        tangoTransparent: 'rgba(244, 127, 34, 0.3)',
        christine: '#f2720a',
        bamboo: '#de5c00',
        blazeOrange: '#ff6a00',
        yellowOrange: '#ffa53a',
        mustard: '#FFCA57',
        crayola: '#FFED8A',
        black: '#000',
        codGray: '#1a1a1a',
        mirage: '#17222a',
        vulcan: '#121821',
        shuttleGray: '#566778',
        baliHai: '#8c9bab',
        dustyGray: '#9B9B9B',
        mystic: '#e7ecef',
        aquaHaze: '#f7f9fa',
        alto: '#D8D8D8',
        seashellPeach: '#fff4eb',
        white: '#fff',
        transparentGrey: 'rgba(23, 34, 42, 0.1)'
    },
    fonts: {
        small: {
            exchangePicker: '3.2rem/3.6rem chalet-london-sixty, sans-serif',
            smallCaps: '600 1rem/1.2rem urw-din, sans-serif',
            storyTitle: '400 1.1rem/1.6rem urw-din, sans-serif',
            sectionHeaderTitle: '2.1rem/2.4rem chalet-london-sixty, sans-serif',
            sectionItemTitle: '2.1rem/2.4rem chalet-new-york-sixty, sans-serif',
            sectionItemSubtitle: '2.1rem/2.4rem chalet-london-sixty, sans-serif',
            textLink: '600 1.1rem/1.2rem urw-din, sans-serif',
            contactText: '2.2rem/3.2rem chalet-london-sixty, sans-serif',
            contentTitle: '2.4rem/3.2rem chalet-new-york-sixty, sans-serif',
            contentBody: '1.5rem/2.4rem chalet-london-sixty, sans-serif',
            introTitle: '3.6rem/3.6rem chalet-new-york-sixty, sans-serif',
            introText: '1.6rem/2.8rem chalet-london-sixty, sans-serif',
            registerSubtitle: '2.4rem/3.2rem chalet-new-york-sixty, sans-serif',
        },
        medium: {
            exchangePicker: '5.12rem/5.12rem chalet-london-sixty, sans-serif',
            sectionHeaderTitle: '2.1rem/2.4rem chalet-london-sixty, sans-serif',
            sectionItemTitle: '2.1rem/2.4rem chalet-new-york-sixty, sans-serif',
            sectionItemSubtitle: '2.1rem/2.4rem chalet-london-sixty, sans-serif',
            textLink: '600 1.2rem/1.2rem urw-din, sans-serif',
            contactText: '3.5rem/4.2rem chalet-london-sixty, sans-serif',
            contentTitle: '2.4rem/3.2rem chalet-new-york-sixty, sans-serif',
            introTitle: '4.8rem/4.8rem chalet-new-york-sixty, sans-serif',
        },
        large: {
            contentBody: '1.6rem/2.6rem chalet-london-sixty, sans-serif',
            storySubtitle: '1.6rem/2.4rem chalet-new-york-sixty, sans-serif',
            storyTitle: '400 1.2rem/2.4rem urw-din, sans-serif',
            exchangePicker: '5.6rem/6rem chalet-london-sixty, sans-serif',
            smallCaps: '600 1.1rem/1.6rem urw-din, sans-serif',
            eventDate: '600 1.2rem/3.6rem urw-din, sans-serif',
            eventTitle: '2.6rem/3.6rem chalet-new-york-sixty, sans-serif',
            sectionHeaderTitle: '2.6em/3.6rem chalet-london-sixty, sans-serif',
            sectionItemTitle: '2.6rem/3.6rem chalet-new-york-sixty, sans-serif',
            sectionItemSubtitle: '2.6rem/3.6rem chalet-london-sixty, sans-serif',
            textLink: '600 1.2rem/1.2rem urw-din, sans-serif',
            contactText: '4rem/4.8rem chalet-london-sixty, sans-serif',
            contentTitle: '3.2rem/3.2rem chalet-new-york-sixty, sans-serif',
            introTitle: '5.6rem/5.6rem chalet-new-york-sixty, sans-serif',
            introText: '2rem/3.6rem chalet-london-sixty, sans-serif',
            filterTitle: '1.6rem/2rem chalet-new-york-sixty, sans-serif',
            registerSubtitle: '3rem/3.6rem chalet-new-york-sixty, sans-serif',
        }
    },
    mediaQueries: {
        xs: '480px',
        s: '768px',
        m: '992px',
        l: '1200px',
        xl: '1440px',
        xxl: '1660px'
    },
    mediaQueriesValues: {
        xs: 400,
        s: 768,
        m: 992,
        l: 1200,
        xl: 1440,
        xxl: 1660
    },
    maxWidths: {
        xs: '464',
        s: '636',
        m: '960',
        l: '1152',
        xl: '1344',
        xxl: '1440',
    }
};

export default theme;
