import React, { Fragment, SFC, useState, useEffect } from 'react';
import { ThemeProps, withTheme } from '../styled-components';
import Meta from './atoms/Meta';
import CollectionIntro from './organisms/collections/CollectionIntro';
import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import { DataProps, graphql } from 'react-apollo';
import { compose, withState } from 'recompose';
import Container from './atoms/Container';
import { ExchangeParams } from './Exchange';
import {
    AllCollectionsQuery as Response,
    AllCollectionsQueryVariables,
    EntryTypesEnum,
    EventCollectionItemFragment,
    EventCollectionPageFragment,
    FeaturedStoryCollectionItemFragment,
    NewsCollectionItemFragment,
    NewsletterCollectionItemFragment,
    NewsCollectionPageFragment,
    StoriesCollectionPageFragment,
    StoryCollectionItemFragment
} from '../entities/operationResults';
import { fragments as CollectionFragments } from './organisms/collections/CollectionItem';
import CollectionFeaturedItems, { fragments as CollectionFeaturedFragments } from './organisms/collections/CollectionFeaturedItems';
import { getEntriesOfType, getEntryOfType } from '../services/entryTypes';
import CollectionList from './organisms/collections/CollectionList';
import ContentBody from './atoms/ContentBody';
import { eventsRoute, newsRoute, newsletterRoute } from '../constants/routes';
import CollectionPagination from './organisms/collections/CollectionPagination';
import IntroTitle from './atoms/IntroTitle';
import TabButton from './atoms/buttons/TabButton';
import TabButtonLink from './atoms/buttons/TabButtonLink';
import withSpinner from '../hocs/withSpinner';
import { path } from 'ramda';
import queryString from 'query-string';
import { startOfDay } from 'date-fns';
import styled from '../styled-components';

const GET_COLLECTIONS_QUERY = gql`
    query AllCollections ($site: String!, $type: [EntryTypesEnum], $collectionType: [EntryTypesEnum], $offset: Int!, $itemLimit: Int!, $order: String, $listExpiryDate: String, $listInPastEvents: Boolean) {
        collectionItems: entriesConnection(site:$site, offset:$offset, limit:$itemLimit, type:$type, orderBy:$order, listExpiryDate:$listExpiryDate, listInPastEvents: $listInPastEvents) {
            entries{
                ...EventCollectionItem,
                ...NewsCollectionItem,
                ...NewsletterCollectionItem,
                ...StoryCollectionItem
            }
            totalCount
            pageInfo {
                totalPages
            }
        }
        collection: entry(site:$site, type:$collectionType) {
            ...EventCollectionPage,
            ...NewsCollectionPage,
            ...NewsletterCollectionPage,
            ...StoriesCollectionPage
        }
    }
    fragment EventCollectionPage on EventCollection {
        introHeading
        introText {
            content
        }
    }
    fragment NewsCollectionPage on NewsCollection{
        introHeading
        introText {
            content
        }
    }
    fragment NewsletterCollectionPage on NewsletterCollection{
        introHeading
        introText {
            content
        }
    }
    fragment StoriesCollectionPage on StoryCollection{
        introHeading
        introText {
            content
        }
        featuredStories{
            ...FeaturedStoryCollectionItem
        }
    }
    ${CollectionFragments.event}
    ${CollectionFragments.newsletter}
    ${CollectionFragments.news}
    ${CollectionFragments.story}
    ${CollectionFeaturedFragments.relatedStory}
`;

interface TabProps {
    listInPastEventsProps: any;
    setListPastEvents: any;
}

type Props = DataProps<Response, AllCollectionsQueryVariables> & ThemeProps & RouteComponentProps<ExchangeParams> & TabProps;

const enhance = compose(
    withRouter,
    withTheme,
    withState('currentPage', 'setCurrentPage', 1),
    withState('listInPastEventsProps', 'setListPastEvents', false),
    graphql(GET_COLLECTIONS_QUERY, {
        options: (({ match, location: { search }, listInPastEventsProps }: Props) => {
            const limit: number = 8;
            let offset: number = 0;
            let collectionType: EntryTypesEnum;
            let collectionPageType: EntryTypesEnum;
            let order: string | null = null;
            let listInPastEvents: boolean = false;
            let listExpiryDate: string | null = null;
            const currentPage = parseInt(queryString.parse(search).page, 10) || 1;

            switch (match.path) {
                case eventsRoute.path:
                    if(!listInPastEventsProps) {
                        collectionType = EntryTypesEnum.Event;
                        collectionPageType = EntryTypesEnum.EventCollection;
                        order = 'startDate ASC';
                        listInPastEvents = listInPastEventsProps;
                        listExpiryDate = `>= ${Math.round((+startOfDay(Date.now())) / 1000)}`;
                    } else {
                        collectionType = EntryTypesEnum.Event;
                        collectionPageType = EntryTypesEnum.EventCollection;
                        order = 'startDate ASC';
                        listInPastEvents = listInPastEventsProps;
                        listExpiryDate = ``;
                    }
                    break;
                case newsRoute.path:
                    collectionType = EntryTypesEnum.News;
                    collectionPageType = EntryTypesEnum.NewsCollection;
                    order = 'postDate DESC';
                    break;
                case newsletterRoute.path:
                    collectionType = EntryTypesEnum.Newsletter;
                    collectionPageType = EntryTypesEnum.NewsletterCollection;
                    order = 'newsletterDate DESC';
                    break;
                default:
                    collectionType = EntryTypesEnum.Story;
                    collectionPageType = EntryTypesEnum.StoryCollection;
                    order = 'postDate DESC';
                    break;
            }
            if (currentPage !== 1) {
                offset = (currentPage - 1) * limit;
            }

            return {
                variables: {
                    site: match.params.exchange,
                    type: collectionType,
                    collectionType: collectionPageType,
                    offset,
                    itemLimit: limit,
                    order,
                    listInPastEvents,
                    listExpiryDate
                }
            };
        })
    }),
    withSpinner
);

const Collection: SFC<Props> = ({ data, theme, match, listInPastEventsProps, setListPastEvents }) => {
    let collectionItems: Array<NewsCollectionItemFragment | StoryCollectionItemFragment | EventCollectionItemFragment | NewsletterCollectionItemFragment>;
    let collectionPage: StoriesCollectionPageFragment | EventCollectionPageFragment | NewsCollectionPageFragment | null;

    const [showTab, setShowTab] = useState(false);
    const [tabValue, setTabValue] = useState(false);
    const [first, setFirst] = useState(true);
    const [second, setSecond] = useState(false);

    if (match.path === eventsRoute.path) {
        collectionItems = getEntriesOfType('Event', data.collectionItems && data.collectionItems.entries);
        collectionPage = getEntryOfType('EventCollection', data.collection);
    } else if (match.path === newsRoute.path) {
        collectionItems = getEntriesOfType('News', data.collectionItems && data.collectionItems.entries);
        collectionPage = getEntryOfType('NewsCollection', data.collection);
    } else if (match.path === newsletterRoute.path) {
        collectionItems = getEntriesOfType('Newsletter', data.collectionItems && data.collectionItems.entries);
        collectionPage = getEntryOfType('NewsletterCollection', data.collection);
    } else {
        collectionItems = getEntriesOfType('Story', data.collectionItems && data.collectionItems.entries);
        collectionPage = getEntryOfType('StoryCollection', data.collection);
    }

    const toggle = () => {
        if(tabValue && !listInPastEventsProps) {
            setTabValue(false)
            setFirst(false);
            setSecond(true);
            setListPastEvents(true);  
        } else if (!tabValue && !listInPastEventsProps) {
            setTabValue(true)
            setFirst(false);
            setSecond(true);
            setListPastEvents(true);  
        }
        else {
            setTabValue(true)
            setFirst(true);
            setSecond(false);
            setListPastEvents(false);
        }
    };

    useEffect(() => {
        if (match.path === eventsRoute.path || match.path === newsRoute.path || match.path === newsletterRoute.path) {
            setShowTab(true)

            if (match.path === newsletterRoute.path) {
                setFirst(false);
                setSecond(true);
            }
            if (listInPastEventsProps) {
                setFirst(false);
                setSecond(true);
            }
        }  else {
            setShowTab(false)
        }
    }, []);

    const totalPages = path<number>(['collectionItems', 'pageInfo', 'totalPages'], data || {});
    const title = path<string>(['introHeading'], collectionPage || {});
    const intro = path<string>(['introText', 'content'], collectionPage || {});
    const featuredItems: FeaturedStoryCollectionItemFragment[] | null = collectionPage && collectionPage.__typename === 'StoryCollection' ? getEntriesOfType('Story', collectionPage.featuredStories) : null;
    const leftTabText = match.path === eventsRoute.path ? 'Current events' : 'News';
    const rightTabText = match.path === eventsRoute.path ? 'Past events' : 'Newsletters';
    return (
        <Fragment>
            <Meta title={title} />
            <CollectionIntro
                featuredEntries={featuredItems !== null ?
                    <CollectionFeaturedItems collectionItems={featuredItems} /> : null}
            >
                <div>
                    <IntroTitle
                        color={theme.colors.white}
                    >
                        {title}
                    </IntroTitle>
                    {intro && <ContentBody color={theme.colors.white} dangerouslySetInnerHTML={{ __html: intro }} />}
                </div>
            </CollectionIntro>
            <Container
                maxWidth
                paddingTop={'5.6rem'}
                paddingTopL={'5.6rem'}
                paddingBottom={'4rem'}
                paddingBottomL={'4rem'}
                backgroundColor={theme.colors.white}
            >
            {showTab && match.path === eventsRoute.path &&
                <TabArea>
                    <TabButton text={leftTabText} onClick={() => toggle()} clicked={first}/>
                    <TabButton text={rightTabText} onClick={() => toggle()} clicked={second}/>
                </TabArea>
            }

            {showTab && match.path !== eventsRoute.path && 
                <TabArea>
                    <TabButtonLink onClick={() => toggle()} clicked={first} to={`/${match.params.exchange}/news`}>{leftTabText}</TabButtonLink>
                    <TabButtonLink onClick={() => toggle()} clicked={second} to={`/${match.params.exchange}/newsletter`}>{rightTabText}</TabButtonLink>
                </TabArea>
            }

            <CollectionList collectionItems={collectionItems} />

            {totalPages && totalPages > 1 && (
                <CollectionPagination
                    totalPages={totalPages}
                />
            )}
            </Container>
        </Fragment>
    );
};
const TabArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 6rem;
`;

export default enhance(Collection);
