import withModal from '../../hocs/withModal';
import React, { FC } from 'react';
import Container from '../atoms/Container';
import * as Intro from '../molecules/Intro';
import styled from '../../styled-components';
import ContactFormASN from '../molecules/ContactFormASN';
import { RouteComponentProps, withRouter } from 'react-router';
import { ExchangeParams } from '../Exchange';
import { ContactInfoSocialsFragment, WishlistSlideOverQuery } from '../../entities/operationResults';
import gql from 'graphql-tag';
import ContactInfo, { contactInfoFragment } from '../molecules/ContactInfo';
import { DataProps, graphql } from 'react-apollo';
import { path } from 'ramda';
import { compose } from 'recompose';
import { ModalInProps } from '../../hocs/withModal';

const GET_EXCHANGE_CONTACT = gql`
    query WishlistSlideOver ($site: String!) {
        globals(site: $site) {
            exchangeConfig {
                address
                contactInfo
            }
            socialMedia {
                ...ContactInfoSocials
            }
        }
    }

    ${contactInfoFragment}
`;

interface InProps extends ModalInProps {
    placeholder?: boolean;
}

type OutProps = RouteComponentProps<ExchangeParams> & DataProps<WishlistSlideOverQuery>;

type Props = OutProps;

const WishlistSlideover: FC<Props> = ({ data }) => {
    const contactInfo = path<string>(['globals', 'exchangeConfig', 'contactInfo'], data);
    const address = path<string>(['globals', 'exchangeConfig', 'address'], data);
    const socials = path<ContactInfoSocialsFragment>(['globals', 'socialMedia'], data);
    return (
        <Wrapper maxWidth>
            <FirstColumn>
                <Intro.Container>
                    <h1>
                        Which ASN are you missing?
                    </h1>
                    <Intro.Sep />
                </Intro.Container>

                <ContactFormASN wishlist skipPhoneNumber/>
            </FirstColumn>
            {address && contactInfo && socials && (
                <ContactInfo
                    address={address}
                    contactInfo={contactInfo}
                    socials={socials}
                />
            )}
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    > div {
        display: flex;
        flex-direction: column;
        
    }

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        > div {
            flex-direction: row;
        }
    }
`;

const FirstColumn = styled.div`
    flex: 1;
`;

const enhance = compose<OutProps, InProps>(
    withModal,
    withRouter,
    graphql(GET_EXCHANGE_CONTACT, {
        options: ((props: Props) => ({
            variables: {
                site: props.match.params.exchange
            }
        }))
    })
);
export default enhance(WishlistSlideover);