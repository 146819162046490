import React, { SFC } from 'react';
import ObserveSize from 'react-observe-size';
import { RouteComponentProps, withRouter } from 'react-router';
import { LinkProps } from 'react-router-dom';
import { compose } from 'recompose';
import amsterdamIconLight from '../../../assets/maps/location-icon-grey_AMS.svg';
import bayAreaIconLight from '../../../assets/maps/location-icon-grey_BA.svg';
import caribbeanIconLight from '../../../assets/maps/location-icon-grey_CAR.svg';
import chicagoIconLight from '../../../assets/maps/location-icon-grey_CHI.svg';
import hongKongIconLight from '../../../assets/maps/location-icon-grey_HK.svg';
import mumbaiIconLight from '../../../assets/maps/location-icon-grey_MUM.svg';
import kolkataIconLight from '../../../assets/maps/location-icon-grey_KOL.svg';
import noidaIconLight from '../../../assets/maps/location-icon-grey_NOI.svg';
import chennaiIconLight from '../../../assets/maps/location-icon-grey_CHE.svg';
import hyderabadIconLight from '../../../assets/maps/location-icon-grey_HYD.svg';
import singaporeIconLight from '../../../assets/maps/location-icon-grey_SIN.svg';
import bangkokIconLight from '../../../assets/maps/location-icon-grey_BAN.svg';
import manilaIconLight from '../../../assets/maps/location-icon-grey_MAN.svg';
import lagosIconLight from '../../../assets/maps/location-icon-grey_LAG.svg';
import djiboutiIconLight from '../../../assets/maps/location-icon-grey_DJI.svg';
import amsterdamIconDark from '../../../assets/maps/location-icon-white_AMS.svg';
import bayAreaIconDark from '../../../assets/maps/location-icon-white_BA.svg';
import caribbeanIconDark from '../../../assets/maps/location-icon-white_CAR.svg';
import chicagoIconDark from '../../../assets/maps/location-icon-white_CHI.svg';
import hongKongIconDark from '../../../assets/maps/location-icon-white_HK.svg';
import mumbaiIconDark from '../../../assets/maps/location-icon-white_MUM.svg';
import kolkataIconDark from '../../../assets/maps/location-icon-white_KOL.svg';
import noidaIconDark from '../../../assets/maps/location-icon-white_NOI.svg';
import chennaiIconDark from '../../../assets/maps/location-icon-white_CHE.svg';
import hyderabadIconDark from '../../../assets/maps/location-icon-white_HYD.svg';
import singaporeIconDark from '../../../assets/maps/location-icon-white_SIN.svg';
import bangkokIconDark from '../../../assets/maps/location-icon-white_BAN.svg'
import manilaIconDark from '../../../assets/maps/location-icon-white_MAN.svg'
import lagosIconDark from '../../../assets/maps/location-icon-white_LAG.svg'
import djiboutiIconDark from '../../../assets/maps/location-icon-white_DJI.svg'
import sites from '../../../constants/sites';
import { DropdownTyping } from '../../../entities/DropdownType';
import { MenuScheme } from '../../../entities/operationResults';
import styled from '../../../styled-components';
import ArrowDownIcon from '../../atoms/icons/ArrowDownIcon';
import { ExchangeParams } from '../../Exchange';

type OutProps = RouteComponentProps<ExchangeParams> & LinkProps;

interface InProps {
    menuScheme: MenuScheme;
    setVisible: (visible: boolean) => void;
    setExchangeVisible: (visible: boolean) => void;
    exchangeVisible: boolean;
    setActiveIndex: (activeDropdownIndex: number | null) => void;
    setDropdownType: (dropdownType: DropdownTyping) => void;
}

const enhanced = compose<OutProps, InProps>(withRouter);

function getMapSettings(exchange: string) {
    let light: string = '';
    let dark: string = '';
    switch (exchange) {
        case 'ams':
            light = amsterdamIconLight;
            dark = amsterdamIconDark;
            break;
        case 'mum':
            light = mumbaiIconLight;
            dark = mumbaiIconDark;
            break;
        case 'hk':
            light = hongKongIconLight;
            dark = hongKongIconDark;
            break;
        case 'chi':
            light = chicagoIconLight;
            dark = chicagoIconDark;
            break;
        case 'car':
            light = caribbeanIconLight;
            dark = caribbeanIconDark;
            break;
        case 'bay':
            light = bayAreaIconLight;
            dark = bayAreaIconDark;
            break;
        case 'kol':
            light = kolkataIconLight;
            dark = kolkataIconDark;
            break;
        case 'noi':
            light = noidaIconLight;
            dark = noidaIconDark;
            break;
        case 'che':
            light = chennaiIconLight;
            dark = chennaiIconDark;
            break;
        case 'hyd':
            light = hyderabadIconLight;
            dark = hyderabadIconDark;
            break;
        case 'sin':
            light = singaporeIconLight;
            dark = singaporeIconDark;
            break;
        case 'ban':
            light = bangkokIconLight;
            dark = bangkokIconDark;
            break;
        case 'man':
            light = manilaIconLight;
            dark = manilaIconDark;
            break;
        case 'lag':
            light = lagosIconLight;
            dark = lagosIconDark;
            break;
        case 'dji':
            light = djiboutiIconLight;
            dark = djiboutiIconDark;
            break;
        default:
            light = amsterdamIconLight;
            dark = amsterdamIconDark;
            break;
    }

    return {
        light,
        dark,
    };
}

const HeaderExchangePicker: SFC<InProps & OutProps> = ({
    menuScheme,
    match,
    setDropdownType,
    setVisible,
    setActiveIndex,
    setExchangeVisible,
    exchangeVisible,
}) => {
    const map = getMapSettings(match.params.exchange);
    const exchange = sites.find(site => site.handle === match.params.exchange);
    return (
        <ObserveSize>
            {({ width }) => (
                <HeaderExchangePickerWrap
                    onMouseOver={() => {
                        setDropdownType('locations');
                        setVisible(true);
                        setActiveIndex(null);
                    }}
                    onClick={() => {
                        setExchangeVisible(!exchangeVisible);
                    }}
                    scheme={menuScheme}
                    className={'location-picker'}
                >
                    <Map src={map.dark} alt={exchange !== undefined && exchange.title || undefined} />
                    <PickerText scheme={menuScheme}>
                        <span>LOCATION</span>
                        <br />
                        {exchange !== undefined && exchange.title}
                    </PickerText>
                    {width >= 200 && <ArrowDownIcon type={'small'} />}
                </HeaderExchangePickerWrap>
            )}
        </ObserveSize>
    );
};

const HeaderExchangePickerWrap = styled.div<{ scheme: MenuScheme }>`
    border-left: 1px solid ${({ theme }) => theme.colors.shuttleGray};
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0.4rem 0.8rem;
    margin: 0 0 0 0.8rem;
    cursor: pointer;
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding: 0 1.8rem;
        margin: 0 0 0 1.2rem;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        padding: 0 1.8rem;
        flex-wrap: nowrap;
        flex-direction: unset;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        padding: 0 2.4rem;
    }
`;

const Map = styled.img`
    height: 2.4rem;
    // switch back when the campaign is over
    // @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
    //     height: 41px;
    //     width: 35px;
    // }
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        height: 41px;
        width: 35px;
        display: none;
    }
`;

const PickerText = styled.p<{ scheme: MenuScheme }>`
    margin: 0;
    font-size: 1rem;
    line-height: 0.6rem;
    // remove this when the campaign is over
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
      line-height: 2rem;
    }
    font-family: chalet-new-york-sixty;
    color: inherit;
    span {
        font-family: 'urw-din';
        color: ${({ theme }) => theme.colors.white};
        font-size: 1rem;
        // remove comment when the campaign is over
        // line-height: 1.2rem;
        letter-spacing: 0.04rem;
        display: none;
        // remove this when the campaign is over
        @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
          display: unset;
        }
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin: 0 4.2rem 0 1.6rem;
        // remove this when the campaign is over
        margin-left: 0;

        span {
            display: unset;
        }
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        line-height: 2rem;
        font-size: 1.6rem;
    }
`;

export default enhanced(HeaderExchangePicker);
