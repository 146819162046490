import gql from 'graphql-tag';
import React, { SFC } from 'react';
import { DataValue } from 'react-apollo';
import ObserveSize from 'react-observe-size';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import generatePath from 'react-router-dom/generatePath';
import { compose, withState } from 'recompose';

import { getStartedRoute, homeRoute, MatchedRoute, searchRoute } from '../../constants/routes';
import { DropdownTyping } from '../../entities/DropdownType';
import { MenuFragment, MenuQuery, MenuScheme } from '../../entities/operationResults';
import { getEntriesOfType } from '../../services/entryTypes';
import matchRoute from '../../services/matchRoute';
import styled, { ThemeProps, withTheme } from '../../styled-components';
import DefaultButton from '../atoms/buttons/DefaultButton';
import CloseIcon from '../atoms/icons/CloseIcon';
import Logo from '../atoms/icons/Logo';
import OpenMenuIcon from '../atoms/icons/OpenMenuIcon';
import SearchIcon from '../atoms/icons/SearchIcon';
import UserProfileIcon from '../atoms/icons/UserProfileIcon';
import { ExchangeParams } from '../Exchange';
import Dropdown from '../molecules/header/dropdown/Dropdown';
import HeaderExchangePicker from '../molecules/header/HeaderExchangePicker';
import HeaderStats from '../molecules/HeaderStats';
import Menu from './Menu';
import MobileExchangeMenu from './MobileExchangeMenu';
import MobileMenu from './MobileMenu';
import ArrowDownIcon from '../atoms/icons/ArrowDownIcon';
import TransparentButton from '../atoms/buttons/TransparentButton';
import comingSoonHandles from '../../constants/comingSoonHandles';
// remove when campaign is over
import Logo30 from "../atoms/icons/Logo30";

export const HeaderFragments = gql`
    fragment Menu on Menu {
        ...MenuItem
        menuDropdownType
        children {
            ...MenuColumn
        }
    }

    fragment MenuColumn on Menu {
        title
        children {
            ...MenuItem
        }
    }

    fragment MenuItem on Menu {
        id
        title
        linkEntry {
            uri
        }
        externalLink 
        ancestors {
            title
        }
    }
`;

const enhanced = compose<OutProps, InProps>(
    withRouter,
    withState('visible', 'setVisible', false),
    withState('exchangeVisible', 'setExchangeVisible', false),
    withState('dropdownVisible', 'setDropdownVisible', false),
    withState('activeDropdownIndex', 'setActiveDropdownIndex', null),
    withState('dropdownType', 'setDropdownType', null),
    withTheme,
);

interface InProps {
    data: DataValue<MenuQuery> | null;
}

interface OutProps extends ThemeProps, RouteComponentProps<ExchangeParams> {
    visible: boolean;
    exchangeVisible: boolean;
    dropdownVisible: boolean;
    activeDropdownIndex: number | null;
    setActiveDropdownIndex: (activeDropdownIndex: number | null) => void;
    setVisible: (visible: boolean) => void;
    setExchangeVisible: (visible: boolean) => void;
    setDropdownVisible: (dropdownVisible: boolean) => void;
    dropdownType: DropdownTyping;
    setDropdownType: (dropdownType: DropdownTyping) => void;
}

const Header: SFC<OutProps & InProps> = ({
    data,
    theme,
    visible,
    setVisible,
    setDropdownVisible,
    dropdownVisible,
    activeDropdownIndex,
    setActiveDropdownIndex,
    dropdownType,
    setDropdownType,
    match,
    location,
    exchangeVisible,
    setExchangeVisible,
}) => {
    if (!data || !data.menuConfig) {
        return null;
    }
    const menuItems: MenuFragment[] | null = getEntriesOfType('Menu', data && data.menuItems);
    const activeMenuItem = activeDropdownIndex !== null && menuItems[activeDropdownIndex];
    const matchedRoute: MatchedRoute<any> | null = matchRoute(location.pathname);
    const scheme: MenuScheme =
        matchedRoute && matchedRoute.route.menuScheme && matchedRoute.route.menuScheme === MenuScheme.dark
            ? MenuScheme.dark
            : data.menuConfig.scheme || MenuScheme.light;
    const getStartedPath = generatePath<ExchangeParams>(getStartedRoute.path, {
        exchange: match.params.exchange,
    });

    return (
        <ObserveSize>
            {({ width }) => (
                <React.Fragment>
                    <MenuContainer onMouseLeave={() => setDropdownVisible(false)} visible={visible}>
                        <MenuWrap scheme={scheme}>
                            <MenuLeft>
                                <MenuFlag>
                                    <Link
                                        to={generatePath(homeRoute.path, {
                                            exchange: match.params.exchange,
                                        })}
                                    >
                                        {/* remove this when campaign is over (leave <Brand /> only) */}
                                        {width < theme.mediaQueriesValues.m && (
                                            <Brand />
                                        )}
                                        {width >= theme.mediaQueriesValues.m && (
                                            <Brand30 />
                                        )}
                                    </Link>
                                    <HeaderExchangePicker
                                        menuScheme={scheme}
                                        setVisible={setDropdownVisible}
                                        exchangeVisible={exchangeVisible}
                                        setExchangeVisible={setExchangeVisible}
                                        setActiveIndex={setActiveDropdownIndex}
                                        setDropdownType={setDropdownType}
                                    />
                                </MenuFlag>
                                <DesktopItems>
                                    <Menu
                                        activeDropdownIndex={activeDropdownIndex}
                                        menuItems={menuItems}
                                        setDropdownVisible={setDropdownVisible}
                                        setDropdownActiveIndex={setActiveDropdownIndex}
                                        setDropdownType={setDropdownType}
                                    />
                                </DesktopItems>
                            </MenuLeft>
                            <MenuRight>
                                <div
                                    onMouseOver={() => {
                                        setDropdownType('statistics');
                                        setDropdownVisible(true);
                                        setActiveDropdownIndex(
                                            menuItems.findIndex(menuItem => menuItem.menuDropdownType === 'statistics'),
                                        );
                                    }}
                                >
                                    <HeaderStats headerWidth={width} menuScheme={scheme} />
                                </div>

                                <DesktopItems>
                                    {!comingSoonHandles.includes(data.variables.site) &&
                                        <DefaultButton to={getStartedPath} colorScheme={'colored'}>
                                            GET STARTED
                                        </DefaultButton>
                                    }

                                    <Wrapper onMouseOver={() => {
                                        setDropdownType('myAmsIx');
                                        setDropdownVisible(true);
                                        setActiveDropdownIndex(
                                            menuItems.findIndex(menuItem => menuItem.menuDropdownType === 'myAmsIx'),
                                        );
                                    }}>
                                        <TransparentButton scheme={scheme}>
                                            <UserProfileIcon />
                                            <ArrowDownIcon type={'small'} />
                                        </TransparentButton>
                                    </Wrapper>

                                    <IconLink
                                        to={generatePath(searchRoute.path, {
                                            exchange: match.params.exchange,
                                        })}
                                        className={'search'}
                                    >
                                        <SearchIcon />
                                    </IconLink>
                                </DesktopItems>
                            </MenuRight>
                        </MenuWrap>
                        {width >= theme.mediaQueriesValues.m && dropdownType && (
                            <Dropdown
                                dropdownType={dropdownType}
                                visible={dropdownVisible}
                                setVisible={setDropdownVisible}
                                setActiveIndex={setActiveDropdownIndex}
                                setDropdownType={setDropdownType}
                                menuSubs={activeMenuItem ? activeMenuItem : null}
                                scheme={scheme}
                            />
                        )}
                        <MenuButton onClick={() => setVisible(!visible)}>
                            {visible ? <CloseIcon /> : <OpenMenuIcon />}
                        </MenuButton>
                        {width <= theme.mediaQueriesValues.m && (
                            <MobileMenu
                                visible={visible}
                                setVisible={setVisible}
                                menuItems={menuItems}
                                getStartedPath={getStartedPath}
                            />
                        )}
                        {width <= theme.mediaQueriesValues.m && (
                            <MobileExchangeMenu visible={exchangeVisible} setVisible={setExchangeVisible} />
                        )}
                    </MenuContainer>
                </React.Fragment>
            )}
        </ObserveSize>
    );
};

const Wrapper = styled.div`
    display:inline;
    margin:0;
    padding:0;
`;

const MenuFlag = styled.div`
    background: ${props => props.theme.colors.mirage};
    color: ${props => props.theme.colors.white};
    display: flex;
    align-items: center;
    padding: 0.75rem 0 0.75rem 1rem;
    margin-right: 2.4rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        margin: 0rem 2.4rem 0rem 0;
        padding: 1.2rem 0 1.2rem 1.2rem;
        width: 28rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        margin: -1.2rem 2.4rem -1.2rem 0;
        padding: 1.2rem 0 1.2rem 1.2rem;
        width: 30.4rem;
    }

    @media screen and (max-width: ${props => props.theme.mediaQueries.s}) {
        margin-right: 0;
    }

    // remove this when campaign is over
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding: 0;
    }
`;

const MenuContainer = styled.header<{ visible: boolean }>`
    position: fixed;
    z-index: 1;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 0 0.1rem rgba(23, 34, 42, 0.08);

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        justify-content: space-between;
    }
    @media screen and (max-width: ${props => props.theme.mediaQueries.m}) {
        &:after {
            content: '';
            position: absolute;
            display: ${props => (props.visible ? 'block' : 'none')};
            top: 6.4rem;
            right: 0;
            width: 100%;
            height: calc(100vh - 6.4rem);
            background-color: black;
            opacity: 0.7;
            z-index: -1;
        }
    }
`;

const MenuWrap = styled.div<{ scheme: MenuScheme }>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    color: ${props => (props.scheme !== 'light' ? props.theme.colors.white : props.theme.colors.black)};
    transition: 0.3s color, 0.3s background-color;
    background-color: ${props =>
        props.scheme === 'transparent'
            ? 'transparent'
            : props.scheme === 'dark'
            ? props.theme.colors.mirage
            : props.theme.colors.white};
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        padding: 0rem 0;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        padding: 1.2rem 0;
    }
`;

const MenuGroup = styled.div`
    display: flex;
    align-items: center;
`;
const MenuLeft = styled(MenuGroup)`
    /* padding: 0 0 0 1rem; 
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding: 0 0 0 1.6rem; 
  } */
`;

const MenuRight = styled(MenuGroup)`
    padding: 0.5rem 0 0 0;
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding: 0 1.3rem 0 0;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        padding: 0 2.9rem 0 0;
    }
`;

const Brand = styled(Logo)`
    transform: scale(0.9);
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        transform: unset;
    }
`;

const Brand30 = styled(Logo30)`
    transform: scale(0.9);
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        transform: unset;
    }
`;

const IconButton = styled.button`
    vertical-align: middle;
    position: relative;
    display: inline-block;
    background-color: unset;
    border: none;
    margin-left: 1.3rem;
    color: inherit;
    cursor: pointer;
    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        margin-left: 2.8rem;
    }
    &:after {
        content: '';
        position: absolute;
        height: 3.2rem;
        width: 3.2rem;
        top: -0.5rem;
        right: -0.8rem;
        border-radius: 50%;
        z-index: -1;
        background-color: ${props => props.theme.colors.aquaHaze};
        opacity: 0;
        transition: opacity 0.2s;
    }

    &:last-child:after {
        right: 0;
    }

    &:hover:after {
        opacity: 1;
    }
`;

// hacks...
const IconLink = IconButton.withComponent(Link as any) as any;

const MenuButton = styled.button`
    background-color: ${props => props.theme.colors.mirage};
    color: ${props => props.theme.colors.white};
    width: 6.4rem;
    height: 6.4rem;
    flex-shrink: 0;
    flex-grow: 0;
    border: none;
    outline: none;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        display: none;
    }
`;

const DesktopItems = styled.div`
    display: none;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        display: block;
    }
`;

export default enhanced(Header);
