import gql from 'graphql-tag';
import React, { SFC } from 'react';
import { TextWithImageContentBlockFragment } from '../../entities/operationResults';
import styled from '../../styled-components';
import Container from '../atoms/Container';
import ContentBody from '../atoms/ContentBody';
import ContentTitle from '../atoms/ContentTitle';
import LazyImg from '../atoms/LazyImg';

export const fragment = gql`
    fragment TextWithImageContentBlock on ContentBlocksTextWithImage {
        image {
            srcSmall: url(transform: contentBlockImageWithTextSmall)
            widthSmall: width(transform: contentBlockImageWithTextSmall)
            srcMedium: url(transform: contentBlockImageWithTextMedium)
            widthMedium: width(transform: contentBlockImageWithTextMedium)
            srcLarge: url(transform: contentBlockImageWithTextLarge)
            widthLarge: width(transform: contentBlockImageWithTextLarge)
            title
        }
        heading
        textHTML: text {
            content
        }
        imageAlignment
    }
`;

interface Props {
    contentBlock: TextWithImageContentBlockFragment;
}

const TextWithImageContentBlock: SFC<Props> = ({ contentBlock: { image, heading, textHTML, imageAlignment } }) => {
    const blockImage = image && image[0];

    return (
        <Container paddingBottom={'6rem'} paddingBottomL={'12rem'}>
            <Wrapper imageRight={imageAlignment === 'right'}>
                {blockImage && blockImage.srcSmall && (
                    <ImgWrapper>
                        <Image
                            src={blockImage.srcSmall}
                            srcSet={`
                                ${blockImage.srcSmall} ${blockImage.widthSmall}w,
                                ${blockImage.srcMedium} ${blockImage.widthMedium}w,
                                ${blockImage.srcLarge} ${blockImage.widthLarge}w`}
                            sizes={'(min-width: 990px) 50vw, 100vw'}
                            alt={blockImage.title || ''}
                        />
                    </ImgWrapper>
                )}
                {(heading || textHTML) && (
                    <Content imageRight={imageAlignment === 'right'}>
                        {heading && <ContentTitle>{heading}</ContentTitle>}
                        {textHTML && textHTML.content && (
                            <ContentBody dangerouslySetInnerHTML={{ __html: textHTML.content }} />
                        )}
                    </Content>
                )}
            </Wrapper>
        </Container>
    );
};

const Wrapper = styled.div<{ imageRight: boolean }>`
    position: relative;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        display: flex;
        ${props => (props.imageRight ? 'flex-direction: row-reverse;' : '')}

        > div {
            width: 50%;
        }
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.xxl}) {
        padding: 0 7.2rem;
    }
`;

const Image = styled(LazyImg)`
    width: 100%;
    display: block;
`;

const ImgWrapper = styled.div`
    position: relative;
`;

const Content = styled.div<{ imageRight: boolean }>`
    box-sizing: border-box;
    padding: 4rem 3.3rem;
    align-self: center;
    max-width: 64rem;
    margin: 0 auto;

    @media (min-width: ${props => props.theme.mediaQueries.m}) {
        margin: 0;
        padding: ${({ imageRight }) => (imageRight ? '0 8rem 0 4rem' : '0 4rem 0 8rem')};
    }
`;

export default TextWithImageContentBlock;
