import React from 'react';
import { MenuFragment } from '../../entities/operationResults';
import ArrowDownIcon from '../atoms/icons/ArrowDownIcon';
import { DropdownTyping } from '../../entities/DropdownType';
import { NavMainItem, NavList } from '../atoms/NavItems';

interface Props {
    menuItems: MenuFragment[];
    setDropdownVisible: (visible: boolean) => void;
    setDropdownActiveIndex: (activeDropdownIndex: number) => void;
    setDropdownType: (dropdownType: DropdownTyping) => void;
    activeDropdownIndex: number | null;
}

const Menu = ({ menuItems, setDropdownVisible, setDropdownActiveIndex, setDropdownType, activeDropdownIndex }: Props) => {
    return (
        <NavList>
            {menuItems.map((menuItem, index) => {
                const menuType = menuItem.menuDropdownType;
                return (
                    menuType && menuType !== 'statistics' && menuType !== 'myAmsIx' &&
                    <NavMainItem key={menuItem.id}>
                        <div
                            style={{ cursor: 'pointer' }}
                            className={activeDropdownIndex === index ? 'active' : ''}
                            onMouseOver={() => {
                                setDropdownActiveIndex(index);
                                setDropdownVisible(true);
                                setDropdownType(menuType);
                            }}
                        >
                            {menuItem.title}<ArrowDownIcon type={'small'} />
                        </div>
                    </NavMainItem>
                );
            })}
        </NavList>
    );
};

export default Menu;
