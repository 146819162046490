import React, { SFC } from 'react';
import styled, { withTheme } from '../../styled-components';
import DefaultButton from '../atoms/buttons/DefaultButton';
import { ThemeInterface } from '../../constants/theme';
import { ThemeProps } from 'styled-components';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router';
import { ExchangeParams } from '../Exchange';
import { default as sites, Site } from '../../constants/sites';
import generatePath from 'react-router-dom/generatePath';
import { infoRoute } from '../../constants/routes';
import { InfoParams } from '../Info';

type Props = ThemeProps<ThemeInterface> & RouteComponentProps<ExchangeParams>;

const HeroIntroBlock: SFC<Props> = ({ theme, match }) => {
    const site: Site | undefined = sites.find(current => current.handle === match.params.exchange);
    if (!site) {
        throw new Error('Invalid exchange param passed to HeroIntroBlock parent route, this shouldn\'t happen');
    }
    const aboutPath = generatePath<InfoParams>(infoRoute.path, { exchange: match.params.exchange, slug: 'about-ams-ix' });

    return (
        <AboutWrap>
            <Description>
                <Text>
                    Your <br/> interconnection
                    platform in <br/> <span>{site.title}</span>
                </Text>
                <ButtonWrap>
                    <FirstButton colorScheme={'colored'} to={aboutPath} className={'Hero-CTA'}>
                        ABOUT US
                    </FirstButton>
                    <DefaultButton colorScheme={'dark'} to={`/${match.params.exchange}/contact-us`} className={'Hero-CTA'}>
                        CONTACT US
                    </DefaultButton>
                </ButtonWrap>
            </Description>
        </AboutWrap>
    );
};

const Text = styled.p`
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        text-shadow: 0px 0px 20px ${(props) => props.theme.colors.dustyGray};
    }
`;

const AboutWrap = styled.div`
    width: 100%;
    max-width: calc(100% - 0.8rem);
    color:white;
    position: relative;
    z-index: 1;
    @media screen and (max-width: ${props => props.theme.mediaQueries.m}) {
        background-color: ${(props) => props.theme.colors.mirage};
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) {
        max-width: 40rem;
        margin-right: 0;
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        max-width: 66rem;
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        max-width: 71rem;
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.xl}) {
        max-width: 82.5rem;
    }
`;

const Description = styled.div`
    padding: 4rem 3.2rem 2.8rem 4rem; 
    p{
        margin: 0;
        text-align: left;
        font: ${(props) => props.theme.fonts.small.exchangePicker};
        letter-spacing: -0.049rem;
        padding-bottom: 2rem;
    }
    span{
      font-weight: bold;
      letter-spacing: -0.102rem;
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        padding: 5.6rem 8rem 3.2rem 5.6rem; 
        p{
            font: ${(props) => props.theme.fonts.medium.exchangePicker};
            letter-spacing: -0.087rem;
        }
    }

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        padding: 5.6rem 6.4rem 3.2rem 6.4rem; 
        p{
            font: ${(props) => props.theme.fonts.large.exchangePicker};
        }
    }
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.xl}) {
        padding: 5.6rem 12rem 3.2rem 10.2rem; 
        p{
            font: ${(props) => props.theme.fonts.large.exchangePicker};
        }
    }
    
`;

const ButtonWrap = styled.div`
    display: flex;
    justify-content: row;
    align-items: center;
    padding: 2.8rem 0 1.6rem;

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) {
        padding: 1.6rem 0 1.6rem;
    }
`;

const FirstButton = styled(DefaultButton)`
    margin-right: 3rem;
`;

const enhance = compose(
    withTheme,
    withRouter
);

export default enhance(HeroIntroBlock);
