import gql from 'graphql-tag';
import React, { SFC } from 'react';
import { GraphicContentBlockFragment } from '../../entities/operationResults';
import styled from '../../styled-components';
import Container from '../atoms/Container';
import LazyImg from '../atoms/LazyImg';

export const fragment = gql`
    fragment GraphicContentBlock on ContentBlocksGraphic {
        image {
            srcSmall: url(transform: contentBlockGraphicSmall)
            widthSmall: width(transform: contentBlockGraphicSmall)
            srcMedium: url(transform: contentBlockGraphicMedium)
            widthMedium: width(transform: contentBlockGraphicMedium)
            srcLarge: url(transform: contentBlockGraphicLarge)
            widthLarge: width(transform: contentBlockGraphicLarge)
            srcOriginal: url
            widthOriginal: width
            title
        }
    }
`;

interface Props {
    contentBlock: GraphicContentBlockFragment;
    narrow?: boolean;
}

const GraphicContentBlock: SFC<Props> = ({ contentBlock: { image }, narrow }) => {
    const blockImage = image && image[0];
    return (
        <Container maxWidth paddingBottom={'6rem'} paddingBottomL={'12rem'}>
            <Wrapper narrow={narrow}>
                {blockImage && blockImage.srcSmall && (
                    <ImgWrapper>
                        <Image
                            srcSet={`
                                ${blockImage.srcSmall} ${blockImage.widthSmall}w,
                                ${blockImage.srcMedium} ${blockImage.widthMedium}w,
                                ${blockImage.srcLarge} ${blockImage.widthLarge}w`}
                            src={blockImage.srcSmall}
                            alt={blockImage.title || ''}
                            sizes={'100vw'}
                            width={blockImage.widthOriginal || ''}
                        />
                    </ImgWrapper>
                )}
            </Wrapper>
        </Container>
    );
};

const Wrapper = styled.div<{ narrow?: boolean }>`
    position: relative;
    display: flex;
    justify-content: center;

    ${props =>
        props.narrow
            ? `
        max-width: 900px;
        margin: 0 auto;
        
        @media screen and (min-width: ${props.theme.mediaQueries.l}) {
            > div, > img {
                max-width: 75%;
            }
        }
    `
            : ''};
`;

const Image = styled(LazyImg)`
    max-width: 100%;
    display: block;
`;

const ImgWrapper = styled.div`
    position: relative;
`;

export default GraphicContentBlock;
