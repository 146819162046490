import gql from 'graphql-tag';
import {
    EntryTypesFragment,
    RelatedEventFragment,
    RelatedInfoFragment,
    RelatedServiceFragment,
    RelatedStoryFragment,
    RelatedDocumentationFragment
} from '../entities/operationResults';

// convenience functions to make working with huge graphql unions easier

// tslint:disable-next-line:no-unused-expression
gql`
    fragment EntryTypes on EntryInterface {
        __typename
    }
`;

export type EntryType = EntryTypesFragment['__typename'];

interface Fragment {
    __typename: EntryType;
}

const isEntryOfType = <TFragment extends Fragment>(typename: EntryType) =>
    (arg: Fragment): arg is TFragment => {
        return !!arg && arg.__typename === typename;
    };

export const getEntryOfType = <TFragment extends Fragment>(typename: EntryType, entry: Fragment | null | undefined): TFragment | null => {
    if (entry && isEntryOfType<TFragment>(typename)(entry)) {
        return entry;
    } else {
        return null;
    }
};

export const getEntriesOfType = <TFragment extends Fragment>(typename: EntryType, entries: Array<Fragment | null> | null | undefined): TFragment[] => {
    if (!entries) {
        return [];
    }

    return entries.filter(isEntryOfType(typename));
};

export const getRelatedItems = (relatedItems: Array<any | null> | null | undefined): Array<RelatedStoryFragment | RelatedEventFragment | RelatedServiceFragment | RelatedInfoFragment | RelatedDocumentationFragment> => {
    const items: Array<RelatedStoryFragment | RelatedEventFragment | RelatedServiceFragment | RelatedInfoFragment | RelatedDocumentationFragment> = [];

    if (relatedItems) {
        for (const item of relatedItems) {
            if (!item) {
                continue;
            }
            switch (item.__typename) {
                case 'News':
                case 'Event':
                case 'Story':
                case 'Service':
                case 'Documentation':
                case 'Info':
                    items.push(item);
                    break;
                default:
                    break;
            }
        }
    }
    return items;
};