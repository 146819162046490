import gql from 'graphql-tag';
import React, { SFC } from 'react';
import styled from '../../styled-components';
import { IntroContentBlockFragment } from '../../entities/operationResults';
import ReactScroll from 'react-scroll';
import Container from '../atoms/Container';
import { Text } from './Intro';
import IntroTitle from '../atoms/IntroTitle';

export const fragment = gql`
    fragment IntroContentBlock on ContentBlocksIntro {
        id
        heading
        textHTML: text {
            content
        }
    }
`;

interface Props {
    contentBlock: IntroContentBlockFragment;
}
const IntroContentBlock: SFC<Props> = ({ contentBlock: { id, heading, textHTML } }) => (
    <Container maxWidth paddingBottom={'6rem'} paddingBottomL={'9.6rem'}>
        <ScrollContainer name={`section-${id}`}>
            {heading && <IntroTitle>{heading}</IntroTitle> }
            {textHTML && textHTML.content && (
                <Text dangerouslySetInnerHTML={{ __html: textHTML.content }} />
            )}
        </ScrollContainer>
    </Container>
);

export const ScrollContainer = styled(ReactScroll.Element)`
    padding-top: 3.2rem;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        max-width: 900px;
        margin: 0 auto;
    }
`;

export default IntroContentBlock;