import React, { Fragment, SFC } from 'react';
import SectionHeader from '../molecules/SectionHeader';
import StoryPreview from '../molecules/StoryPreview';
import Container from '../atoms/Container';
import styled, { ThemeProps, withTheme } from '../../styled-components';
import gql from 'graphql-tag';
import { compose } from 'recompose';
import { DataProps, graphql } from 'react-apollo';
import { FeaturedStoriesFragment, FeaturedStoryFragment, FeaturedStoriesQuery as Response } from '../../entities/operationResults';
import { getEntriesOfType, getEntryOfType } from '../../services/entryTypes';
import generatePath from 'react-router-dom/generatePath';
import { storiesRoute } from '../../constants/routes';
import { RouteComponentProps, withRouter } from 'react-router';
import { ExchangeParams } from '../Exchange';

const GET_STORIES_QUERY = gql`
    query FeaturedStories ($site: String!) {
        exchangePage: entry(site:$site, type:ExchangePage) {
            ...FeaturedStories
        }
    }

    fragment FeaturedStory on Story {
        id
        title
        uri
        heroImage {
            srcSmall: url(transform: storyHeroSmall)
            widthSmall: width(transform: storyHeroSmall)
            srcMedium: url(transform: storyHeroMedium)
            widthMedium: width(transform: storyHeroMedium)
            srcLarge: url(transform: storyHeroLarge)
            widthLarge: width(transform: storyHeroLarge)
            title
        }
        description
        service {
            title
        }
        postDate @date(as:"F j, Y", timezone:"Europe/Amsterdam")
    }

    fragment FeaturedStories on ExchangePage {
        featuredStories{
            ...FeaturedStory
        }
    }
`;

interface Props extends DataProps<Response>, ThemeProps, RouteComponentProps<ExchangeParams> {
}

const RelatedStories: SFC<Props> = ({ data, theme, match }) => {
    const exchangePage: FeaturedStoriesFragment | null = getEntryOfType('ExchangePage', data.exchangePage);
    const stories: FeaturedStoryFragment[] = getEntriesOfType('Story', exchangePage && exchangePage.featuredStories);

    return (
        <Container backgroundColor={theme.colors.aquaHaze} paddingTop={'5rem'} paddingTopL={'8rem'}>
            <SectionHeader
                title={<Fragment>AMS-IX <strong>stories</strong></Fragment>}
                linkLabel={'ALL STORIES'}
                link={generatePath(storiesRoute.path, { exchange: match.params.exchange })}
            />
            <Stories>
                {stories.map(({ id, title, uri, heroImage, service, postDate }) => {
                    const firstService = service && service[0];
                    const firstImage = heroImage && heroImage[0];
                    return uri && (
                        <StoryPreview
                            key={id}
                            link={`/${uri}`}
                            service={firstService && firstService.title}
                            title={title}
                            img={firstImage ? {
                                title: firstImage.title,
                                srcSmall: firstImage.srcSmall,
                                widthSmall: firstImage.widthSmall,
                                srcMedium: firstImage.srcMedium,
                                widthMedium: firstImage.widthMedium,
                                srcLarge: firstImage.srcLarge,
                                widthLarge: firstImage.widthLarge
                            } : undefined}
                            date={null}
                        />
                    );
                })}
            </Stories>
        </Container>
    );
};

const Stories = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    padding: 0.5rem;
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        flex-direction: unset;
    }
`;

const enhanced = compose(
    withRouter,
    graphql(GET_STORIES_QUERY, {
        options: ((props: Props) => ({
            variables: {
                site: props.match.params.exchange
            }
        }))
    }),
    withTheme
);

export default enhanced(RelatedStories);
