import gql from 'graphql-tag';
import React, { SFC } from 'react';
import styled from '../../styled-components';
import { TextContentBlockFragment } from '../../entities/operationResults';
import ContentTitle from '../atoms/ContentTitle';
import Container from '../atoms/Container';
import ContentBody from '../atoms/ContentBody';
import ReactScroll from 'react-scroll';
import { useQuery } from 'react-apollo';

export const fragment = gql`    
    fragment TextContentBlock on ContentBlocksText {
        id
        heading
        textHTML: text {
            content
        }
    }
`;

const GLOSSARY_QUERY = gql`
    query GlossaryQuery {
        globals {
            glossary {
                glossaryTable {
                    meaning
                    word
                }
            }
        }
    }
`;

interface Props {
    contentBlock: TextContentBlockFragment;
}

const TextContentBlock: SFC<Props> = ({ contentBlock: { id, heading, textHTML } }) => {
    const { data } = useQuery(GLOSSARY_QUERY);
    const wordMeanings = data ? data.globals.glossary.glossaryTable : [];
    const glossary = {};
    wordMeanings.map((object: any) => {
        // @ts-ignore
        glossary[object.word] = object.meaning;
    });

    const re = new RegExp(`\\b(${Object.keys(glossary).join("|")})\\b`, "gi");
    return (
        <Container maxWidth paddingBottom={'6rem'} paddingBottomL={'9.6rem'}>
            <Block name={`section-${id}`}>
                {heading && (
                    <ContentTitle>{heading}</ContentTitle>
                )}
                {textHTML && textHTML.content && (
                    <ContentBody dangerouslySetInnerHTML={{ __html: textHTML.content.replace(re, (matched) => {
                            // @ts-ignore
                            return `<span class="tooltip" data-meaning="${glossary[matched]}">${matched}</span>`;
                        }) }} />
                )}
            </Block>
        </Container>
    );
};



const Block = styled(ReactScroll.Element)`
    box-sizing: border-box;
    
    ${ContentBody} p:last-child {
      margin-bottom: 0;
    }
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        max-width: 900px;
        margin: 0 auto;
        
        > h2 {
            max-width: 60%;
        }
    }
    
    span.tooltip {
      position: relative;
      text-decoration: underline; 
    }
    
    span.tooltip::before {
      content: attr(data-meaning);
      line-height: 1.1;
      position: absolute;
      bottom: calc(100% + 7px);
      left: 50%;
      transform: translateX(-50%);
      padding: 10px 15px;
      border: 0px solid #f47f22;
      border-radius: 5px;
      background-color: #f47f22;
      color: white;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      pointer-events: none;
      width: 300px;
      text-align: center;
    }
    
    span.tooltip::after {
      content: '';
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translateX(-50%);
      margin-left: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: #f47f22 transparent transparent transparent;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }
    
    span.tooltip:hover::before, span.tooltip:hover::after {
      opacity: 1;
    }
`;

export default TextContentBlock;
