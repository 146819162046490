import * as React from 'react';
import { SFC } from 'react';
import LazyLoad from 'react-lazyload';
import { Fallback, Img } from 'react-image-loading';

type Props = React.ImgHTMLAttributes<HTMLImageElement>;

const LazyImg: SFC<Props> = (props) => (
    <LazyLoad once placeholder={<Fallback />}>
        <Img {...props} />
    </LazyLoad>
);

export default LazyImg;