import * as queryString from 'querystring';
import React, { SFC } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { withRouter } from 'react-router';
import { RouteConfigComponentProps } from 'react-router-config';
import { compose } from 'recompose';
import { CitiesFragment, ConnectedNetworkInfoFragment, CountriesFragment } from '../../entities/operationResults';
import styled from '../../styled-components';
import Container from '../atoms/Container';
import ArrowDownIcon from '../atoms/icons/ArrowDownIcon';
import SearchIcon from '../atoms/icons/SearchIcon';
import { Spinner } from '../atoms/Spinner';
import { ExchangeParams } from '../Exchange';
import DefaultButton from '../atoms/buttons/DefaultButton';

interface InProps {
    countries?: CountriesFragment[];
    cities?: CitiesFragment[];
    types?: Array<{
        label: string;
        value: string;
    }>;
    exportLinks?: ConnectedNetworkInfoFragment['exportLinks'];
    search?: boolean;
    loading?: boolean;
    passedFunction?: any;
    wishlist?: boolean;
}

interface OutProps extends RouteConfigComponentProps<ExchangeParams> {}

const CollectionFilters: SFC<InProps & OutProps> = ({
    countries,
    cities,
    types,
    history,
    location,
    exportLinks,
    search,
    loading,
    passedFunction,
    wishlist
}) => {
    const filtersQuery = queryString.parse(location.search.replace('?', ''));

    const pushQuery = (key: string, value: string, removeCity?: boolean) => {
        const queryObj = queryString.parse(location.search.replace('?', ''));

        if (value) {
            queryObj[key] = value;
        } else if (queryObj[key]) {
            delete queryObj[key];
        }

        if (removeCity) {
            delete queryObj.city;
        }

        const newQueryString = queryString.stringify(queryObj);
        history.push({
            search: newQueryString,
        });
    };

    const activeTypeFilter = types && types.find(type => type.value === filtersQuery.partnerType);

    return (
        <FilterBar>
            <Container maxWidth>
                <Filters>
                    {search && (
                        <Search>
                            <SearchIcon />
                            <Input>
                                <DebounceInput
                                    placeholder={'Search'}
                                    debounceTimeout={700}
                                    type="text"
                                    value={(filtersQuery.search && filtersQuery.search.toString()) || ''}
                                    onChange={event => {
                                        pushQuery('search', event.target.value);
                                    }}
                                />
                            </Input>
                        </Search>
                    )}

                    {countries && (
                        <Filter>
                            <FilterLabel active={!!filtersQuery.country}>
                                Country:{' '}
                                <span>
                                    {filtersQuery.country || 'Any'} <ArrowDownIcon type={'small'} />
                                </span>
                            </FilterLabel>
                            <Select
                                value={filtersQuery.country || ''}
                                onChange={event => {
                                    pushQuery('country', event.target.value, !!event.target.value);
                                }}
                            >
                                <option value="">{'Any'}</option>
                                {countries.map(country => (
                                    <option key={country.title} value={country.title}>
                                        {country.title}
                                    </option>
                                ))}
                            </Select>
                        </Filter>
                    )}

                    {cities && (
                        <Filter>
                            <FilterLabel active={!!filtersQuery.city}>
                                City:{' '}
                                <span>
                                    {filtersQuery.city || 'Any'} <ArrowDownIcon type={'small'} />
                                </span>
                            </FilterLabel>
                            <Select
                                value={filtersQuery.city || ''}
                                onChange={event => {
                                    pushQuery('city', event.target.value);
                                }}
                            >
                                <option value="">{'Any'}</option>
                                {cities.map((city, index) => (
                                    <option key={index} value={city.title}>
                                        {city.title}
                                    </option>
                                ))}
                            </Select>
                        </Filter>
                    )}

                    {types && (
                        <Filter>
                            <FilterLabel active={!!filtersQuery.partnerType}>
                                Type:{' '}
                                <span>
                                    {(activeTypeFilter && activeTypeFilter.label) || 'Any'}{' '}
                                    <ArrowDownIcon type={'small'} />
                                </span>
                            </FilterLabel>
                            <Select
                                value={filtersQuery.partnerType || ''}
                                onChange={event => {
                                    pushQuery('partnerType', event.target.value);
                                }}
                            >
                                <option value="">{'Any'}</option>
                                {types.map(({ value, label }) => (
                                    <option key={value} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </Select>
                        </Filter>
                    )}

                    {exportLinks && (
                        <Filter>
                            <FilterLabel active htmlFor={'export-select'}>
                                Export as{' '}
                                <span>
                                    <ArrowDownIcon type={'small'} />
                                </span>
                            </FilterLabel>
                            <Select
                                name="export-select"
                                id="export-select"
                                value=""
                                onChange={event => event.target.value && window.open(event.target.value, '_blank')}
                            >
                                <option value="">{''}</option>
                                {exportLinks.map(
                                    (link, index: number) =>
                                        link && (
                                            <option key={index} value={link.url || ''}>
                                                {link.title}
                                            </option>
                                        )
                                )}
                            </Select>
                        </Filter>
                    )}
                    {wishlist &&
                        <ASNWishlist>
                            <ASNText>Are you missing an ASN?</ASNText>
                            <DefaultButton colorScheme={'colored'} to={'#'} onClick={passedFunction}>
                                ASN Wishlist
                            </DefaultButton>
                        </ASNWishlist>
                    }
                    {loading && <FilterSpinner />}
                </Filters>
            </Container>
        </FilterBar>
    );
};

const FilterBar = styled.div`
    background-color: ${props => props.theme.colors.aquaHaze};
`;

const ASNWishlist = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    background-color: ${props => props.theme.colors.aquaHaze};
`;

const ASNText = styled.h2`
    font-weight: 300;
    margin-right: 3rem;
`;

const Filters = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        flex-wrap: nowrap;
    }
`;

const Filter = styled.div`
    position: relative;
    width: 100%;

    &:last-child {
        margin-bottom: 1rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        width: auto;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const FilterLabel = styled.label<{ active?: boolean }>`
    display: block;
    font: ${props => props.theme.fonts.large.filterTitle};
    padding: 1.4rem 2.5rem 1.4rem 0;

    span {
        color: ${props => (props.active ? props.theme.colors.tango : props.theme.colors.shuttleGray)};
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding: 2.4rem 2.5rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        padding: 3.4rem 2.5rem;
    }
`;

const Select = styled.select`
    display: block;
    appearance: none;
    border: none;
    background: transparent;
    font: inherit;
    outline: none;
    cursor: pointer;
    opacity: 0;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const Search = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2.4rem 0 1rem 0;
    width: 100%;

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        width: auto;
        margin: 3.2rem 2.5rem 3.2rem 0;
    }
`;

const Input = styled.div`
    margin-left: 1rem;
    input {
        background-color: transparent;
        font: ${props => props.theme.fonts.large.filterTitle};
        border: none;
        border-bottom: 2px solid ${props => props.theme.colors.baliHai};
        outline: none;
    }
`;

const FilterSpinner = styled(Spinner)`
    position: absolute;
    right: 0;
`;

const enhanced = compose<OutProps, InProps>(withRouter);

export default enhanced(CollectionFilters);
