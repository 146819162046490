import gql from 'graphql-tag';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { path } from 'ramda';
import React, { SFC } from 'react';
import { DataProps, graphql } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import generatePath from 'react-router-dom/generatePath';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';

import { connectedNetworksRoute } from '../../constants/routes';
import { ThemeInterface } from '../../constants/theme';
import { MenuScheme, MenuStatsFragment, MenuStatsQuery } from '../../entities/operationResults';
import withWidth, { WidthProps } from '../../hocs/withWidth';
import { getEntriesOfType } from '../../services/entryTypes';
import { formatBitUnits } from '../../services/numberFormatting';
import styled, { ThemeProps } from '../../styled-components';
import { ExchangeParams } from '../Exchange';

export const GET_MENU_STATISTICS = gql`
    query MenuStats($site: String!) {
        stats: entries(site: $site, type: Graph, interval: daily, target: "totalall") {
            ...MenuStats
        }
        vitals: globals(site: $site) {
            globalStats {
                currentTraffic
                peakTraffic
                connectedNetworks
            }
        }
    }

    fragment MenuStats on Graph {
        averageInputData {
            time
            value
        }
    }
`;

interface InProps {
    headerWidth: number;
    menuScheme: MenuScheme;
}

interface OutProps extends DataProps<MenuStatsQuery>, ThemeProps, RouteComponentProps<ExchangeParams>, WidthProps { }

const buildConfig = (
    peakTraffic: number,
    stats: MenuStatsFragment['averageInputData'] | null,
    headerWidth: number,
    theme: ThemeInterface,
    scheme: MenuScheme
) => {

    const chartSettings =
        headerWidth > theme.mediaQueriesValues.xl
            ? {
                lineWidth: 2,
                width: 100,
                height: 50,
            }
            : {
                lineWidth: 1.5,
                width: 42,
                height: 45,
            };

    if (peakTraffic && stats) {
        return {
            chart: {
                type: 'areaspline',
                animation: false,
                height: chartSettings.height,
                width: chartSettings.width,
                margin: [0, 0, 0, 0],
                backgroundColor: 'transparent',
            },
            tooltip: { enabled: false },
            title: { text: undefined },
            exporting: { enabled: false },
            legend: { enabled: false },
            credits: { enabled: false },
            xAxis: { visible: false },
            yAxis: {
                visible: true,
                title: { text: undefined },
                labels: { enabled: false },
                gridLineColor: undefined,
                plotLines: [
                    {
                        value: peakTraffic,
                        color: scheme === 'light' ? theme.colors.tango : theme.colors.white,
                        width: chartSettings.lineWidth,
                    },
                ],
            },
            plotOptions: {
                series: {
                    animation: {
                        duration: 0,
                    },
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                    turboThreshold: 0,
                },
            },
            series: [
                {
                    color: scheme === 'light' ? theme.colors.tango : theme.colors.white,
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 1,
                            y2: 0,
                        },
                        stops:
                            scheme === 'light'
                                ? [[0, 'rgba(244, 127, 34, 0)'], [1, theme.colors.tangoTransparent]]
                                : [[0, 'rgba(255, 255, 255, 0)'], [1, 'rgba(255, 255, 255, 0.3)']],
                    },
                    lineWidth: chartSettings.lineWidth,
                    data: stats.map(
                        row =>
                            (row && row.time && row.value !== null && row.value !== undefined
                                ? [row.time, row.value]
                                : [0, 0]) as [number, number]
                    ),
                },
            ],
        };
    }

    return {};
};

const HeaderStats: SFC<InProps & OutProps> = props => {
    const stats: MenuStatsFragment[] | null = getEntriesOfType('Graph', props.data.stats);

    const peakTraffic = path<number>(['vitals', 'globalStats', 'peakTraffic'], props.data) || 0;
    const peakTrafficFormatted = formatBitUnits(peakTraffic);
    const currentTraffic = path<number>(['vitals', 'globalStats', 'currentTraffic'], props.data) || 0;
    const currentTrafficFormatted = formatBitUnits(currentTraffic);
    const connectedNetworks = path<number>(['vitals', 'globalStats', 'connectedNetworks'], props.data);

    const dailyStats = stats && stats[0];
    if (dailyStats === undefined) {
        return null;
    }
    const config = buildConfig(
        peakTraffic,
        dailyStats.averageInputData,
        props.headerWidth,
        props.theme,
        props.menuScheme
    );

    return (
        <Container>
            <GraphContainer>
                {dailyStats && dailyStats.averageInputData && (
                    <HighchartsReact highcharts={Highcharts} options={config} />
                )}
            </GraphContainer>
            <TrafficContainer>
                <StatContainer>
                    <StatLabel scheme={props.menuScheme}>
                        Cur<span>rent</span>
                    </StatLabel>
                    <StatValue>
                        {currentTrafficFormatted.amount} {currentTrafficFormatted.unit}/s
                    </StatValue>
                </StatContainer>
                <StatContainer>
                    <StatLabel scheme={props.menuScheme}>Peak</StatLabel>
                    <StatValue>
                        {peakTrafficFormatted.amount} {peakTrafficFormatted.unit}/s
                    </StatValue>
                </StatContainer>
                <StatContainer>
                    <StatLink
                        to={generatePath<ExchangeParams>(connectedNetworksRoute.path, {
                            exchange: props.match.params.exchange,
                        })}
                    >
                        <StatLabel scheme={props.menuScheme}>ASNS</StatLabel>
                        <StatValue>{connectedNetworks}</StatValue>
                    </StatLink>
                </StatContainer>
            </TrafficContainer>
        </Container>
    );
};

const Container = styled.div`
    height: 100%;
    margin-right: 16px;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    @media screen and (max-width: 312px) {
        display: none;
    }
`;

const StatContainer = styled.div`
    display: flex;
    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 2rem;
    }
`;

const GraphContainer = styled.div`
    margin-right: 1.6rem;

    @media screen and (max-width: 366px) {
        display: none;
    }
`;
const StatLink = styled(Link)`
    &:hover {
        > div:last-child {
            color: ${props => props.theme.colors.tango};
        }
    }
`;

const StatLabel = styled.div<{ scheme: MenuScheme }>`
    display: flex;
    opacity: ${({ theme, scheme }) => (scheme === 'dark' ? 1 : 0.5)};
    text-transform: uppercase;
    font: ${props => props.theme.fonts.small.smallCaps};
    color: inherit;
    width: 3.5rem;

    > span {
        display: none;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        font: ${props => props.theme.fonts.large.smallCaps};
        justify-content: center;

        > span {
            display: inline;
        }
    }
`;

const StatValue = styled.div`
    display: flex;
    font-size: 1rem;
    font-family: chalet-new-york-sixty;

    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        font-size: 1.4rem;
        justify-content: center;
    }
`;

const TrafficContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        flex-direction: row;
    }

    @media screen and (max-width: 388px) {
        margin-top: -0.25rem;
    }
`;

const enhance = compose<OutProps, InProps>(
    withRouter,
    withWidth,
    graphql(GET_MENU_STATISTICS, {
        options: (props: OutProps) => ({
            variables: {
                site: props.match.params.exchange,
            },
        }),
    }),
    withTheme
);

export default enhance(HeaderStats);
