import React, { SFC } from 'react';
import {
    FeaturedStoryCollectionItemFragment
} from '../../../entities/operationResults';
import FeaturedPreview from '../../molecules/FeaturedEntryPreview';
import styled from '../../../styled-components';
import gql from 'graphql-tag';

interface Props {
    collectionItems: FeaturedStoryCollectionItemFragment[] | null;
}

const storyFragment = gql`
    fragment FeaturedStoryCollectionItem on Story {
        id
        uri
        title
        description
        heroImage {
            srcSmall: url(transform: storyHeroSmall)
            widthSmall: width(transform: storyHeroSmall)
            srcMedium: url(transform: storyHeroMedium)
            widthMedium: width(transform: storyHeroMedium)
            srcLarge: url(transform: storyHeroLarge)
            widthLarge: width(transform: storyHeroLarge)
            title
        }
        postDate @date(as:"j F Y", timezone:"Europe/Amsterdam")
    }
`;

export const fragments = {
    relatedStory: storyFragment,
};

const CollectionFeaturedItems: SFC<Props> = ({ collectionItems }) => {
    return (
        <PreviewWrap>
            {collectionItems !== null && collectionItems.map(({ id, title, uri, heroImage, postDate }) => {
                const firstImage = heroImage && heroImage[0];
                return uri && (
                    <FeaturedPreview
                        key={id}
                        link={'/' + uri}
                        img={firstImage ? {
                            title: firstImage.title,
                            srcSmall: firstImage.srcSmall,
                            widthSmall: firstImage.widthSmall,
                            srcMedium: firstImage.srcMedium,
                            widthMedium: firstImage.widthMedium,
                            srcLarge: firstImage.srcLarge,
                            widthLarge: firstImage.widthLarge
                        } : undefined}
                        date={postDate}
                    >
                        {title}
                    </FeaturedPreview>
                );
            })}
        </PreviewWrap>
    );
};

const PreviewWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        flex-direction: unset;
    }
`;

export default CollectionFeaturedItems;
