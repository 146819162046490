import { startOfDay } from 'date-fns';
import gql from 'graphql-tag';
import React, { Fragment, SFC } from 'react';
import { DataProps, graphql } from 'react-apollo';
import ObserveSize from 'react-observe-size';
import { RouteComponentProps, withRouter } from 'react-router';
import generatePath from 'react-router-dom/generatePath';
import { compose } from 'recompose';
import { eventsRoute } from '../../constants/routes';
import {
    EventsQuery as Response,
    EventSummaryFragment,
    ExchangePageEventsFragment,
    FeaturedEventFragment,
} from '../../entities/operationResults';
import { getEntriesOfType, getEntryOfType } from '../../services/entryTypes';
import styled, { ThemeProps, withTheme } from '../../styled-components';
import Container from '../atoms/Container';
import { ExchangeParams } from '../Exchange';
import EventPreview from '../molecules/EventPreview';
import SectionHeader from '../molecules/SectionHeader';

interface Props extends DataProps<Response>, ThemeProps, RouteComponentProps<ExchangeParams> {}

const GET_EVENTS_QUERY = gql`
    query Events($listExpiryDate: String!, $site: String!) {
        events: entries(
            site: $site
            offset: 1
            type: Event
            orderBy: "startDate ASC"
            limit: 4
            listExpiryDate: $listExpiryDate
        ) {
            ...EventSummary
        }
        next: entry(site: $site, type: Event, orderBy: "startDate ASC", listExpiryDate: $listExpiryDate) {
            ...FeaturedEvent
        }
        exchangePage: entry(site: $site, type: ExchangePage) {
            ...ExchangePageEvents
        }
    }

    fragment ExchangePageEvents on ExchangePage {
        featuredEvent {
            ...FeaturedEvent
        }
    }

    fragment EventSummary on Event {
        title
        location
        startDate @date(as:"j F", timezone:"Europe/Amsterdam")
        endDate @date(as:"j F", timezone:"Europe/Amsterdam")
        id
        uri
    }

    fragment FeaturedEvent on Event {
        ...EventSummary
        heroImage {
            srcSmall: url(transform: eventTeaserSmall)
            widthSmall: width(transform: eventTeaserSmall)
            srcMedium: url(transform: eventTeaserMedium)
            widthMedium: width(transform: eventTeaserMedium)
            srcLarge: url(transform: eventTeaserLarge)
            widthLarge: width(transform: eventTeaserLarge)
            title
        }
    }
`;

const Events: SFC<Props> = ({ data, theme, match }) => {
    const { events, featured } = pickEvents(data);
    const featuredHero = featured && featured.heroImage && featured.heroImage[0];
    return (
        <Container maxWidth paddingTop={'5rem'} paddingTopL={'8rem'} paddingBottom={'5rem'} paddingBottomL={'10rem'}>
            <ObserveSize>
                {({ width }) => (
                    <React.Fragment>
                        <SectionHeader
                            title={
                                <Fragment>
                                    Upcoming <strong>events</strong>
                                </Fragment>
                            }
                            linkLabel={'ALL EVENTS'}
                            link={generatePath<ExchangeParams>(eventsRoute.path, { exchange: match.params.exchange })}
                        />

                        <EventWrap fullWidth={width < theme.mediaQueriesValues.s}>
                            {featured && featured.uri && (
                                <FeaturedEvent
                                    fullWidth={width < theme.mediaQueriesValues.s}
                                    small={width < theme.mediaQueriesValues.m}
                                >
                                    <EventPreview
                                        to={`/${featured.uri}`}
                                        hero={
                                            featuredHero && {
                                                title: featuredHero.title,
                                                srcSmall: featuredHero.srcSmall,
                                                widthSmall: featuredHero.widthSmall,
                                                srcMedium: featuredHero.srcMedium,
                                                widthMedium: featuredHero.widthMedium,
                                                srcLarge: featuredHero.srcLarge,
                                                widthLarge: featuredHero.widthLarge,
                                            }
                                        }
                                        location={featured.location}
                                        startDate={featured.startDate ? featured.startDate : null}
                                        endDate={featured.endDate ? featured.endDate : null}
                                        title={featured.title}
                                    />
                                </FeaturedEvent>
                            )}

                            <UpcomingEvents fullWidth={width < theme.mediaQueriesValues.s}>
                                {events.map(({ location, startDate, endDate, title, uri, id }) => uri && (
                                    <EventPreview
                                        key={id}
                                        to={`/${uri}`}
                                        location={location}
                                        startDate={startDate ? startDate : null}
                                        endDate={endDate ? endDate : null}
                                        title={title}
                                    />
                                ))}
                            </UpcomingEvents>
                        </EventWrap>
                    </React.Fragment>
                )}
            </ObserveSize>
        </Container>
    );
};

const pickEvents = (data: Props['data']) => {
    let events: EventSummaryFragment[] = getEntriesOfType('Event', data.events);
    const next: FeaturedEventFragment | null = getEntryOfType('Event', data.next);
    const exchangePage: ExchangePageEventsFragment | null = getEntryOfType('ExchangePage', data.exchangePage);
    let featured: FeaturedEventFragment | null = null;
    if (exchangePage) {
        const featuredEvents: FeaturedEventFragment[] = getEntriesOfType('Event', exchangePage.featuredEvent);
        featured = featuredEvents.length ? featuredEvents[0] : null;
    }
    if (next) {
        if (featured) {
            // ignore the featured event if it's in the past...
            if (featured.startDate && +featured.startDate < Math.round(+Date.now() / 1000)) {
                featured = next;
            } else {
                events = [next, ...events].filter(event => featured && event.id !== featured.id);
            }
        } else {
            featured = next;
        }
    }
    return {
        events,
        featured,
    };
};

const EventWrap = styled.div<{ fullWidth?: boolean }>`
    display: flex;
    flex-direction: ${props => (props.fullWidth ? 'column' : 'row')};
`;

const FeaturedEvent = styled.div<{ fullWidth?: boolean; small?: boolean }>`
    display: flex;
    width: 100%;
    max-width: ${props => (props.fullWidth ? 'none' : props.small ? '48rem' : '68rem')};
    padding-right: ${props => (props.fullWidth ? 0 : '2.7rem')};
    border-right: ${({ theme, fullWidth }) => (fullWidth ? 'none' : `1px solid ${theme.colors.mystic}`)};
    padding-bottom: ${props => (props.fullWidth ? '1.8rem' : '0')};
    border-bottom: ${({ theme, fullWidth }) => (fullWidth ? `1px solid ${theme.colors.mystic}` : 'none')};
`;

const UpcomingEvents = styled.div<{ fullWidth?: boolean }>`
    flex: 1;
    margin-left: ${({ fullWidth }) => (fullWidth ? 0 : '2.7rem')};

    > * {
        padding: 1.8rem 0;
        border-bottom: 1px solid ${props => props.theme.colors.mystic};
    }

    > *:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

    > *:first-child {
        padding-top: ${props => (props.fullWidth ? '1.8rem' : 0)};
        span {
            margin-top: -10px;
        }
    }
`;

const enhance = compose(
    withRouter,
    graphql(GET_EVENTS_QUERY, {
        options: (props: Props) => ({
            variables: {
                listExpiryDate: `>= ${Math.round(+startOfDay(Date.now()) / 1000)}`,
                site: props.match.params.exchange,
            },
        }),
    }),
    withTheme
);

export default enhance(Events);
export { Events };
