import gql from 'graphql-tag';
import React, { Fragment, ReactNode, SFC } from 'react';
import { graphql, MutationFunction } from 'react-apollo';
import ReactDOM from 'react-dom';
import Helmet from 'react-helmet-async';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, lifecycle, withState } from 'recompose';
import DefaultButton from '../components/atoms/buttons/DefaultButton';
import IconButton from '../components/atoms/buttons/IconButton';
import CloseIcon from '../components/atoms/icons/CloseIcon';
import { MenuScheme, setSchemeMutation, setSchemeMutationVariables } from '../entities/operationResults';
import styled, { keyframes } from '../styled-components';

const canUseDOM = typeof document !== 'undefined';

const SET_SCHEME = gql`
    mutation setScheme($theme: MenuScheme) {
        setScheme(scheme: $theme) @client {
            scheme
        }
    }
`;

// props used internally by Modal only
interface ModalMutateProps {
    setScheme: MutationFunction<setSchemeMutation, setSchemeMutationVariables>;
}

interface ModalProps extends RouteComponentProps<any> {
    fullyClosed: boolean;
    setFullyClosed: (fullyClosed: boolean) => void;
    routeChange: boolean;
    setRouteChange: (routeChange: boolean) => void;
}

export interface ModalInProps {
    children?: ReactNode;
    isOpen: boolean;
    onClose: () => void;
    backgroundColor?: string;
    textColor?: string;
    getStartedPath: string;
}

const renderContent = ({
    children,
    isOpen,
    fullyClosed,
    onClose,
    setFullyClosed,
    backgroundColor,
    textColor,
    getStartedPath,
    routeChange,
    setRouteChange,
    history,
}: ModalInProps & ModalProps) => (
    <Fragment>
        <Helmet>
            <body className={'lock'} />
        </Helmet>
        <ModalOverlay
            onClick={onClose}
            isOpen={isOpen}
            onAnimationEnd={() => {
                if (routeChange) {
                    history.push(getStartedPath);
                }
                if (!isOpen) {
                    setFullyClosed(true);
                }
            }}
        />
        <ModalContainer backgroundColor={backgroundColor} textColor={textColor} isOpen={isOpen} id={'modal'}>
            <ButtonsContainer>
                <CloseButton onClick={onClose} icon={<CloseIcon />} colorScheme="dark">
                    Close
                </CloseButton>
                {getStartedPath && (
                    <DefaultButton
                        onClick={() => {
                            onClose();
                            setRouteChange(true);
                        }}
                        to={'#'}
                        colorScheme={'colored'}
                    >
                        GET STARTED
                    </DefaultButton>
                )}
            </ButtonsContainer>
            {children}
        </ModalContainer>
    </Fragment>
);

const wrapWithModal = (BaseComponent: React.ComponentType): SFC<ModalInProps & ModalProps> => ({
    isOpen,
    onClose,
    fullyClosed,
    setFullyClosed,
    routeChange,
    setRouteChange,
    backgroundColor,
    textColor,
    getStartedPath,
    history,
    match,
    location,
    ...rest
}) => (
    <Modal
        isOpen={isOpen}
        onClose={onClose}
        fullyClosed={fullyClosed}
        setFullyClosed={setFullyClosed}
        routeChange={routeChange}
        setRouteChange={setRouteChange}
        backgroundColor={backgroundColor}
        textColor={textColor}
        getStartedPath={getStartedPath}
        history={history}
        match={match}
        location={location}
    >
        <BaseComponent {...rest} />
    </Modal>
);

const Modal: SFC<ModalProps & ModalInProps> = props => {
    if (!canUseDOM || props.fullyClosed) {
        return null;
    }
    const modalRoot = document.querySelector('#modal-root');
    if (!modalRoot) {
        return null;
    }
    return <Fragment>{ReactDOM.createPortal(renderContent(props), modalRoot)}</Fragment>;
};

interface Instance {
    handleClickOutside: (this: { props: ModalInProps }) => void;
    handleKey?: (ev: KeyboardEvent) => void;
}

const anims = {
    overlay: {
        in: keyframes`
            from {
                opacity: 0; 
            }
            to {
                opacity: 1; 
            }
`,
        out: keyframes`
            from {
                opacity: 1; 
            }
            to {
                opacity: 0; 
            }
`,
    },
    container: {
        in: keyframes`
            from {
                opacity: 0; 
                transform: translateX(100%); 
            }
            to {
                opacity: 1;
                transform: translateX(0%); 
            }
`,
        out: keyframes`
            from {
                opacity: 1;
                transform: translateX(0%); 
            }
            to {
                opacity: 0; 
                transform: translateX(100%); 
            }
`,
    },
};

const ModalContainer = styled.div<{ isOpen: boolean; backgroundColor?: string; textColor?: string }>`
    animation: 0.5s ${props => (props.isOpen ? anims.container.in : anims.container.out)} 1 normal;
    width: calc(100vw - 7.6rem);
    position: fixed;
    background-color: ${props => props.backgroundColor || props.theme.colors.white};
    color: ${props => props.textColor || props.theme.colors.black};
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    overflow-y: auto;

    @media screen and (max-width: ${props => props.theme.mediaQueries.m}) {
        width: calc(100vw - 7.3rem);
    }
`;

const CloseButton = styled(IconButton)`
    font: ${props => props.theme.fonts.large.textLink};
    text-transform: uppercase;
`;

const ButtonsContainer = styled.div`
    padding: 1.2rem 1.6rem;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: ${props => props.theme.mediaQueries.m}) {
        padding-left: 3.3rem;
        padding-right: 3.3rem;
    }
`;

const ModalOverlay = styled.div<{ isOpen: boolean }>`
    animation: 0.3s ${props => (props.isOpen ? anims.overlay.in : anims.overlay.out)} 1 normal;
    position: fixed;
    background: rgba(23, 34, 42, 0.89);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
`;

export default compose<ModalInProps, any>(
    withRouter,
    graphql(SET_SCHEME, {
        name: 'setScheme',
    }),
    withState('fullyClosed', 'setFullyClosed', true),
    withState('routeChange', 'setRouteChange', false),
    lifecycle<ModalInProps & ModalMutateProps & ModalProps, {}, Instance>({
        componentDidMount() {
            this.handleKey = event => {
                if (event.key === 'Escape') {
                    this.props.onClose();
                }
            };
            document.addEventListener('keydown', this.handleKey);
        },
        async componentWillUnmount() {
            if (this.handleKey) {
                document.removeEventListener('keydown', this.handleKey);
            }
            await this.props.setScheme({
                variables: {
                    theme: null,
                },
            })
        },
        handleClickOutside() {
            this.props.onClose();
        },
        async componentWillReceiveProps(next: ModalInProps) {
            if (this.props.isOpen !== next.isOpen) {
                if (next.isOpen) {
                    this.props.setFullyClosed(false);
                }
                await this.props.setScheme({
                    variables: {
                        theme: next.isOpen ? MenuScheme.transparent : null,
                    },
                });
            }
        },
    }),
    wrapWithModal
);
