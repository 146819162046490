// todo at some point we would like to receive these from the CMS,
// but craftql does not support querying for a list of sites as of now...

export interface Site {
    id: number;
    handle: string;
    title: string;
    primary?: boolean;
}

const sites: Site[] = [{
    id: 2,
    handle: 'ams',
    title: 'Amsterdam',
    primary: true
}, {
    id: 13,
    handle: 'ban',
    title: 'Bangkok'
}, {
    id: 3,
    handle: 'bay',
    title: 'Bay Area'
}, {
    id: 4,
    handle: 'car',
    title: 'Caribbean'
}, {
    id: 11,
    handle: 'che',
    title: 'Chennai'
}, {
    id: 5,
    handle: 'chi',
    title: 'Chicago'
}, {
    id: 15,
    handle: 'dji',
    title: 'Djibouti'
}, {
    id: 6,
    handle: 'hk',
    title: 'Hong Kong'
}, {
    id: 10,
    handle: 'hyd',
    title: 'Hyderabad'
}, {
    id: 8,
    handle: 'kol',
    title: 'Kolkata'
}, {
    id: 14,
    handle: 'lag',
    title: 'Lagos'
}, {
    id: 13,
    handle: 'man',
    title: 'Manila'
}, {
    id: 7,
    handle: 'mum',
    title: 'Mumbai'
}, {
    id: 9,
    handle: 'noi',
    title: 'Noida'
}, {
    id: 12,
    handle: 'sin',
    title: 'Singapore'
}
];

export default sites;
