import React, { SFC } from 'react';
import Container from '../atoms/Container';
import {
    RelatedEventFragment,
    RelatedInfoFragment,
    RelatedServiceFragment,
    RelatedStoryFragment,
    RelatedNewsFragment,
    RelatedDocumentationFragment
} from '../../entities/operationResults';
import theme from '../../constants/theme';
import styled from '../../styled-components';
import StoryPreview from '../molecules/StoryPreview';
import gql from 'graphql-tag';
import ContentTitle from '../atoms/ContentTitle';

const storyFragment = gql`
    fragment RelatedStory on Story {
        id
        uri
        title
        postDate @date(as:"F j, Y", timezone:"Europe/Amsterdam")
        heroImage {
            srcSmall: url(transform: storyHeroSmall)
            widthSmall: width(transform: storyHeroSmall)
            srcMedium: url(transform: storyHeroMedium)
            widthMedium: width(transform: storyHeroMedium)
            srcLarge: url(transform: storyHeroLarge)
            widthLarge: width(transform: storyHeroLarge)
            title
        }
        service {
            title
        }
       
    }
`;

const eventFragment = gql`
    fragment RelatedEvent on Event {
        id
        uri
        title
        postDate @date(as:"F j, Y", timezone:"Europe/Amsterdam")
        heroImage {
            srcSmall: url(transform: storyHeroSmall)
            widthSmall: width(transform: storyHeroSmall)
            srcMedium: url(transform: storyHeroMedium)
            widthMedium: width(transform: storyHeroMedium)
            srcLarge: url(transform: storyHeroLarge)
            widthLarge: width(transform: storyHeroLarge)
            title
        }
    }
`;

const serviceFragment = gql`
    fragment RelatedService on Service {
        id
        uri
        title
        postDate @date(as:"F j, Y", timezone:"Europe/Amsterdam")
        relatedItemImage {
            srcSmall: url(transform: storyHeroSmall)
            widthSmall: width(transform: storyHeroSmall)
            srcMedium: url(transform: storyHeroMedium)
            widthMedium: width(transform: storyHeroMedium)
            srcLarge: url(transform: storyHeroLarge)
            widthLarge: width(transform: storyHeroLarge)
            title
        }
    }
`;

const infoFragment = gql`
    fragment RelatedInfo on Info {
        id
        uri
        title
        postDate @date(as:"F j, Y", timezone:"Europe/Amsterdam")
        relatedItemImage {
            srcSmall: url(transform: storyHeroSmall)
            widthSmall: width(transform: storyHeroSmall)
            srcMedium: url(transform: storyHeroMedium)
            widthMedium: width(transform: storyHeroMedium)
            srcLarge: url(transform: storyHeroLarge)
            widthLarge: width(transform: storyHeroLarge)
            title
        }
    }
`;

const documentationFragment = gql`
    fragment RelatedDocumentation on Documentation {
        id
        uri
        title
        postDate @date(as:"F j, Y", timezone:"Europe/Amsterdam")
        relatedItemImage {
            srcSmall: url(transform: storyHeroSmall)
            widthSmall: width(transform: storyHeroSmall)
            srcMedium: url(transform: storyHeroMedium)
            widthMedium: width(transform: storyHeroMedium)
            srcLarge: url(transform: storyHeroLarge)
            widthLarge: width(transform: storyHeroLarge)
            title
        }
    }
`;

const newsFragment = gql`
    fragment RelatedNews on News {
        id
        uri
        title
        postDate @date(as:"F j, Y", timezone:"Europe/Amsterdam")
        heroImage {
            srcSmall: url(transform: storyHeroSmall)
            widthSmall: width(transform: storyHeroSmall)
            srcMedium: url(transform: storyHeroMedium)
            widthMedium: width(transform: storyHeroMedium)
            srcLarge: url(transform: storyHeroLarge)
            widthLarge: width(transform: storyHeroLarge)
            title
        }
    }
`;

export const relatedFragments = gql`
    fragment Related on EntryInterface {
        ...RelatedStory
        ...RelatedEvent
        ...RelatedService
        ...RelatedInfo
        ...RelatedNews
        ...RelatedDocumentation
    }
    
    ${infoFragment}
    ${storyFragment}
    ${eventFragment}
    ${serviceFragment}
    ${newsFragment}
    ${documentationFragment}
`;

interface Props {
    relatedItems?: Array<RelatedStoryFragment | RelatedEventFragment | RelatedServiceFragment | RelatedInfoFragment | RelatedNewsFragment | RelatedDocumentationFragment>;
}

const RelatedDetail: SFC<Props> = ({ relatedItems }) => {
    return (
        <section>
            <Container backgroundColor={theme.colors.aquaHaze} paddingTop={'5rem'} paddingTopL={'8rem'}>
                <TitleWrapper>
                    <ContentTitle>You might also be interested in</ContentTitle>
                </TitleWrapper>

                <Stories>
                {relatedItems && relatedItems.map((item) => {
                    if (!item) { return null; }
                    const { id, title } = item;
                    let { postDate } = item;
                    const uri = '/' + item.uri;
                    let firstImage = null;
                    let serviceTitle = '';
                    switch (item.__typename) {
                        case 'Story': {
                            const heroImage = item && item.heroImage && item.heroImage[0];
                            if (heroImage) {
                                firstImage = heroImage;
                            }
                            const service = item && item.service && item.service[0];
                            if (service) {
                                serviceTitle = service.title;
                            }
                            break;
                        }
                        case 'Event': {
                            const heroImage = item && item.heroImage && item.heroImage[0];
                            if (heroImage) {
                                firstImage = heroImage;
                            }
                            break;
                        }
                        case 'Service': {
                            postDate = 'service';
                            const relatedItemImage = item && item.relatedItemImage && item.relatedItemImage[0];
                            if (relatedItemImage) {
                                firstImage = relatedItemImage;
                            }
                            break;
                        }
                        case 'Info': {
                            postDate = 'more info';
                            const relatedItemImage = item && item.relatedItemImage && item.relatedItemImage[0];
                            if (relatedItemImage) {
                                firstImage = relatedItemImage;
                            }
                            break;
                        }
                        case 'Documentation': {
                            postDate = 'documentation';
                            const relatedItemImage = item && item.relatedItemImage && item.relatedItemImage[0];
                            if (relatedItemImage) {
                                firstImage = relatedItemImage;
                            }
                            break;
                        }
                        case 'News': {
                            const heroImage = item && item.heroImage && item.heroImage[0];
                            if (heroImage) {
                                firstImage = heroImage;
                            }
                            break;
                        }
                        default: {
                            firstImage = null;
                        }
                    }
                    return uri && (
                        <StoryPreview
                            key={id}
                            link={uri}
                            service={serviceTitle}
                            title={title}
                            img={firstImage ? {
                                title: firstImage.title,
                                srcSmall: firstImage.srcSmall,
                                widthSmall: firstImage.widthSmall,
                                srcMedium: firstImage.srcMedium,
                                widthMedium: firstImage.widthMedium,
                                srcLarge: firstImage.srcLarge,
                                widthLarge: firstImage.widthLarge
                            } : undefined}
                            date={postDate}
                        />
                    );
                })}
                </Stories>
            </Container>
        </section>
    );
};

const TitleWrapper = styled.div`
    padding-left: 3rem;
`;

const Stories = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    padding: 0.5rem;
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        flex-direction: row;
    }
`;

export default RelatedDetail;