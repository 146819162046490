import React from 'react';
import { keyframes } from 'styled-components';
import { DropdownTyping } from '../../../../entities/DropdownType';
import { MenuFragment, MenuScheme } from '../../../../entities/operationResults';
import styled, { css } from '../../../../styled-components';
import Services from '../../../organisms/Services';
import DropdownLocations from './DropdownLocations';
import DropdownNav from './DropdownNav';
import DropdownSearch from './DropdownSearch';
import DropdownStats from './DropdownStats';

interface Props {
    menuSubs: MenuFragment | null;
    visible: boolean;
    setVisible: (visible: boolean) => void;
    setActiveIndex: (activeIndex: number | null) => void;
    dropdownType: DropdownTyping;
    setDropdownType: (dropdownType: DropdownTyping) => void;
    scheme: MenuScheme;
}

const Dropdown = ({ menuSubs, visible, setVisible, setActiveIndex, dropdownType, setDropdownType, scheme }: Props) => {
    return (
        <DropdownWrap
            onAnimationEnd={() => {
                if (!visible) {
                    setActiveIndex(null);
                    setDropdownType(null);
                }
            }}
            scheme={scheme}
            dropdownType={dropdownType}
            visible={visible}
            className={'sub-menu'}
        >
            {dropdownType === 'regular' && menuSubs !== null && (
                <DropdownNav menuSubs={menuSubs} setVisible={setVisible} />
            )}
            {dropdownType === 'services' && <Services inMenu setVisible={setVisible} isDark={scheme === 'dark'} />}
            {dropdownType === 'statistics' && menuSubs !== null && (
                <DropdownStats scheme={scheme} menuItems={menuSubs} setVisible={setVisible} />
            )}
            {dropdownType === 'search' && <DropdownSearch setVisible={setVisible} />}
            {dropdownType === 'locations' && <DropdownLocations setVisible={setVisible} />}
            {dropdownType === 'myAmsIx' && menuSubs !== null && (
                <DropdownNav menuSubs={menuSubs} setVisible={setVisible} reverse={true} />
            )}
        </DropdownWrap>
    );
};

const dropdown = keyframes`
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0%);
    }
`;

const dropup = keyframes`
    from {
        transform: translateY(0%);
    }

    to {
        transform: translateY(-150%);
    }
`;

const DropdownWrap = styled.div<{ visible: boolean; scheme: MenuScheme; dropdownType: DropdownTyping }>`
    position: absolute;
    top: 6.5rem;
    width: 100%;
    background-color: ${props =>
        props.scheme === 'transparent'
            ? 'transparent'
            : props.scheme === 'dark' || props.dropdownType === 'locations'
            ? props.theme.colors.mirage
            : props.theme.colors.white};
    box-shadow: 0 0 8rem rgba(0, 0, 0, 0.4);
    z-index: 0;
    animation: ${props => (props.visible ? css`0.3s 1 normal ${dropdown}` : css`0.5s 1 normal ${dropup}`)};
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    color: ${props =>
        props.scheme === 'transparent'
            ? 'transparent'
            : props.scheme === 'dark'
            ? props.theme.colors.white
            : props.theme.colors.mirage};
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        top: 7.4rem;
    }
`;

export default Dropdown;
