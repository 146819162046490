export interface PoweredBySites {
    id: number;
    handle: string;
    title: string;
    url?: string;
}

const poweredBySites: PoweredBySites[] = [{
    id: 2,
    handle: 'cai',
    title: 'Cairo',
    url: 'https://eg-ix.com.eg/'
}, {
    id: 3,
    handle: 'kuc',
    title: 'Kuching',
    url: 'https://www.irix.my/ix/'
}, {
    id: 1,
    handle: 'mnix',
    title: 'Manama',
    url: 'https://www.mn-ix.com/'
},  {
    id: 4,
    handle: 'omix',
    title: 'Muscat',
    url: 'https://om-ix.com/'
}];

export default poweredBySites;