import gql from 'graphql-tag';
import React, { SFC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import {
    DocumentationCollectionItemFragment,
    EventCollectionItemFragment,
    InfoCollectionItemFragment,
    NewsCollectionItemFragment,
    NewsletterCollectionItemFragment,
    ServiceCollectionItemFragment,
    StoryCollectionItemFragment,
} from '../../../entities/operationResults';
import formatEventDate from '../../../services/formatEventDate';
import styled from '../../../styled-components';
import LazyImg from '../../atoms/LazyImg';

const newsFragment = gql`
    fragment NewsCollectionItem on News {
        id
        uri
        title
        description
        heroImage {
            src: url(transform: collectionItem)
            width: width(transform: collectionItem)
            src2x: url(transform: collectionItem2x)
            width2x: width(transform: collectionItem2x)
            title
        }
        date: postDate @date(as:"j F Y", timezone:"Europe/Amsterdam")
    }
`;

const newsletterFragment = gql`
    fragment NewsletterCollectionItem on Newsletter {
        id
        uri
        title
        newsletterUrl
        newsletterDate @date(as:"F Y", timezone:"Europe/Amsterdam")
        heroImage {
            src: url(transform: collectionItem)
            width: width(transform: collectionItem)
            src2x: url(transform: collectionItem2x)
            width2x: width(transform: collectionItem2x)
            title
        }
        date: postDate @date(as:"j F Y", timezone:"Europe/Amsterdam")
    }
`;

const eventFragment = gql`
    fragment EventCollectionItem on Event {
        id
        uri
        title
        description
        heroImage {
            src: url(transform: collectionItem)
            width: width(transform: collectionItem)
            src2x: url(transform: collectionItem2x)
            width2x: width(transform: collectionItem2x)
            title
        }
        startDate @date(as:"j F", timezone:"Europe/Amsterdam")
        endDate @date(as:"j F", timezone:"Europe/Amsterdam")
        date: postDate @date(as:"j F Y", timezone:"Europe/Amsterdam")
        listInPastEvents
        location
    }
`;

const storyFragment = gql`
    fragment StoryCollectionItem on Story {
        id
        uri
        title
        description
        heroImage {
            src: url(transform: collectionItem)
            width: width(transform: collectionItem)
            src2x: url(transform: collectionItem2x)
            width2x: width(transform: collectionItem2x)
            title
        }
        date: postDate @date(as:"j F Y", timezone:"Europe/Amsterdam")
    }
`;

const infoFragment = gql`
    fragment InfoCollectionItem on Info {
        id
        uri
        title
        description
    }
`;

const documentationFragment = gql`
    fragment DocumentationCollectionItem on Documentation {
        id
        uri
        title
        description
    }
`;

const serviceFragment = gql`
    fragment ServiceCollectionItem on Service {
        id
        uri
        title
        description
        heroImage: icon {
            src: url(transform: serviceLogo)
            width: url(transform: serviceLogo)
            src2x: url(transform: serviceLogo2x)
            width2x: width(transform: serviceLogo2x)
            title
        }
    }
`;

export const fragments = {
    news: newsFragment,
    newsletter: newsletterFragment,
    story: storyFragment,
    event: eventFragment,
    info: infoFragment,
    documentation: documentationFragment,
    service: serviceFragment,
};

interface Props {
    collectionItem:
        | NewsCollectionItemFragment
        | NewsletterCollectionItemFragment
        | StoryCollectionItemFragment
        | EventCollectionItemFragment
        | InfoCollectionItemFragment
        | DocumentationCollectionItemFragment
        | ServiceCollectionItemFragment;
}

const CollectionItem: SFC<Props> = ({ collectionItem }) => {
    const thumb = collectionItem.__typename !== 'Info' && collectionItem.__typename !== 'Documentation' && collectionItem.heroImage && collectionItem.heroImage[0];
    const date = collectionItem.__typename === 'Event' && collectionItem.startDate
        ? formatEventDate(collectionItem.startDate, collectionItem.endDate ? collectionItem.endDate : null)
        : (collectionItem.__typename === 'News' || collectionItem.__typename === 'Story') && collectionItem.date;
    const newsletterDate = collectionItem.__typename === 'Newsletter' && collectionItem.newsletterDate
    if (collectionItem.__typename === 'Newsletter'){
        return (
                <ListItemExternalLink type={collectionItem.__typename} href={collectionItem.newsletterUrl && collectionItem.newsletterUrl || '/'} target="_blank">
                    {thumb && thumb.src && (
                        <ImgWrapper>
                            <LazyImg
                                alt={thumb.title || undefined}
                                src={thumb.src}
                                srcSet={`${thumb.src} ${thumb.width}w, ${thumb.src2x} ${thumb.width2x}w`}
                                sizes={'(min-width: 990px) 10vw, 140px'}
                            />
                        </ImgWrapper>
                    )}
                    <Copy>
                        <DateParagraph>{newsletterDate}</DateParagraph>
                        <Title>{collectionItem.title}</Title>
                    </Copy>
                </ListItemExternalLink>
        );
    } else {
        return (
            <li>
                <ListItemLink type={collectionItem.__typename} to={`/${collectionItem.uri}`}>
                    {thumb && thumb.src && (
                        <ImgWrapper>
                            <LazyImg
                                alt={thumb.title || undefined}
                                src={thumb.src}
                                srcSet={`${thumb.src} ${thumb.width}w, ${thumb.src2x} ${thumb.width2x}w`}
                                sizes={'(min-width: 990px) 10vw, 140px'}
                            />
                        </ImgWrapper>
                    )}
                    <Copy>
                        {collectionItem.__typename !== 'Story' && (<DateParagraph>{date}</DateParagraph>)}
                        <Title>{collectionItem.title}</Title>
                        {collectionItem.__typename === 'Event' && <Subtitle>{collectionItem.location}</Subtitle>}
                        <Excerpt>{collectionItem.description}</Excerpt>
                    </Copy>
                </ListItemLink>
            </li>
        );
    }
};

const ImgWrapper = styled.div`
    min-height: 100%;
    position: relative;
    overflow: hidden;
    margin-right: 3.1rem;

    img {
        object-position: center;
        object-fit: cover;
    }
`;

const ListItemLink = styled(Link)<
    LinkProps & { type: 'News' | 'Story' | 'Event' | 'Info' | 'Documentation' | 'Service' | 'Newsletter'}
>`
    border-bottom: 0.1rem solid ${props => props.theme.colors.mystic};
    display: flex;
    flex-direction: column;
    padding-bottom: 3.2rem;
    margin-bottom: 3.2rem;

    &:hover {
        img {
            transform: ${props => (props.type === 'News' ? 'scale(1)' : 'scale(1.05)')};
        }

        h2 {
            color: ${props => props.theme.colors.yellowOrange};
        }
    }

    ${ImgWrapper} {
        min-width: ${props => (props.type === 'News' ? 'initial' : '100%')};
        width: ${props => (props.type === 'News' ? '14rem' : 'initial')};
        height: ${props => (props.type === 'News' ? '14rem' : '16rem')};

        > img {
            border-radius: ${props => (props.type === 'News' ? '50%' : '0%')};
            margin-bottom: ${props => (props.type === 'News' ? '' : '1.5rem')};
            flex-shrink: 0;
            transition: 0.5s transform ease-in-out;
            width: 100%;
            height: 100%;
        }
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        flex-direction: unset;
        padding: ${props => (props.type === 'News' ? '0 0 5.6rem 0' : '0 0 5.6rem 0')};
        margin-bottom: 5.6rem;
        ${ImgWrapper} {
            min-width: ${props => (props.type === 'News' ? '14rem' : '28.6rem')};
            height: ${props => (props.type === 'News' ? '14rem' : '18rem')};
        }
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding: ${props => (props.type === 'News' ? '0 12.3rem 5.6rem 12.3rem' : '0 12.3rem 5.6rem 12.3rem')};
        margin-bottom: 5.6rem;
        ${ImgWrapper} {
            min-width: ${props => (props.type === 'News' ? '14rem' : '33.6rem')};
            height: ${props => (props.type === 'News' ? '14rem' : '21rem')};
        }
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        padding: ${props => (props.type === 'News' ? '0 23rem 5.6rem 12.3rem' : '0 23rem 5.6rem 12.3rem')};
        margin-bottom: 5.6rem;
    }
`;

const ListItemExternalLink = styled.a<{ type: 'Newsletter'}>`
    border-bottom: 0.1rem solid ${props => props.theme.colors.mystic};
    display: flex;
    flex-direction: column;
    padding-bottom: 3.2rem;
    margin-bottom: 3.2rem;

    &:hover {
        img {
            transform: scale(1);
        }

        h2 {
            color: ${props => props.theme.colors.yellowOrange};
        }
    }

    ${ImgWrapper} {
        min-width: initial;
        width: 14rem;
        height: 14rem;

        > img {
            border-radius: 50%;
            margin-bottom: '';
            flex-shrink: 0;
            transition: 0.5s transform ease-in-out;
            width: 100%;
            height: 100%;
        }
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        flex-direction: unset;
        padding: 0 0 5.6rem 0;
        margin-bottom: 5.6rem;
        ${ImgWrapper} {
            min-width: 14rem;
            height: 14rem;
        }
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding: 0 12.3rem 5.6rem 12.3rem;
        margin-bottom: 5.6rem;
        ${ImgWrapper} {
            min-width: 14rem;
            height: 14rem;
        }
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        padding: 0 23rem 5.6rem 12.3rem;
        margin-bottom: 5.6rem;
    }
`;

const Copy = styled.div`
    max-width: 70.4rem;
`;

const DateParagraph = styled.p`
    margin: 0;
    font: ${props => props.theme.fonts.large.eventDate};
    color: ${props => props.theme.colors.tango};
    text-transform: uppercase;
`;

const Title = styled.h2`
    font: ${props => props.theme.fonts.small.sectionItemTitle};
    margin: 0;
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        font: ${props => props.theme.fonts.medium.sectionItemTitle};
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        font: ${props => props.theme.fonts.large.sectionItemTitle};
    }
`;

const Subtitle = styled(Title)`
    font-family: chalet-london-sixty, sans-serif !important;
`;

const Excerpt = styled.p`
    margin: 1.6rem 0 0 0;
    font: ${props => props.theme.fonts.small.contentBody};
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        font: ${props => props.theme.fonts.large.contentBody};
    }
`;

export default CollectionItem;
