import withModal, { ModalInProps } from '../../hocs/withModal';
import React, { Fragment, SFC } from 'react';
import * as Intro from '../molecules/Intro';
import { compose } from 'recompose';
import Container from '../atoms/Container';

import gql from 'graphql-tag';
import { DataProps, graphql } from 'react-apollo';
import { ContactInfoSocialsFragment, ContactSlideoverQuery } from '../../entities/operationResults';
import { RouteComponentProps, withRouter } from 'react-router';
import { ExchangeParams } from '../Exchange';
import styled from '../../styled-components';
import { path } from 'ramda';
import ContactForm from '../molecules/ContactForm';
import ContactFormRegistration from '../molecules/ContactFormRegistration';
import ContactInfo, { contactInfoFragment } from '../molecules/ContactInfo';

const GET_EXCHANGE_CONTACT = gql`
    query ContactSlideover ($site: String!) {
        globals(site: $site) {
            exchangeConfig {
                address
                contactInfo
            }
            socialMedia {
                ...ContactInfoSocials
            }
            email {
                registrationEmailAdditionalSubject
            }
        }
    }

    ${contactInfoFragment}
`;

interface InProps extends ModalInProps {
    contactFormText?: string | null;
    contactBlockId?: number | null;
    documentUpload?: boolean;
    isRegistrationForm?: boolean;
}

type OutProps = RouteComponentProps<ExchangeParams> & DataProps<ContactSlideoverQuery>;

type Props = InProps & OutProps;

const SlideOver: SFC<Props> = ({ contactFormText, data, contactBlockId, documentUpload, isRegistrationForm }) => {
    const contactInfo = path<string>(['globals', 'exchangeConfig', 'contactInfo'], data);
    const address = path<string>(['globals', 'exchangeConfig', 'address'], data);
    const socials = path<ContactInfoSocialsFragment>(['globals', 'socialMedia'], data);
    const registrationSubject = path<string>(['globals', 'email', 'registrationEmailAdditionalSubject'], data);

    return (
        <Wrapper maxWidth>
            <div style={{ flex: 1 }}>
                <Intro.Container>
                    {isRegistrationForm ? (
                        <h1>Register</h1>
                    ) : (
                        <h1>Contact us</h1>
                    )}
                    {contactFormText && (
                        <Fragment>
                            <Intro.Sep />
                            <Intro.Text dangerouslySetInnerHTML={{ __html: contactFormText }} />
                        </Fragment>
                    )}
                </Intro.Container>

                {!isRegistrationForm &&
                    <ContactForm contactBlockId={contactBlockId} documentUpload={documentUpload}/>
                }

                {isRegistrationForm && 
                    <ContactFormRegistration contactBlockId={contactBlockId} registrationSubject={registrationSubject} />
                }
            </div>
            {address && contactInfo && socials && (
                <ContactInfo
                    address={address}
                    contactInfo={contactInfo}
                    socials={socials}
                />
            )}
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    > div {
        display: flex;
        flex-direction: column;
    }

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        > div {
            flex-direction: row;
        }
    }
`;

const enhance = compose<OutProps, InProps>(
    withModal,
    withRouter,
    graphql(GET_EXCHANGE_CONTACT, {
        options: ((props: Props) => ({
            variables: {
                site: props.match.params.exchange
            }
        }))
    })
);
export default enhance(SlideOver);