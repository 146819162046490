import React, { SFC } from 'react';
import Helmet from 'react-helmet-async';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import generatePath from 'react-router-dom/generatePath';
import { compose, withState } from 'recompose';
import { searchRoute } from '../../constants/routes';
import { MenuColumnFragment, MenuFragment, MenuItemFragment } from '../../entities/operationResults';
import { getEntriesOfType } from '../../services/entryTypes';
import styled, { ThemeProps, withTheme } from '../../styled-components';
import DefaultButton from '../atoms/buttons/DefaultButton';
import IconButton from '../atoms/buttons/IconButton';
import BackIcon from '../atoms/icons/BackIcon';
import LinkIconRight from '../atoms/icons/LinkIconRight';
import SearchIcon from '../atoms/icons/SearchIcon';
import UserProfileIcon from '../atoms/icons/UserProfileIcon';
import { ExchangeParams } from '../Exchange';

const enhanced = compose<OutProps, InProps>(
    withTheme,
    withRouter,
    withState('activeIndex', 'setActiveIndex', null)
);

interface OutProps extends ThemeProps, RouteComponentProps<ExchangeParams> {
    activeIndex: number | null;
    setActiveIndex: (activeIndex: number | null) => void;
}

interface InProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    menuItems: MenuFragment[];
    getStartedPath: string;
    loginUrl?: string;
}

function getMenuItemsLayout(menuColumn: MenuColumnFragment): MenuItemFragment[] {
    return getEntriesOfType('Menu', menuColumn.children);
}

const MobileMenu: SFC<OutProps & InProps> = ({
    visible,
    menuItems,
    theme,
    activeIndex,
    setActiveIndex,
    setVisible,
    getStartedPath,
    match,
    loginUrl,
}) => {
    const activeMenuItem = activeIndex !== null && menuItems[activeIndex];
    const menuColumns: MenuColumnFragment[] | false =
        activeMenuItem !== false && getEntriesOfType('Menu', activeMenuItem.children);
    return (
        <React.Fragment>
            <MobileMenuContainer visible={visible}>
                <MobileMenuList>
                    {menuItems.map((menuItem, index) => {
                        return (
                            <NavItem key={menuItem.id}>
                                <a onClick={() => setActiveIndex(index)}>
                                    {menuItem.title}
                                    <LinkIconRight />
                                </a>
                            </NavItem>
                        );
                    })}
                </MobileMenuList>
                <MobileMenuList>
                    {loginUrl && (
                        <NavItemSmall>
                            <a href={loginUrl} target="_blank">
                                Log in to My AMS-IX
                                <UserProfileIcon />
                            </a>
                        </NavItemSmall>
                    )}
                    <NavItemSmall>
                        <Link to={generatePath(searchRoute.path, { exchange: match.params.exchange })}>
                            Search
                            <SearchIcon />
                        </Link>
                    </NavItemSmall>
                </MobileMenuList>
                <MobileMenuFooter>
                    <DefaultButton colorScheme={'colored'} to={getStartedPath}>
                        GET STARTED
                    </DefaultButton>
                </MobileMenuFooter>
            </MobileMenuContainer>
            {menuColumns && activeMenuItem && (
                <MobileMenuContainer visible={visible}>
                    <IconButton
                        icon={<BackIcon color={'white'} />}
                        onClick={() => setActiveIndex(null)}
                        colorScheme={'dark'}
                    >
                        <p>Back</p>
                    </IconButton>
                    <SubMenuTitle>{activeMenuItem.title}</SubMenuTitle>
                    <MobileMenuList>
                        {menuColumns.map(menuColumn => {
                            if (!menuColumn) {
                                return null;
                            }
                            return (
                                <React.Fragment key={menuColumn.title}>
                                    {getMenuItemsLayout(menuColumn).map(menuItem => {
                                        const menuItemLink = menuItem.linkEntry && menuItem.linkEntry[0];
                                        return (
                                            menuItemLink &&
                                            menuItemLink.uri && (
                                                <NavItem key={menuItem.id}>
                                                    <Link onClick={() => setVisible(false)} to={`/${menuItemLink.uri}`}>
                                                        {menuItem.title}
                                                        <LinkIconRight />
                                                    </Link>
                                                </NavItem>
                                            )
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </MobileMenuList>
                </MobileMenuContainer>
            )}
            {visible && (
                <Helmet>
                    <body className={'lock'} />
                </Helmet>
            )}
        </React.Fragment>
    );
};

const MobileMenuList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 0 1.6rem 0;
`;

const NavItem = styled.li`
    padding: 0;
    font-family: chalet-new-york-sixty;
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: -0.028rem;
    position: relative;
    display: block;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.mystic};
    &:last-of-type {
        border-bottom: none;
    }
    a {
        padding: 2rem 0;
        color: inherit;
        text-decoration: none;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        svg {
            text-align: right;
        }
    }
`;

const NavItemSmall = styled(NavItem)`
    font-size: 1.4rem;
    line-height: 2.4rem;
`;

const MobileMenuContainer = styled.div<{ visible: boolean }>`
    position: absolute;
    overflow: auto;
    border-top: 1px solid ${props => props.theme.colors.mystic}
    top: 100%;
    left: 0;
    background: white;
    width: calc(100% - 12.8rem);
    height: calc(100vh - 10.1rem);
    padding: 1.2rem 3.2rem 2.4rem 3.2rem;
    display: flex;
    flex-direction: column;
    transform: translateX(-100%);
    transform: ${props => (props.visible ? 'translateX(0%)' : 'translateX(-100%)')};
    transition: all 0.3s ease-in-out;
    button{
        padding: 2rem 0;
    }
`;

const MobileMenuFooter = styled.div`
    height: 100%;
    display: inline-flex;
    a {
        display: block;
        width: 100%;
        align-self: flex-end;
        text-align: center;
    }
`;

const SubMenuTitle = styled.p`
    font-size: 1.1rem;
    font-family: chalet-new-york-sixty;
    color: ${props => props.theme.colors.dustyGray};
    margin: 0;
    padding: 1.6rem 0;
`;

export default enhanced(MobileMenu);
